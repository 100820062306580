import React from "react";
interface Group2363 extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
const Group2363 = (props: Group2363) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <g id="Group_2363" data-name="Group 2363" transform="translate(-89 -30)">
        <circle
          id="Ellipse_84"
          data-name="Ellipse 84"
          cx="36"
          cy="36"
          r="36"
          transform="translate(89 30)"
          fill="#03234a"
        />
        <g
          id="Group_2362"
          data-name="Group 2362"
          transform="translate(7098.5 -22809)"
        >
          <path
            id="Path_1894"
            data-name="Path 1894"
            d="M41.9,28.7H40.3V25.5a1.6,1.6,0,0,0-3.2,0v3.2H9.9V14.3a1.6,1.6,0,0,1,1.6-1.6H22.668a1.6,1.6,0,1,0,0-3.2H11.5a4.814,4.814,0,0,0-4.8,4.8V28.7H5.1a1.6,1.6,0,0,0-1.6,1.6v4.8a4.814,4.814,0,0,0,4.8,4.8H38.7a4.814,4.814,0,0,0,4.8-4.8V30.3A1.6,1.6,0,0,0,41.9,28.7Zm-1.6,6.4a1.6,1.6,0,0,1-1.6,1.6H8.3a1.6,1.6,0,0,1-1.6-1.6V31.9H40.3Z"
            transform="translate(-6997 22855.104)"
            fill="white"
          />
          <path
            id="Path_1895"
            data-name="Path 1895"
            d="M31.306,3.5H21.7a3.211,3.211,0,0,0-3.2,3.2V21.108a3.211,3.211,0,0,0,3.2,3.2h9.6a3.211,3.211,0,0,0,3.2-3.2V6.7A3.211,3.211,0,0,0,31.306,3.5ZM21.7,21.108V6.7h9.6V21.108Z"
            transform="translate(-6988.007 22851.5)"
            fill="white"
          />
          <path
            id="Path_1896"
            data-name="Path 1896"
            d="M19.1,23.1a1.605,1.605,0,0,0,1.6-1.6V16.7a3.211,3.211,0,0,0-3.2-3.2H12.7a1.6,1.6,0,1,0,0,3.2h2.545L9.976,21.968a1.594,1.594,0,0,0,0,2.257,1.572,1.572,0,0,0,1.136.464,1.64,1.64,0,0,0,1.137-.464l5.266-5.266V21.5a1.605,1.605,0,0,0,1.6,1.6Z"
            transform="translate(-6993.397 22857.498)"
            fill="white"
          />
          <circle
            id="Ellipse_86"
            data-name="Ellipse 86"
            cx="1.601"
            cy="1.601"
            r="1.601"
            transform="translate(-6963.102 22866.199)"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};
export default Group2363;
