import { createAction } from '@reduxjs/toolkit';
import { Constants } from './constants';
import { DeviceOrientation, MediaSize } from '../../enums';
// import { ILanguage } from '../../interfaces/globalization.interfaces';

export const setMediaSize = createAction(Constants.APP_READOUTS_SETMEDIASIZE, (size: MediaSize) => ({
    payload: { size },
}));

export const setConfigLoaded = createAction(Constants.APP_READOUTS_SET_CONFIG_LOADED, (isConfigLoaded: boolean) => ({
    payload: { isConfigLoaded },
}));

export const setScrollTop = createAction(Constants.APP_READOUTS_SET_SCROLL_TOP, (top: number) => ({
    payload: { top },
}));

export const systemErrorMounted = createAction(Constants.APP_READOUTS_SYSTEM_ERROR_MOUNTED, (mounted: boolean) => ({
    payload: { mounted },
}));

export const setRequestState = createAction(Constants.APP_READOUTS_SET_REQUEST_STATE, (state: boolean) => ({
    payload: { state },
}));

// export const setSupportedLanguages = createAction(Constants.APP_READOUTS_SET_LANGUAGES, (languages: ILanguage[]) => ({
//     payload: { languages },
// }));

export const setHideExemptPopup = createAction(Constants.APP_READOUTS_HIDE_EXEMPT_POPUP, (hide: boolean) => ({
    payload: { hide },
}));

export const setOrientation = createAction(
    Constants.APP_READOUTS_SET_ORIENTATION,
    (orientation: DeviceOrientation) => ({
        payload: { orientation },
    })
);