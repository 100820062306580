import { useEffect, useState } from "react";

const useDebounce = (value : any, delay=500) => {
    
    const [debouncedvalue, setDebouncedValue] = useState(value)

    useEffect(()=>{
        const id = setTimeout(()=>{
            setDebouncedValue(value)
        }, delay)

        return ()=>{
            clearTimeout(id)
        }
    },[value, delay])
    return debouncedvalue;
}

export default useDebounce;