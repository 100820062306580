import React, { useState } from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Group11 from "../../assets/images/svg/group11";
import Group7 from "../../assets/images/svg/group7";
import InputInfoIcon from "../Icons/InputInfoIcon.svg";
import "./PasswordInput.scss";

export interface PasswordInputProps {
  id?: string;
  labelId?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  ariaDescribedBy?: string;
  infoIcon?: boolean;
  infoMessage?: string;
  value?: any;
  maxlength?: number;
  isDisabled: boolean | false;
  onChange: (e: any) => void;
  inputImageId?: string;
  isError?: boolean;
}

const PasswordInput = (props: PasswordInputProps) => {
  const {
    id,
    labelId,
    onChange,
    label,
    placeholder,
    name,
    ariaDescribedBy,
    infoIcon,
    infoMessage,
    value,
    maxlength,
    isDisabled,
    inputImageId,
    isError,
  } = props;

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  return (
    <React.Fragment>
      <Form.Group className="password-container">
        <Form.Label id={labelId}>
          {label}
          {infoIcon && (
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip className="toolTipMessage">{`${infoMessage}`}</Tooltip>
              }
            >
              <img className="InputInfoIcon" width={15} src={InputInfoIcon} />
            </OverlayTrigger>
          )}
        </Form.Label>
        <InputGroup>
          <Form.Control
            id={id}
            type={values.showPassword ? "text" : "password"}
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={isDisabled}
            autoComplete="off"
            onChange={(e) => onChange(e)}
            maxLength={maxlength}
            aria-describedby={ariaDescribedBy}
            className="password-input"
          />
          {value !== "" && (
            <InputGroup.Text
              id={inputImageId}
              className={
                isError
                  ? "passwordInput-err image-container"
                  : "image-container"
              }
            >
              <div
                onClick={handleClickShowPassword}
                style={{ margin: "2px 0px 7px 0px" }}
              >
                {values.showPassword ? <Group7 /> : <Group11 />}
              </div>
            </InputGroup.Text>
          )}
        </InputGroup>
      </Form.Group>
    </React.Fragment>
  );
};

export default PasswordInput;
