import React from "react";
import { Handle, Position, Node, NodeProps } from "reactflow";
import style from "./ModelCustomNode.module.scss";
import Event from "../../components/Icons/Event.svg";
import Place from "../../components/Icons/Place.svg";
import Thing from "../../components/Icons/Thing.svg";
import Person from "../../components/Icons/Person.svg";

type NodeData = {
  label: string;
  type: string;
  status: boolean;
  isSelected: boolean;
  setShowJobTaskPage: any;
  showJobTaskPage: any;
  connected: boolean;
  setShowPlugInModal: any;
};

type ModelCustomNode = Node<NodeData>;

const ModelCustomNode = ({ data }: NodeProps<NodeData>) => {
  return (
    <>
      <div
        className={`${style.nodeContainer} ${
          data.isSelected ? style.nodeStyleSelected : style.nodeStyle
        }`}
      >
        {data?.type === "event" ? (
          <img src={Event} alt="event icon" className={style.icon} width={25} />
        ) : data?.type === "place" ? (
          <img src={Place} alt="place icon" className={style.icon} width={25} />
        ) : data?.type === "thing" ? (
          <img src={Thing} alt="thing icon" className={style.icon} width={25} />
        ) : data?.type === "person" ? (
          <img
            src={Person}
            alt="person icon"
            className={style.icon}
            width={25}
          />
        ) : null}

        <div className={style.label}>
          {data?.label?.length > 11
            ? `${data.label.slice(0, 11)}...`
            : data.label}
        </div>
        {data.isSelected}
        <Handle type="target" position={Position.Left} />
        <div className={style.endpointWrapper}>
          <Handle type="source" position={Position.Right} />
        </div>
      </div>
    </>
  );
};

export default ModelCustomNode;
