import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  OnNodesChange,
  Node,
} from "reactflow";
import styles from "./EnsembleModelsDependency.module.scss";
import deleteFile from "../../components/Icons/deleteFile.svg";

import "reactflow/dist/style.css";
import "./dependency.scss";
import CustomNode from "./CustomNodes";
import {
  deleteEnsembleModelRelation,
  getEnsembleModelRelation,
  getEnsembleModelRelationById,
} from "../../services/ensembleModelling.service";
import DeleteConfirmationPopup from "../../components/DeletePopup/DeleteConfirmationPopup";

const nodeTypes = { customNode: CustomNode };

const EnsembleModelsDependency = (props: any) => {
  const {
    showJobTaskPage,
    setShowJobTaskPage,
    data,
    modalDependencyChange,
    setModalDependecyChange,
    onEmsembleModelDataChange,
    setEnsembleModelSelectedNode,
    setSelectedCusdtomPlugInId,
  } = props;
  const initialNodes: Node[] = [];
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState([]);
  const [clickedNode, setClickedNode] = useState<any>(null);
  const [addedNodes, setAddedNodes] = useState([]);
  const [connectedNodes, setConnectedNodes] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteEdge, setDeleteEdge] = useState<any>();

  useEffect(() => {
    let updatedEdges: any[] = [];

    getEnsembleModelRelation()
      .then((res: any) => {
        updatedEdges = res.map((value: any) => ({
          id: value.ensemble_model_relation_id,
          source: `${value.src_ensemble_model_id}`,
          target: `${value.tgt_ensemble_model_id}`,
          type: "smoothstep",
          style: { stroke: "grey", strokeWidth: 2.5 },
        }));
        setEdges(updatedEdges);
      })
      .catch(() => {
        setEdges([]);
      });
  }, []);

  useEffect(() => {
    let updatedNodes: any[] = [];
    let maxObject;
    let maxY = Number.MIN_SAFE_INTEGER;

    for (const obj of data) {
      if (obj?.canvas_position?.x === 100 && obj?.canvas_position?.y > maxY) {
        maxY = obj.canvas_position.y;
        maxObject = obj;
      }
    }
    let counter = maxObject ? maxObject.canvas_position.y : -100;
    let sortedData = [...data].sort((a, b) => a.canvas_position.x - b.canvas_position.x);

    updatedNodes = sortedData.map((value: any, index: number, dataArray: any) => {
      function isObjectEmpty(obj: any) {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
      }

      function areNodesOverlapping(obj1: any, obj2: any) {
        const deltaY = Math.abs(obj1.y - obj2.y);
        const deltaX = Math.abs(obj1.x - obj2.x);
        if (deltaX < 40 && deltaY < 60) {
          return { x: obj1.x, y: obj1.y + 100 };
        }
        return obj1;
      }

      return {
        id: `${value.ensemble_model_id}`,
        position: !isObjectEmpty(value.canvas_position)
          ? areNodesOverlapping(
              value.canvas_position,
              dataArray[index + 1]?.canvas_position || value.canvas_position
            )
          : { x: 100, y: (counter += 100) },
       
        data: {
          label: value.ensemble_model_name,
          subject_area: value.subject_area,
        },
        type: "customNode",
      };
    });
    setNodes(updatedNodes);
  }, [data]);

  useEffect(() => {
    modalDependencyChange &&
      onEmsembleModelDataChange({
        addedNodes: addedNodes,
        nodes: nodes,
        edges: edges,
      });
  }, [nodes, edges]);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onNodeDragStart = (
    event: React.MouseEvent,
    node: Node,
    nodes: Node[]
  ) => {
    onNodeClick(null, node);
    setModalDependecyChange(true);
  };

  const onNodeClick = (event: React.MouseEvent, element: any) => {
    setEnsembleModelSelectedNode(element);
    setAddedNodes((pre: any) => [...pre, element.id]);
    const clickedNode = nodes.find(
      (el) => el.data.label === element.data.label
    );
    setClickedNode(clickedNode);
    setSelectedCusdtomPlugInId(parseInt(element.id));
    getEnsembleModelRelationById(element.id)
      .then((res: any) => {
        const connectedNodes = res.map((value: any) => ({
          source: `${value.src_ensemble_model_id}`,
          target: `${value.tgt_ensemble_model_id}`,
          id: value.ensemble_model_relation_id,
        }));
        setConnectedNodes(connectedNodes);
      })
      .catch((res: any) => setConnectedNodes([]));

    if (element?.id && element.type === "customNode") {
      const updatedElements = edges.map((el) => {
        if (el.source === element.id || el.target === element.id) {
          return {
            ...el,
            style: { stroke: "blue", strokeWidth: 2.5 },
          };
        }
        return {
          ...el,
          style: { stroke: "grey", strokeWidth: 2.5 },
        };
      });
      setEdges(updatedElements);
    }
  };

  const onConnect = (params: any) => {
    if (params.source === params.target) {
      return;
    }
    setModalDependecyChange(true);
    const sourceNode = nodes.filter((value) => value.id == params.source);
    const targetNode = nodes.filter((value) => value.id == params.target);
    setClickedNode(sourceNode[0]);
    setEnsembleModelSelectedNode(sourceNode[0]);
    setAddedNodes((pre) => [...pre, targetNode[0].id]);
    const id1 = `${sourceNode[0].id}-${targetNode[0].id}`;
    const newEdge = {
      id: id1,
      source: sourceNode[0].id,
      target: targetNode[0].id,
      type: "smoothstep",
      style: { stroke: "grey", strokeWidth: 2.5 },
    };
    const existingIndex = edges.findIndex((item) => item.id === newEdge.id);
    if (existingIndex !== -1) {
      const updatedEdges = [...edges];
      updatedEdges[existingIndex] = newEdge;
      setEdges(updatedEdges);
    } else {
      setEdges((pre) => [...pre, newEdge]);
    }
  };

  const getJobName = (edgeDetails: any) => {
    let targetNode;
    if (edgeDetails.source == clickedNode?.id) {
      targetNode = nodes.filter((node: any) => node.id === edgeDetails.target);
    } else {
      targetNode = nodes.filter((node: any) => node.id === edgeDetails.source);
    }
    if (targetNode[0]?.data?.label.length > 30) {
      return `${targetNode[0]?.data?.label.slice(0, 30)}...`;
    } else {
      return targetNode[0]?.data?.label;
    }
  };

  const deleteEdgeHandler = (deleteValue: any, deleteType: any) => {
    setDeleteEdge({ deleteValue: deleteValue, deleteType: deleteType });
    setDeleteModal(true);
  };

  const handleDeleteJobDependency = () => {
    deleteEnsembleModelRelation(deleteEdge.deleteValue.id)
      .then(() => {
        let updatedEdges: any[] = [];
        getEnsembleModelRelation()
          .then((res: any) => {
            updatedEdges = res.map((value: any) => ({
              id: value.ensemble_model_relation_id,
              source: `${value.src_ensemble_model_id}`,
              target: `${value.tgt_ensemble_model_id}`,
              type: "smoothstep",
              style: { stroke: "grey", strokeWidth: 2.5 },
            }));
            setEdges(updatedEdges);
          })
          .catch(() => {
            setEdges([]);
          });
      })
      .catch(() => {});
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.reactFlow}>
          <ReactFlow
            fitView
            maxZoom={1}
            nodes={nodes.map((element) => ({
              ...element,
              data: {
                ...element.data,
                isSelected: element.id === clickedNode?.id,
                setShowJobTaskPage: setShowJobTaskPage,
                showJobTaskPage: showJobTaskPage,
                setModalDependecyChange: setModalDependecyChange,
                setConnectedNodes: setConnectedNodes,
              },
            }))}
            edges={edges}
            onNodesChange={onNodesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            onNodeClick={onNodeClick}
            connectionLineStyle={{ stroke: "#555", strokeWidth: 2 }}
            snapToGrid={true}
            snapGrid={[20, 20]}
            onNodeDragStart={onNodeDragStart}
            zoomOnDoubleClick={false}
          >
            <Background color="#fff" />
            <Controls />
          </ReactFlow>
        </div>
        <div className={styles.dependencyDetails}>
          {clickedNode?.data && (
            <div className={styles.dependencyDetailsName}>
              {clickedNode?.data?.label}
            </div>
          )}
          {clickedNode?.data && (
            <div className={styles.dependencyDetailsHeader}>
              Dependency Details
            </div>
          )}
          <div className={styles.dependencyDetailsContent}>
            <div className={styles.jobJobContainer}>
              {connectedNodes.length < 1 && clickedNode?.data ? (
                <div className={styles.noJobText}>No Dependency Created</div>
              ) : (
                connectedNodes.length < 1 && (
                  <div className={styles.noJobText}>
                    Select a Ensemble Model to view dependency details
                  </div>
                )
              )}
            </div>
            {connectedNodes.length >= 1 &&
              connectedNodes.map((value) => {
                return (
                  <div className={styles.selectedJobDetails}>
                    <div className={styles.linkedJobDetails}>
                      <div className={styles.linkedJobHeader}>
                        <div className={styles.linkedJobHeaderName}>
                          {getJobName(value)}
                        </div>

                        <div className={styles.linkedJobHeaderEditAndDelete}>
                          <img
                            className={styles.deleteFile}
                            src={deleteFile}
                            alt="Delete"
                            onClick={() => {
                              deleteEdgeHandler(value, "dependency");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <DeleteConfirmationPopup
          popUpMessage={
            deleteEdge?.deleteType === "dependency"
              ? "Do you want to delete the selected Ensemble Model Dependency?"
              : ""
          }
          show={deleteModal}
          onHide={() => {
            setDeleteModal(false);
          }}
          onSave={handleDeleteJobDependency}
        />
      </div>
    </>
  );
};

export default EnsembleModelsDependency;
