import React from "react";
interface PlusIconCircle extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
const PlusIconCircle = (props: PlusIconCircle) => {
  return (
    <svg
      id="Component_2_1"
      data-name="Component 2 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      onClick={props.onClick}
    >
      <rect
        id="Rectangle_85"
        data-name="Rectangle 85"
        width="32"
        height="32"
        rx="16"
        fill="#e8eff3"
      />
      <g id="plus-circle" transform="translate(8 8)">
        <path
          id="Path_214"
          data-name="Path 214"
          d="M8,15a7,7,0,1,1,7-7,7,7,0,0,1-7,7Zm0,1A8,8,0,1,0,0,8,8,8,0,0,0,8,16Z"
          fill="#03234a"
        />
        <path
          id="Path_215"
          data-name="Path 215"
          d="M8,4a.5.5,0,0,1,.5.5v3h3a.5.5,0,0,1,0,1h-3v3a.5.5,0,0,1-1,0v-3h-3a.5.5,0,0,1,0-1h3v-3A.5.5,0,0,1,8,4Z"
          fill="#03234a"
        />
      </g>
    </svg>
  );
};
export default PlusIconCircle;
