import React from "react";
import style from "./VerfiedAccount.module.scss";
import { Modal, Button } from "react-bootstrap";
import YesTick from "../../../Icons/YesTick.svg";
import closeIcon from "../../../Icons/closeIconGreen.svg";

export interface PopUpProps {
  onDoneButtonClick: any;
  show: boolean;
  onHide: any;
}

const VerifiedAccount = (props: PopUpProps) => {
  const { show, onHide, onDoneButtonClick } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={style.GuideEnvironmentCloseIconModal}
    >
      <div className={style.modalBodayMain}>
        <Modal.Body>
          <div className={style.GuideEnvironmentCloseIcon}>
            <img
              onClick={onHide}
              src={closeIcon}
              alt="closeIcon"
              width={19.94}
              height={19.9}
            />
          </div>
          <div className={style.modalBoday}>
            <img
              id="yes-tick-icon-id"
              src={YesTick}
              alt="YesTick"
              width={189.64}
              height={113.36}
            />
            <span className={style.modalBodayHeader}>
              Verified account successfully!
            </span>

            <Modal.Footer className={style.modalDoneButton}>
              <Button onClick={onDoneButtonClick}>DONE</Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
export default VerifiedAccount;
