import { createReducer } from "@reduxjs/toolkit";
import { ILoginState, IPasswordState } from "./interface";
import { LoginConstants } from "./constants";
import * as UserActions from "./actions";

const initialUserState: ILoginState = {
  isAuthorized: false,
};

const initialPasswordState: IPasswordState = {
  password: "",
};

export const loginReducer = createReducer(initialUserState, {
  [LoginConstants.USER_SET_IS_AUTORIZED]: (
    draftState,
    action: ReturnType<typeof UserActions.setIsAuthorized>
  ) => {
    draftState.isAuthorized = action.payload.isAuthorized;
  },
});

export const passwordReducer = createReducer(initialPasswordState, {
  [LoginConstants.SET_PASSWORD]: (
    draftState,
    action: ReturnType<typeof UserActions.setPassword>
  ) => {
    draftState.password = action.payload.password;
  },
});
