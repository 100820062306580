import * as api from "./middleware/api.middleware";
const serviceUrl = "users";

export function getVerify2FAService(
  id: number,
  otpNumber: string,
  username: string,
  password: string
) {
  const user_name = username;
  const pass_word = password;
  const encodedCredentials = btoa(`${user_name}:${pass_word}`);
  const authHeader = `Basic ${encodedCredentials}`;

  const getResponse = api.post<any>(
    `${serviceUrl}/${id}/verify_2fa?token=${otpNumber}`,
    {
      user_id: id,
      token: otpNumber,
    },
    {
      Authorization: authHeader,
    }
  );
  return getResponse;
}
