const dbRequiredFields = [
  "db_server_port",
  "db_name",
  "connection_string",
  "db_username_keyvault_secret_name",
  "db_password_keyvault_secret_name",
  "authentication_type",
];

const dbRequiredFieldsV2 = [
  "db_server_port",
  "db_name",
  "connection_string",
  "db_username_keyvault_secret_name",
  "db_password_keyvault_secret_name",
  "authentication_type_DB",
];

const restRequiredFields = ["base_url", "request_method"];

const remoteSystemFilesRequiredFields = ["kv_username", "kv_password"];

const endPointDbRequiredFieldPartOne = [
  "natural_keys",
  "data_format",
  "column_delimiter",
];

const endPointDbRequiredFieldPartTwo = [
  "database_schema",
  "database_table",
  "ingest_to_data_format",
];

const endpointRMSRequiredFieldsSubsection = [
  "remote_base_path",
  "remote_file_encoding",
  "remote_file_prefix",
];

const endPointAlreadyIngestedRequiredFields = [
  "source_entity_name",
  "natural_keys",
  "data_format",
  "column_delimiter"
]

const validateSelectedKeys = (stateObject: any, keysToValidate: any) => {
  return keysToValidate.every((key: string | number) => {
    const value = stateObject[key];
    return value !== undefined && value !== "" && value !== null;
  });
};
// modal action enum
enum IActionModal {
  INVALID_DELETE = "INVALID_DELETE",
  CREATE_DATASOURCE = "CREATE_DATASOURCE",
  CREATE_ENDPOINT = "CREATE_ENDPOINT"
}

const INVALID_DELETE_MESSAGE = "Cannot be deleted because related endpoints exist."

export {
  dbRequiredFields,
  dbRequiredFieldsV2,
  restRequiredFields,
  remoteSystemFilesRequiredFields,
  endpointRMSRequiredFieldsSubsection,
  endPointDbRequiredFieldPartOne,
  endPointDbRequiredFieldPartTwo,
  endPointAlreadyIngestedRequiredFields,
  validateSelectedKeys,
  IActionModal,
  INVALID_DELETE_MESSAGE
};
