import React, { useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import Speedometer from "./SpeedoMeterChart";
import DoughnutChart from "./DoughnutChart";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { getDataSources } from "../../services";
import { getCustomPlugInDetails } from "../../services/cusstomPlugIn.service";
import DataQuality from "./DataQuality";
import JobMonitor from "./JobMonitor";
import {
  getJobDetails,
  // getCurrentJobExecutionDetails,
  getJobTaskGroupDetails,
  getJobExecutionStatus,
  getJobExecutionHistory,
  getCurrentJobExecutionDetails,
} from "../../services/JobManagement.service";
import {
  getDmDataQuality,
  getRdvDataQuality,
} from "../../services/Dashboard.service";
import CustomLoader from "../../components/Loader/Spinner";
import refreshIcon from "../../components/Icons/refresh.svg";
import { FaCopy } from "react-icons/fa";

const DashBoard = () => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [customPlugIn, setCustomPlugIn] = useState<any>([]);
  const [jobData, setJobData] = useState<any>([]);
  const [jobTaskGroupData, setJobTaskGroupData] = useState<any>([]);
  const [jobTaskGroupTypeData, setJobTaskGroupTypeData] = useState<any>({
    bronze: 0,
    silver: 0,
    gold: 0,
  });
  const [jobTaskGroupActiveTypeData, setJobTaskGroupActiveTypeData] =
    useState<any>({
      bronze: 0,
      silver: 0,
      gold: 0,
    });
  const environment_id = Number(localStorage.getItem("environment_id"));
  const [dqMonitorTab, setDqMonitorTab] = useState<boolean>(false);
  const [jobMonitorTab, setJobMonitorTab] = useState<boolean>(false);
  const [jobStatusCount, setJobStatusCount] = useState<any>();
  const [currectJobData, setCurrentJobData] = useState<any>();
  const [currectJTGData, setCurrentJTGData] = useState<any>();
  const [jobTimeData, setJobTimeData] = useState<any>(null);
  const [jobMonitorData, setJobMonitorData] = useState<any>(null);
  const [jobExecutionStatus, setJobExectionStatus] = useState<any>([]);
  const [jobTableData, setJobTableData] = useState<any>([]);
  const [jobExecutionHistory, setJobExecutionHistory] = useState<any>([]);
  const [jobExecutionHistoryChartData, setJobExecutionHistoryChartData] =
    useState<any>([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDqLoading, setIsDqLoading] = useState<boolean>(false);
  const [bronzeLayerData, setBronzeLayerData] = useState<any>({
    name: "",
    value: [],
  });
  const [silverLayerData, setSilverLayerData] = useState<any>({
    name: "",
    value: [],
  });
  const [goldLayerData, setGoldLayerData] = useState<any>({
    name: "",
    value: [],
  });
  const [jobDurationStartDate, setJobDurationStartDate] = useState(null);
  const [jobDurationEndDate, setJobDurationEndDate] = useState(null);
  const [jobExecutionStartDate, setJobExecutionStartDate] = useState(null);
  const [jobExecutionEndDate, setJobExecutionEndDate] = useState(null);

  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  useEffect(() => {
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    const threeDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    threeDaysAgo.setDate(currentDate.getDate() - 3);

    setJobExecutionStartDate(threeDaysAgo.toISOString().split("T")[0]);
    setJobExecutionEndDate(currentDate.toISOString().split("T")[0]);
    setJobDurationStartDate(thirtyDaysAgo.toISOString().split("T")[0]);
    setJobDurationEndDate(currentDate.toISOString().split("T")[0]);
  }, []);

  const getStatus = (status: any) => {
    const currentSpeed =
      status === "Queued"
        ? 20
        : status === "Initiated"
        ? 60
        : status === "Running"
        ? 140
        : status === "Failed"
        ? 100
        : status === "Succeeded"
        ? 180
        : status === "undefined"
        ? 0
        : 0;
    return currentSpeed;
  };

  const getJobExectionHistoryData = () => {
    getJobExecutionHistory(jobDurationStartDate, jobDurationEndDate)
      .then((res: any) => {
        setJobExecutionHistory(res);
        const getRandomColor = () =>
          `#${Math.floor(Math.random() * 16777215).toString(16)}`;

        function getLatestRecords(data: any) {
          const latestRecordsMap = data.reduce((acc: any, current: any) => {
            const key = `${current.job_name}_${current.start_time.slice(
              0,
              10
            )}`;
            const existingRecord = acc.get(key);

            if (
              !existingRecord ||
              new Date(current.start_time) > new Date(existingRecord.start_time)
            ) {
              acc.set(key, current);
            }

            return acc;
          }, new Map<string, any>());

          return Array.from(latestRecordsMap.values());
        }

        const latestRecords: any = getLatestRecords(res);

        let uniqueDatesSet;
        uniqueDatesSet = new Set(
          latestRecords.map((entry: any) => entry.start_time.split("T")[0])
        );

        const uniqueDates = Array.from(uniqueDatesSet);

        const datasets = latestRecords.map((runId: any) => {
          const counts = uniqueDates.map((date) => {
            const entry = latestRecords.find(
              (item: any) =>
                item.start_time.split("T")[0] === date &&
                item.job_name === runId.job_name
            );
            return entry ? entry.job_duration / 60 : 0;
          });

          const color = getRandomColor();
          return {
            label: runId.job_name,
            data: counts,
            borderColor: color,
            backgroundColor: color,
            tension: 0,
            borderDash: [0],
          };
        });

        const uniqueArray = datasets.reduce(
          (accumulator: any, current: any) => {
            const existingItem = accumulator.find(
              (item: any) => item.label === current.label
            );
            if (!existingItem) {
              return [...accumulator, current];
            }
            return accumulator;
          },
          []
        );

        const chartData: any = {
          labels: uniqueDates,
          datasets: uniqueArray,
        };

        setJobExecutionHistoryChartData(chartData);
      })
      .catch();
  };

  const getJobExecutionStatusData = () => {
    getJobExecutionStatus(jobExecutionStartDate, jobExecutionEndDate)
      .then((res) => {
        setJobMonitorData(res);

        const groupedData = res.reduce((acc: any, curr: any) => {
          const date = new Date(curr.job_start_time).toLocaleDateString();
          const key = curr.job_name + "-" + date;
          if (
            !acc[key] ||
            new Date(acc[key].job_start_time) < new Date(curr.job_start_time)
          ) {
            acc[key] = curr;
          }
          return acc;
        }, {});

        const filteredData: any = Object.values(groupedData);

        const calculateDuration = (startTime: any, endTime: any) => {
          const start: any = new Date(startTime);
          const end: any = new Date(endTime);
          const durationInMilliseconds: any = Math.abs(end - start);
          const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
          const minutes = Math.floor(
            (durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor(
            (durationInMilliseconds % (1000 * 60)) / 1000
          );
          return `${hours}:${minutes}:${seconds}`;
        };

        const validData = (data: any) => {
          data.forEach((job: any) => {
            if (job.job_end_time === null) {
              job.job_end_time = new Date().toISOString();
              job.formatted_duration = calculateDuration(
                job.job_start_time,
                job.job_end_time
              );
              const startDate: any = new Date(job.job_start_time);
              const endDate: any = new Date(job.job_end_time);
              const durationInMilliseconds: any = endDate - startDate;
              job.job_duration = Math.floor(durationInMilliseconds / 1000);
            }

            job.job_task_groups.forEach((group: any) => {
              if (group.job_task_group_end_time === null) {
                group.job_task_group_end_time = new Date().toISOString();
                group.formatted_duration = calculateDuration(
                  group.job_task_group_start_time,
                  group.job_task_group_end_time
                );
                const startDate: any = new Date(
                  group.job_task_group_start_time
                );
                const endDate: any = new Date(group.job_task_group_end_time);
                const durationInMilliseconds: any = endDate - startDate;
                group.job_task_group_duration = Math.floor(
                  durationInMilliseconds / 1000
                );
              }

              group.job_tasks.forEach((task: any) => {
                if (task.job_task_end_time === null) {
                  task.job_task_end_time = new Date().toISOString();
                  task.formatted_duration = calculateDuration(
                    task.job_task_start_time,
                    task.job_task_end_time
                  );
                  const startDate: any = new Date(task.job_task_start_time);
                  const endDate: any = new Date(task.job_task_end_time);
                  const durationInMilliseconds: any = endDate - startDate;
                  task.job_task_duration = Math.floor(
                    durationInMilliseconds / 1000
                  );
                }
              });
            });
          });
        };

        validData(filteredData);

        filteredData.sort(
          (a: any, b: any) =>
            new Date(b.job_start_time).getTime() -
            new Date(a.job_start_time).getTime()
        );

        const layerOrder: any = {
          Bronze: 0,
          Silver: 1,
          Gold: 2,
        };

        filteredData.forEach((job: any) => {
          job.job_task_groups.sort(
            (a: any, b: any) => layerOrder[a.layer] - layerOrder[b.layer]
          );
        });

        filteredData.forEach((job: any) => {
          job.job_task_groups.forEach((group: any) => {
            group.job_tasks.sort((a: any, b: any) => {
              if (a.job_step_type_id !== b.job_step_type_id) {
                return a.job_step_type_id - b.job_step_type_id;
              } else {
                return (
                  new Date(a.job_task_start_time).getTime() -
                  new Date(b.job_task_start_time).getTime()
                );
              }
            });
          });
        });
        setJobExectionStatus(filteredData);
      })
      .catch();
  };

  useEffect(() => {
    jobDurationEndDate !== null &&
      jobDurationStartDate !== null &&
      getJobExectionHistoryData();
  }, [jobDurationEndDate, jobDurationStartDate]);

  useEffect(() => {
    jobExecutionStartDate !== null &&
      jobExecutionEndDate !== null &&
      getJobExecutionStatusData();
  }, [jobExecutionStartDate, jobExecutionEndDate]);

  const fetchJobMonitorDetails = () => {
    setJobExectionData([]);
    getCurrentJobExecutionDetails()
      .then((res) => {
        const statusCounts = res.reduce((acc: any, job: any) => {
          acc[job.job_status] = (acc[job.job_status] || 0) + 1;
          return acc;
        }, {});
        setJobStatusCount(statusCounts);
        function sortByStartDateAndJobStatus(a: any, b: any): number {
          const statusPreference = ["Failed", "Running", "Initiated", "Queued", "Succeeded"];
      
          if (a.job_start_time === null && b.job_start_time !== null) {
              return 1;
          }
          if (a.job_start_time !== null && b.job_start_time === null) {
              return -1; 
          }
          if (a.job_start_time !== null && b.job_start_time !== null) {
              const timeDifference = new Date(b.job_start_time).getTime() - new Date(a.job_start_time).getTime();
              if (timeDifference !== 0) {
                  return timeDifference; 
              }
          }
      
          return statusPreference.indexOf(a.job_status) - statusPreference.indexOf(b.job_status);
      }
      
      const sortedData: any = res.sort(sortByStartDateAndJobStatus);
      
      function calculateOverallStatus(data: any) {
          const statusPreference = ["Failed", "Running", "Initiated", "Queued", "Succeeded"];
      
          let overallStatus = "Succeeded";
          for (let i = 0; i < data.length; i++) {
              const currentStatus = data[i].job_status;
      
              if (statusPreference.indexOf(currentStatus) < statusPreference.indexOf(overallStatus)) {
                  overallStatus = currentStatus;
              }
      
              if (overallStatus === "Failed") {
                  break;
              }
          }
      
          return overallStatus;
      }
      
      const overallStatus = calculateOverallStatus(sortedData);
      setCurrentJobData({ jobStatus: getStatus(overallStatus) });
      handleTableRowClick(sortedData[0]);
      setJobTableData(sortedData);
        setIsLoading(false);
      })
      .catch(() => {});

    jobExecutionStartDate !== null &&
      jobExecutionEndDate !== null &&
      getJobExecutionStatusData();
    jobDurationEndDate !== null &&
      jobDurationStartDate !== null &&
      getJobExectionHistoryData();
  };

  const fetchDqMonitorDetails = () => {
    getDataSources(environment_id).then((res) => {
      setDataSource(res);
    });
    getCustomPlugInDetails().then((res) => {
      setCustomPlugIn(res);
    });
    getJobDetails().then((res) => {
      setJobData(res);
    });
    getRdvDataQuality().then((res: any) => {
      if (res.length === 0) {
        setSilverLayerData({
          name: "Silver DQ Status",
          value: [
            {
              data_mart_name: "null",
              data_vault_test_passed: "NO_RECORD",
            },
          ],
        });
      } else {
        setSilverLayerData({ name: "Silver DQ Status", value: res });
      }
      setIsDqLoading(false);
    });

    getDmDataQuality().then((res: any) => {
      if (res.length === 0) {
        setGoldLayerData({
          name: "Gold DQ Status",
          value: [
            {
              data_mart_name: "null",
              data_mart_test_passed: "NO_RECORD",
            },
          ],
        });
      } else {
        setGoldLayerData({ name: "Gold DQ Status", value: res });
      }
      setIsDqLoading(false);
    });

    setBronzeLayerData({
      name: "Bronze DQ Status",
      value: [
        {
          data_mart_name: "null",
          data_mart_test_passed: "NO_RECORD",
        },
      ],
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchJobMonitorDetails();
    setIsDqLoading(true);
    fetchDqMonitorDetails();
  }, []);

  useEffect(() => {
    if (jobData?.length > 0) {
      const jobId = jobData.map((value: any) => value.job_id);
      const jobTaskGroups: any = [];
      jobId.forEach((job_id: any, index: any) => {
        getJobTaskGroupDetails(job_id).then((data) => {
          jobTaskGroups.push({ jobId: job_id, taskGroups: data });
          if (index === jobId.length - 1) {
            setJobTaskGroupData(jobTaskGroups);
          }
        });
      });
    }
  }, [jobData]);

  useEffect(() => {
    jobTaskGroupData?.length > 0 && countTaskGroupsByLayer(jobTaskGroupData);
  }, [jobTaskGroupData]);

  const setJobExectionData = (res: any) => {
    const distinctRecords = res.filter(
      (record: any, index: any, array: any) => {
        return !array
          .slice(0, index)
          .some(
            (prevRecord: any) =>
              prevRecord.job_name === record.job_name &&
              prevRecord.job_start_time === record.job_start_time &&
              prevRecord.job_end_time === record.job_end_time
          );
      }
    );

    const processedData: any = {};
    distinctRecords.forEach((record: any) => {
      const date: any = new Date(record.job_start_time).toLocaleDateString();

      const startTime = new Date(record.job_start_time);
      const endTime = new Date(record.job_end_time);
      const runtime = (endTime.getTime() - startTime.getTime()) / (1000 * 60);

      if (!processedData[record.job_name]) {
        processedData[record.job_name] = { labels: [], data: [], status: "" };
      }
      processedData[record.job_name].labels.push(date);
      processedData[record.job_name].data.push(runtime);
      processedData[record.job_name].status = record.status;
    });

    const datasets = Object.keys(processedData).map((jobName) => ({
      label: jobName,
      data: processedData[jobName].data,
      status: processedData[jobName].status,
      borderColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
      tension: 0,
      borderDash: [0],
    }));

    const chartData = {
      labels: processedData[Object.keys(processedData)[0]]?.labels,
      datasets,
    };
    setJobTimeData(chartData);
  };

  const dqMonitorButtonClick = () => {
    setDqMonitorTab(true);
  };

  const jobMonitorButtonClick = () => {
    setJobMonitorTab(true);
  };

  const countTaskGroupsByLayer = (jobTaskGroupData: any) => {
    const taskGroups = jobTaskGroupData.map((value: any) => value.taskGroups);
    const flattenedArray = [].concat(
      ...taskGroups.map((innerArray: any) => innerArray)
    );
    const layerCounts: any = {};
    flattenedArray?.length > 0 &&
      flattenedArray.forEach((taskGroup: any) => {
        const layerId = taskGroup.layer_id;
        if (layerCounts[layerId]) {
          layerCounts[layerId] = layerCounts[layerId] + 1;
        } else {
          layerCounts[layerId] = 1;
        }
      });

    const updatedObject = {
      bronze: layerCounts["1"],
      silver: layerCounts["2"],
      gold: layerCounts["3"],
    };
    setJobTaskGroupTypeData(updatedObject);

    const layerCountsActive: any = {};
    flattenedArray?.length > 0 &&
      flattenedArray.forEach((taskGroup: any) => {
        const layerId = taskGroup.layer_id;
        if (taskGroup.is_active === true) {
          if (layerCountsActive[layerId]) {
            layerCountsActive[layerId]++;
          } else {
            layerCountsActive[layerId] = 1;
          }
        }
      });

    const updatedObjectActive = {
      bronze: layerCountsActive["1"],
      silver: layerCountsActive["2"],
      gold: layerCountsActive["3"],
    };
    setJobTaskGroupActiveTypeData(updatedObjectActive);
  };

  const getJobTaskGroupCount = () => {
    const countArray = jobTaskGroupData?.map((item: any) => ({
      jobId: item.jobId,
      count: item.taskGroups.length,
    }));
    const totalCount = countArray?.reduce(
      (total: any, item: any) => total + item.count,
      0
    );
    return totalCount ? totalCount : 0;
  };

  const getJobTaskGroupActiveCount = () => {
    const countArray = jobTaskGroupData?.map((item: any) => ({
      jobId: item.jobId,
      count: item.taskGroups.reduce((count: any, obj: any) => {
        if (obj.is_active === true) {
          return count + 1;
        }
        return count;
      }, 0),
    }));
    const totalCount = countArray?.reduce(
      (total: any, item: any) => total + item.count,
      0
    );
    return totalCount ? totalCount : 0;
  };

  const calculatePercentages = (response: any) => {
    const totalCount = response?.value?.length || 1;

    const status =
      response.name === "Bronze DQ Status"
        ? "data_mart_test_passed"
        : response.name === "Silver DQ Status"
        ? "data_vault_test_passed"
        : "data_mart_test_passed";
    const statusCounts = response?.value?.reduce((acc: any, curr: any) => {
      acc[curr[status]] = (acc[curr[status]] || 0) + 1;
      return acc;
    }, {});

    const failedPercentage = statusCounts?.["FAILED"] || 0;
    const passedPercentage = statusCounts?.["PASSED"] || 0;
    const noRecordPercentage =
      ((statusCounts?.["NO_RECORD"] || 0) / totalCount) * 100;

    return {
      chartType: response.name,
      failedPercentage,
      passedPercentage,
      noRecordPercentage,
    };
  };

  const chartData = [bronzeLayerData, silverLayerData, goldLayerData].map(
    (apiResponse, index) => ({
      ...calculatePercentages(apiResponse),
      id: index,
    })
  );

  const handleTableRowClick = (row: any) => {
    setSelectedRow(row);
    setCurrentJTGData((pre: any) => ({
      bronzeJtgStatus: row?.layer === "Bronze" ? getStatus(row?.job_status) : null,
      silverJtgStatus: row?.layer === "Silver" ? getStatus(row?.job_status) : null,
      goldJtgStatus: row?.layer === "Gold" ? getStatus(row?.job_status) : null,
    }));
    setCurrentJobData({ jobStatus: getStatus(row?.job_status) });
  };

  const handleJobMonitorRefresh = () => {
    setIsLoading(true);
    fetchJobMonitorDetails();
  };

  const handleDqMonitorRefresh = () => {
    setIsDqLoading(true);
    setDataSource([]);
    setCustomPlugIn([]);
    setJobData([]);
    setJobTaskGroupData([]);
    setSilverLayerData({
      name: "",
      value: [],
    });
    setGoldLayerData({
      name: "",
      value: [],
    });
    setBronzeLayerData({
      name: "",
      value: [],
    });
    setJobTaskGroupTypeData({
      bronze: 0,
      silver: 0,
      gold: 0,
    });
    setJobTaskGroupActiveTypeData({
      bronze: 0,
      silver: 0,
      gold: 0,
    });
    fetchDqMonitorDetails();
  };

  const [hoveredColumn, setHoveredColumn] = useState(null);

  const handleMouseEnter = (rowId:string, columnIndex:number) => {
    setHoveredRow(rowId);
    setHoveredColumn(columnIndex);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
    setHoveredColumn(null);
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      return null 
    });
  };
  

  const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | null>(
    null
  );
  const tableRef = useRef<HTMLTableElement>(null);

  const handleMouseMove = (event: MouseEvent) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleScroll = () => {
    if (!tableRef.current || !mousePosition) return;

    const tableRect = tableRef.current.getBoundingClientRect();
    const rows = Array.from(tableRef.current.querySelectorAll("tbody tr"));

    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      const row = rows[rowIndex];
      const rowRect = row.getBoundingClientRect();

      // Check if the mouse is over this row
      if (
        mousePosition.x >= tableRect.left &&
        mousePosition.x <= tableRect.right &&
        mousePosition.y >= rowRect.top &&
        mousePosition.y <= rowRect.bottom
      ) {
        const uniqueRowId = `${jobTableData[rowIndex].job_execution_id}-${rowIndex}`;
        setHoveredRow(uniqueRowId);
        break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    tableRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      tableRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [mousePosition]);

  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeout = useRef(null);
  
  const handleScrollV2 = () => {
    if (!isScrolling) {
      setIsScrolling(true); // Set to true when scrolling starts
    }

    // Clear the previous timeout if the user is still scrolling
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }

    // Set a timeout to detect scroll stop
    scrollTimeout.current = setTimeout(() => {
      setIsScrolling(false); // Set to false when scrolling stops
    }, 200); // Adjust timeout duration as needed
  };

  useEffect(() => {
    return () => {
      // Clear timeout on unmount
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, []);

  return (
    <>
      <div className={style.dashboardMainContainer}>
        {/* <BreadCrumbs breadCrumbsData={importDataModelBreadCrumbData} /> */}
        <div className={style.dashboardHeader}>
          <div className={style.dashboardHeaderText}>
            {dqMonitorTab
              ? "Data Quality History"
              : jobMonitorTab
              ? "Job History"
              : "Dashboard"}
          </div>
        </div>
        {!dqMonitorTab && !jobMonitorTab && (
          <div className={style.dashboardContentContainer}>
            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
              <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                <div className={style.orchestrationContainer}>
                  <div className={style.orchestrationContainerHeader}>
                    <div className={style.orchestrationHeaderHeading}>
                      Job Monitor - Current Job Orchestration Status
                    </div>
                    <img
                      alt="refresh_icon"
                      src={refreshIcon}
                      width={30}
                      className={style.refreshIcon}
                      onClick={handleJobMonitorRefresh}
                    ></img>
                  </div>
                  <div className={style.orchestrationChartContainer}>
                    <div className={style.orchestrationGaugeMeterChart}>
                      <div className={style.orchestrationGaugeMeterChartforJob}>
                        <div className={style.chartBox}>
                          <Speedometer
                            currentSpeed={currectJobData?.jobStatus}
                            totalSpeed={200}
                            height={200}
                            width={200}
                            type="Job"
                          />
                        </div>
                        <div className={style.jobMonitorBox}>
                          <div className={style.jobMonitorButton}>
                            <Button
                              className={style.customButton}
                              onClick={jobMonitorButtonClick}
                            >
                              Job History
                            </Button>
                          </div>
                          <div className={style.jobMonitorStatus}>
                            <div className={style.jobMonitorStatusItem}>
                              <div
                                className={style.jobMonitorStatusColorGrey}
                              ></div>
                              Queued / Canceled
                            </div>
                            <div className={style.jobMonitorStatusItem}>
                              <div
                                className={style.jobMonitorStatusColorBlue}
                              ></div>
                              Initiated
                            </div>
                            <div className={style.jobMonitorStatusItem}>
                              <div className={style.jobMonitorStatusColorRed}></div>
                              Failed
                            </div>
                            <div className={style.jobMonitorStatusItem}>
                              <div
                                className={style.jobMonitorStatusColorYellow}
                              ></div>
                              Running
                            </div>
                            <div className={style.jobMonitorStatusItem}>
                              <div
                                className={style.jobMonitorStatusColorGreen}
                              ></div>
                              Succeeded
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={style.dwInfoChartBox}>
                        <div className={style.dwInfoChartBoxBlock}>
                          <div className={style.dwInfoChartBoxContent}>
                            Number of Queued Jobs :{" "}
                            {jobStatusCount?.Queued ? jobStatusCount?.Queued : 0}
                          </div>
                          <div className={style.dwInfoChartBoxContent}>
                            Number of Initiated Jobs :{" "}
                            {jobStatusCount?.Initiated
                              ? jobStatusCount?.Initiated
                              : 0}
                          </div>
                        </div>
                        <div className={style.dwInfoChartBoxBlock}>
                          <div className={style.dwInfoChartBoxContent}>
                            Number of Succeeded Jobs :{" "}
                            {jobStatusCount?.Succeeded
                              ? jobStatusCount?.Succeeded
                              : 0}
                          </div>
                          <div className={style.dwInfoChartBoxContent}>
                            Number of Failed Jobs :{" "}
                            {jobStatusCount?.Failed ? jobStatusCount?.Failed : 0}
                          </div>
                          <div className={style.dwInfoChartBoxContent}>
                            Number of Running Jobs :{" "}
                            {jobStatusCount?.Running ? jobStatusCount?.Running : 0}
                          </div>
                        </div>
                      </div>
                      <div className={style.orchestrationGaugeMeterChartforJtg}>
                        <div className={style.jtgChartBox}>
                          <Speedometer
                            currentSpeed={currectJTGData?.bronzeJtgStatus}
                            totalSpeed={200}
                            height={150}
                            width={150}
                            type="Bronze"
                          />
                        </div>
                        <div className={style.jtgChartBox}>
                          <Speedometer
                            currentSpeed={currectJTGData?.silverJtgStatus}
                            totalSpeed={200}
                            height={150}
                            width={150}
                            type="Silver"
                          />
                        </div>
                        <div className={style.jtgChartBox}>
                          <Speedometer
                            currentSpeed={currectJTGData?.goldJtgStatus}
                            totalSpeed={200}
                            height={150}
                            width={150}
                            type="Gold"
                          />
                        </div>
                      </div>
                  
                    </div>
                  </div>
                </div>
                <div className={style.qualityStatusContainer}>
                  <div className={style.qualityStatus}>
                    <div className={style.qualityStatusHeader}>
                      <div className={style.orchestrationHeaderHeading}>
                        DQ Monitor - Current Data Lakehouse Data Quality
                      </div>
                      <img
                        alt="refresh_icon"
                        src={refreshIcon}
                        width={32}
                        className={style.refreshIcon}
                        onClick={handleDqMonitorRefresh}
                      ></img>
                    </div>
                    <div className={style.qualityStatusChartContainer}>
                      {isDqLoading && <CustomLoader variant="blue" />}
                      {!isDqLoading &&
                        chartData.map((value: any) => {
                          if (value.chartType === "Not Valid") {
                            return null;
                          } else {
                            return (
                              <div className={style.dataQualityChartBox}>
                                <DoughnutChart
                                  chartData={chartData}
                                  type={value.chartType}
                                  label={["Failed", "Succeeded", "No Record"]}
                                />
                              </div>
                            );
                          }
                        })}
                      <div className={style.dataQualityChartBox}>
                        <div className={style.dataQualityButton}>
                          <Button
                            className={style.customButton}
                            onClick={dqMonitorButtonClick}
                          >
                            DQ History
                          </Button>
                        </div>
                        <div className={style.dataQualityStatus}>
                          <div className={style.dataQualityStatusItem}>
                            <div className={style.dataQualityStatusColorGrey}></div>
                            No record
                          </div>
                          <div className={style.dataQualityStatusItem}>
                            <div
                              className={style.dataQualityStatusColorGreen}
                            ></div>
                            Succeeded
                          </div>
                          <div className={style.dataQualityStatusItem}>
                            <div className={style.dataQualityStatusColorRed}></div>
                            Failed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={style.dwInfo}>
                    <div className={style.dwInfoHeader}>
                      Data Lakehouse/ DW Info
                    </div>
                    <div className={style.dwInfoChart}>
                      <div className={style.dwInfoChartContainer}>
                        <div className={style.dwInfoChartBox}>
                          <div className={style.dwInfoChartBoxContent}>
                            Number of Sources :
                            {dataSource &&
                              ` ${dataSource?.length} (${dataSource?.reduce(
                                (count: any, obj: any) => {
                                  if (obj.is_active === true) {
                                    return count + 1;
                                  }
                                  return count;
                                },
                                0
                              )})`}
                          </div>
                          <div className={style.dwInfoChartBoxContent}>
                            Number of Endpoints :
                            {dataSource &&
                              ` ${dataSource?.reduce(
                                (count: any, obj: any) =>
                                  count + obj.source_entity.length,
                                0
                              )} (${dataSource?.reduce((count: any, obj: any) => {
                                return (
                                  count +
                                  obj.source_entity.reduce(
                                    (innerCount: any, innerObj: any) => {
                                      if (innerObj.is_active === true) {
                                        return innerCount + 1;
                                      }
                                      return innerCount;
                                    },
                                    0
                                  )
                                );
                              }, 0)})`}
                          </div>
                          <div className={style.dwInfoChartBoxContent}>
                            Number of Plugins :
                            {customPlugIn &&
                              ` ${customPlugIn?.length} (${customPlugIn?.reduce(
                                (count: any, obj: any) => {
                                  if (obj.is_active === true) {
                                    return count + 1;
                                  }
                                  return count;
                                },
                                0
                              )})`}
                          </div>
                        </div>
                      </div>
                      <div className={style.dwInfoChartBox} 
                        style={{
                          overflowY: "auto",
                          // maxHeight: "px", // Set the max height as desired
                          scrollbarWidth: "thin", // Minimal width for Firefox
                        }}
                      >
                        <div className={style.dwInfoChartBoxContent}>
                          Number of Jobs :{" "}
                          {`${jobData?.length} (${jobData?.reduce(
                            (count: any, obj: any) => {
                              if (obj.is_active === true) {
                                return count + 1;
                              }
                              return count;
                            },
                            0
                          )})`}
                        </div>
                        <div className={style.dwInfoChartBoxContent}>
                          Number of Job Task Groups :{" "}
                          {`${getJobTaskGroupCount()} (${getJobTaskGroupActiveCount()})`}
                        </div>
                        <div className={style.dwInfoChartBoxContent}>
                          Number of Bronze Job Task Groups :{" "}
                          {`${jobTaskGroupTypeData?.bronze} (${jobTaskGroupActiveTypeData?.bronze})`}
                        </div>
                        <div className={style.dwInfoChartBoxContent}>
                          Number of Silver Job Task Groups :{" "}
                          {`${jobTaskGroupTypeData?.silver} (${jobTaskGroupActiveTypeData?.silver})`}
                        </div>
                        <div className={style.dwInfoChartBoxContent}>
                          Number of Gold Job Task Groups :{" "}
                          {`${jobTaskGroupTypeData?.gold} (${jobTaskGroupActiveTypeData?.gold})`}
                        </div>
                      </div>
                    </div>
                  <div className={style.dwInfoChartTip} style={{width:"50%"}}>
                        * Number within ( ) represents active entities
                  </div>
                  </div>
                </div>
              </div>
              <div style={{height:"100%", padding:"20px"}}>
                <div
                    className={`${style.orchestrationTable} table-responsive`}
                    style={{height:"100%"}}
                  >
                    {isLoading ? (
                      <CustomLoader variant="blue" />
                    ) : (
                      <div style={{ maxHeight: "350px", overflowY: "auto" }}
                        onScroll={handleScrollV2}
                      >
                      {jobTableData.length > 0 ?
                      <table ref={tableRef} className={`${style.jobManagementTableStrip}`}>
                        <thead>
                          <tr className={style.jobManagementTableHead}>
                            <th className={`align-middle `}>Execution Id</th>
                            <th className={`align-middle `}>Job Name</th>
                            <th className={`align-middle `}>Job Task Group</th>
                            <th className={`align-middle `}>Status</th>
                            <th className={`align-middle `}>Job Start Time</th>
                            <th className={`align-middle `}>Job End Time</th>
                            <th className={`align-middle `}>
                              Duration (hh:mm:ss)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobTableData.map((data:any, rowIndex:any) => {
                            const uniqueRowId = `${data.job_execution_id}-${rowIndex}`;
                            return(
                            <tr
                              // key={data.job_execution_id}
                              key={uniqueRowId}
                              className={
                                selectedRow === data
                                  ? style.jobManagementTableRowSelected
                                  : style.jobManagementTableRow
                              }
                              onMouseLeave={handleMouseLeave}
                              onClick={() => {
                                handleTableRowClick(data);
                              }}
                            >
                              {[
                                { content: data.job_execution_id, index: 1 },
                                { content: data.job_name, index: 2 },
                                { content: data.job_task_group_name, index: 3 },
                                {
                                  content: (
                                    <div className={style.statusContainer}>
                                      {data.job_status === "Queued" ? (
                                        <div className={style.jobMonitorStatusColorGrey}></div>
                                      ) : data.job_status === "Initiated" ? (
                                        <div className={style.jobMonitorStatusColorBlue}></div>
                                      ) : data.job_status === "Failed" ? (
                                        <div className={style.jobMonitorStatusColorRed}></div>
                                      ) : data.job_status === "Running" ? (
                                        <div className={style.jobMonitorStatusColorYellow}></div>
                                      ) : 
                                      data.job_status === "Cancel" ? (
                                        <div className={style.jobMonitorStatusColorGrey}></div>
                                      ) : (
                                        <div className={style.jobMonitorStatusColorGreen}></div>
                                      )}
                                      <div>{data.job_status}</div>
                                    </div>
                                  ),
                                  index: 4,
                                },
                                { content: data.job_start_time, index: 5 },
                                { content: data.job_end_time, index: 6 },
                                { content: data.job_elapsed_time, index: 7 },
                              ].map((cell) => (
                                <OverlayTrigger
                                  // key={`${data.job_execution_id}-${cell.index}`}
                                  // show={hoveredRow === data.job_execution_id && hoveredColumn === cell.index}
                                  key={`${uniqueRowId}-${cell.index}`}
                                  show={
                                    !isScrolling &&
                                    hoveredRow === uniqueRowId && hoveredColumn === cell.index
                                  }
                                  placement={cell.index <= 4 ? "right" : "left"} // Determine the placement
                                  container={() => {
                                    const containerElement = document.querySelector('.table-pretty');
                                    return containerElement instanceof HTMLElement ? containerElement : document.body;
                                  }}
                                  overlay={
                                    <Popover
                                      id={`popover-${uniqueRowId}-${cell.index}`}
                                      className={`${style.popover3D}`}
                                    >
                                      <Popover.Header as="h3" className={`${style.popoverHeader}`}>
                                        {data.job_name}
                                      </Popover.Header>
                                      <Popover.Body className={style.popoverBody}>
                                        <div>
                                          <strong>Job Execution ID:</strong> {data.job_execution_id}
                                          <FaCopy
                                            className={`${style.copyIcon}`}
                                            onClick={() => handleCopyToClipboard(data.job_execution_id)}
                                            title="Copy to clipboard"
                                          />
                                        </div>
                                        <div>
                                          <strong>Pipeline Execution ID:</strong> {data.pipeline_execution_id || "N/A"}
                                        </div>
                                        <div>
                                          <strong>Layer:</strong> {data.layer}
                                        </div>
                                        <div>
                                          <strong>Job Step Type:</strong> {data.job_step_type_name}
                                        </div>
                                        <div>
                                          <strong>Job Task Group:</strong> {data.job_task_group_name}
                                        </div>
                                        <div>
                                          <strong>Start Time:</strong> {data.job_start_time}
                                        </div>
                                        <div>
                                          <strong>End Time:</strong> {data.job_end_time}
                                        </div>
                                        <div>
                                          <strong>Elapsed Time:</strong> {data.job_elapsed_time}
                                        </div>
                                        <div>
                                          <strong>Status:</strong> {data.job_status}
                                        </div>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                  delay={{ show: 250, hide: 400 }}
                                  
                                >
                                  <td
                                    className="align-middle"
                                    onMouseEnter={() => handleMouseEnter(uniqueRowId, cell.index)}
                                  >
                                    {cell.content}
                                  </td>
                                </OverlayTrigger>
                              ))}
                            </tr>
                          )})}
                        </tbody>
                      </table>
                      : <div>
                          <span>
                            No Job Execution Records to Show for The latest Events.
                          </span>
                        </div>}
                      </div>
                    )}
                  </div>  
              </div>
            </div>
          </div>
        )}
        {dqMonitorTab && <DataQuality setDqMonitorTab={setDqMonitorTab} />}
        {jobMonitorTab && (
          <JobMonitor
            chartData={jobTimeData}
            jobMonitorData={jobMonitorData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            fetchJobMonitorDetails={fetchJobMonitorDetails}
            jobExecutionStatus={jobExecutionStatus}
            jobExecutionHistory={jobExecutionHistory}
            jobExecutionHistoryChartData={jobExecutionHistoryChartData}
            setJobMonitorTab={setJobMonitorTab}
            setJobDurationStartDate={setJobDurationStartDate}
            setJobDurationEndDate={setJobDurationEndDate}
            jobDurationStartDate={jobDurationStartDate}
            jobDurationEndDate={jobDurationEndDate}
            setJobExecutionStartDate={setJobExecutionStartDate}
            setJobExecutionEndDate={setJobExecutionEndDate}
            jobExecutionStartDate={jobExecutionStartDate}
            jobExecutionEndDate={jobExecutionEndDate}
          />
        )}
      </div>
    </>
  );
};

export default DashBoard;
