import React from "react";
import style from "./DeleteConfirmationPopup.module.scss";
import { Button, Modal } from "react-bootstrap";
import DeleteBucketIcon from "../../components/Icons/DeleteBucketIcon.svg";
import CloseIconGreen from "../../assets/images/svg/CloseIconGreen";

export interface PopUpProps {
  popUpMessage:string
  show: boolean;
  onHide: any;
  onSave: any;
}

const DeleteConfirmationPopup = (props: PopUpProps) => {
  const { show, onHide, onSave,popUpMessage } = props;
  const handleSave = () => {
    onSave();
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      aria-labelledby="delete-user-confirmation-popup"
      centered
      className={style.deleteConfirmationPopupModal}
    >
      <div className={style.modalBodayMain}>
        <Modal.Body>
          <div className={style.DeleteConfirmationPopupModalCloseIcon}>
            <CloseIconGreen onClick={onHide} />
          </div>
          <div className={style.modalBoday}>
            <img src={DeleteBucketIcon} alt="Group595" />
            <span className={style.modalBodayLite}>
              {popUpMessage}
            </span>
          </div>
          <div className={style.ConfirmEnvironmentFooter}>
            <Button
              className={style.ConfirmEnvironmentFooterNo}
              onClick={onHide}
            >
              No
            </Button>
            <Button
              className={style.ConfirmEnvironmentFooterYes}
              onClick={() => handleSave()}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
export default DeleteConfirmationPopup;
