// <<<<<<< HEAD
// // import React, { useCallback, useEffect } from 'react';
// // import ReactFlow, {
// //   ReactFlowProvider,
// //   addEdge,
// //   MiniMap,
// //   Controls,
// //   Background,
// //   Connection,
// //   Edge,
// //   Node,
// //   NodeProps,
// //   Handle,
// //   Position,
// //   NodeTypes,
// // //   Elements
// // } from 'reactflow';
// // import 'reactflow/dist/style.css';
// // import sv from "../../components/Icons/dataMart.svg";

// // const CustomNode = ({ data }: NodeProps) => {
// //   return (
// //     <div style={{ 
// //       padding: 10, 
// //       border: '1px solid #ddd', 
// //       borderRadius: 5, 
// //       backgroundColor: '#fff', 
// //       width: '150px',  // Set the desired fixed width
// //       textAlign: 'center' // Center align text and image
// //     }}>
// //       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
// //         <img style={{ width: '30px' }} src={data.image} alt="Icon" />
// //       </div>
// //       <div style={{ fontWeight: 'bold', marginBottom: 5 , fontSize:"10px"}}>{data.label}</div>
// //       <Handle type="target" position={Position.Top} style={{ background: '#555' }} />
// //       <Handle type="source" position={Position.Bottom} style={{ background: '#555' }} />
// //     </div>
// //   );
// // };


// // // const nodeTypes: NodeTypes = {
// // //     custom: CustomNode
// // // };

// // const initialNodes: Node[] = [
// //     { id: '1', type: 'custom', data: { label: 'Data Modeling', image:sv }, position: { x: 100, y: 50 } },
// //     { id: '2', type: 'custom', data: { label: 'Run Automation', image:sv }, position: { x: 300, y: 250 } },
// //     { id: '3', type: 'custom', data: { label: 'Orchestration Modeling', image:sv }, position: { x: 500, y: 50 } },
// //     { id: '4', type: 'custom', data: { label: 'Modeling Orchestration & Data Quality', image:sv }, position: { x: 700, y: 250 } }
// //   ];
  
// //   const initialEdges: Edge[] = [
// //     { id: 'e1-2', source: '1', target: '2', type: 'smoothstep' },
// //     { id: 'e2-3', source: '2', target: '3', type: 'smoothstep' },
// //     { id: 'e3-4', source: '3', target: '4', type: 'smoothstep' }
// //   ];
  
// //   const nodeTypes: NodeTypes = {
// //     custom: CustomNode
// //   };
  
// //   const NewHome: React.FC = () => {
// //     const [nodes, setNodes] = React.useState<Node[]>([]);
// //     const [edges, setEdges] = React.useState<Edge[]>([]);
  
// //     useEffect(() => {
// //       setNodes(initialNodes);
// //       setEdges(initialEdges);
// //     }, []);
  
// //     const onConnect = useCallback((params: Edge | Connection) => setEdges((eds) => addEdge(params, eds)), []);
  
// //     return (
// //       <div style={{ height: '100vh' }}>
// //         <ReactFlowProvider>
// //           <ReactFlow
// //             nodes={nodes}
// //             edges={edges}
// //             panOnDrag={false}
// //             panOnScroll={false}
// //             zoomOnScroll={false}
// //             onConnect={onConnect}
// //             fitView
// //             fitViewOptions={{ padding: 0.2 }}
// //             nodeTypes={nodeTypes}
// //           >
// //             <MiniMap />
// //             <Controls />
// //             <Background color="#aaa" gap={16} />
// //           </ReactFlow>
// //         </ReactFlowProvider>
// //       </div>
// //     );
// //   };
  
// //   export default NewHome;


// import React, { useCallback, useEffect } from 'react';
// import ReactFlow, {
//   addEdge,
//   MiniMap,
//   Controls,
//   Background,
//   Connection,
//   Edge,
//   Node,
// =======



// import React from 'react';
// import ReactFlow, {
//   Controls,
//   Background,
// >>>>>>> develop
//   NodeProps,
//   Handle,
//   Position,
//   NodeTypes,
// <<<<<<< HEAD
//   useReactFlow,
// } from 'reactflow';
// import 'reactflow/dist/style.css';
// import sv from "../../components/Icons/dataMart.svg";

// const CustomNode = ({ data }: NodeProps) => {
//   return (
// =======
//   getBezierPath,
//   EdgeProps,
//   EdgeTypes,
// } from 'reactflow';
// import 'reactflow/dist/style.css';
// import { Nav, OverlayTrigger } from 'react-bootstrap';
// import "./Index.scss"
// import { Link} from 'react-router-dom';
// import { useContent } from './newHomeHook';

// const SideMessage: React.FC = () => {
//   return (
//     <div style={{ 
//       position: 'absolute',
//       top: '10%',
//       left: '2%', 
//       maxWidth: '300px',
//       color: '#1E2134',
//       fontSize: '2rem',
//       fontWeight: 'bold',
//       lineHeight: '1.5',
//       zIndex: 10,
//       marginLeft:"300px"
//     }}>
//       <div>Five Steps</div>
//       <div style={{width:"400px"}}>to Manage and Govern</div>
//       <div>Your Data Platform</div>
//     </div>
//   );
// };

// const CustomNode = ({ data }: NodeProps) => {
//   const { getContentByHeading, determineRouteAndActive } = useContent();

//   const { to, active } = determineRouteAndActive(data.label);

//   return (
//     <>
//     <Nav.Link as={Link} to={to} active={active}>
// >>>>>>> develop
//     <div style={{ 
//       padding: 10, 
//       border: '1px solid #ddd', 
//       borderRadius: 5, 
//       backgroundColor: '#fff', 
// <<<<<<< HEAD
//       width: '150px',  // Set the desired fixed width
//       textAlign: 'center' // Center align text and image
//     }}>
//       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//         <img style={{ width: '30px' }} src={data.image} alt="Icon" />
//       </div>
//       <div style={{ fontWeight: 'bold', marginBottom: 5 , fontSize:"10px"}}>{data.label}</div>
//       <Handle type="target" position={Position.Top} style={{ background: '#555' }} />
//       <Handle type="source" position={Position.Bottom} style={{ background: '#555' }} />
//     </div>
// =======
//       width: '140px',  
//       textAlign: 'center',
//       display:"flex",
//       flexDirection:"column",
//       justifyContent:"center",
//       alignItems:"center",
//     }}>
//       <div style={{width:"100%", display:"flex", flexDirection:"row"}}>
//         <div style={{width:"20%", borderRadius:"60%", border:"1px solid black", height:"10%", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"12px"}}>{data.step}</div>
//         <div style={{width:"80%", marginRight:20, display:"flex", justifyContent:"center", alignItems:"center"}}>
//         <OverlayTrigger
//           key="top"
//           placement={data.label === "Monitor & Govern Your Data" ? "top" : data.label === "Orchestration Modeling" ? "left" : "right"}
//           overlay={
//               <div className="custom-tooltip">
//                 {/* {getContentByHeading(data.label)} */}
//                 {getContentByHeading(data.label).split('\n').map((line, index) => (
//                   <React.Fragment key={index}>
//                     {line}
//                     <br />
//                   </React.Fragment>
//                 ))}
//               </div>
//           }
//         >
//         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:"50px", }}>
//           {data.image}
//         </div>
//       </OverlayTrigger>

//       {data.label !== "Select Data Source" && (
//         <Handle type="target" position={Position.Left} style={{ background: '#555' }} isConnectable={false}/>
//       )}
//       {data.label === "Select Data Source" && (
//       <Handle type="target" position={Position.Bottom} style={{ background: '#555' }} isConnectable={false}/>
//       )}
//       {data.label === "Data Modeling" && (
//       <Handle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={false}/>
//       )}
//       {data.label === "Run Automation" && (
//       <Handle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={false}/>
//       )}
//       {data.label === "Orchestration Modeling" && (
//       <Handle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={false}/>
//       )}
//       {(  data.label === "Monitor & Govern Your Data") && (
//       <Handle type="source" position={Position.Bottom} style={{ background: '#555' }} isConnectable={false}/>
//       )} 
//       {(data.label === "Select Data Source" &&
//       <Handle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={false}/>
//       )} 
//         </div>
//       </div>
//       <div style={{ width:"100%"}}>
//         <div style={{ fontWeight: 'bold', marginBottom: 5 , fontSize:"10px"}}>{data.label}</div>
//       </div>
//     </div>
//     </Nav.Link>
//     </>
//   );
// };

// const CustomEdge = ({
//   id,
//   sourceX,
//   sourceY,
//   targetX,
//   targetY,
//   sourcePosition,
//   targetPosition,
//   style = {},
//   markerEnd,
// }: EdgeProps) => {
//   const [edgePath] = getBezierPath({
//     sourceX,
//     sourceY,
//     targetX,
//     targetY,
//     sourcePosition,
//     targetPosition,
//   });

//   return (
//     <>
//       <defs>
//         <marker
//           id="arrowhead"
//           viewBox="0 0 10 10"
//           refX="8"
//           refY="5"
//           markerWidth="6"
//           markerHeight="6"
//           orient="auto"
//         >
//           <path d="M 0 0 L 10 5 L 0 10 z" fill="#555" />
//         </marker>
//       </defs>
//       <path
//         id={id}
//         style={{ ...style, strokeDasharray: '12,5' }}
//         className="react-flow__edge-path"
//         d={edgePath}
//         markerEnd="url(#arrowhead)"
//       />
//     </>
//   );
// };

// const CustomEdgeToBottom = ({
//   id,
//   sourceX,
//   sourceY,
//   targetX,
//   targetY,
//   sourcePosition,
//   targetPosition,
//   style = {},
//   markerEnd,
// }: EdgeProps) => {
//   // Calculate a path that moves down to the bottom of the screen and then horizontally to the target
//   const midY = Math.max(sourceY, targetY) + 80; // Adjust this value to control how far down the edge travels

//   const edgePath = `
//     M ${sourceX},${sourceY}
//     L ${sourceX},${midY}
//     L ${targetX},${midY}
//     L ${targetX},${targetY}
//   `;

//   return (
//     <>
//       <defs>
//         <marker
//           id="arrowhead"
//           viewBox="0 0 10 10"
//           refX="8"
//           refY="5"
//           markerWidth="6"
//           markerHeight="6"
//           orient="auto"
//         >
//           <path d="M 0 0 L 10 5 L 0 10 z" fill="#555" />
//         </marker>
//       </defs>
//       <path
//         id={id}
//         style={{ ...style, strokeDasharray: '12,5' }}
//         className="react-flow__edge-path"
//         d={edgePath}
//         markerEnd="url(#arrowhead)"
//       />
//     </>
// >>>>>>> develop
//   );
// };

// const nodeTypes: NodeTypes = {
//   custom: CustomNode,
// };

// <<<<<<< HEAD
// const initialNodes: Node[] = [
//   { id: '1', type: 'custom', data: { label: 'Data Modeling', image: sv }, position: { x: 100, y: 50 } },
//   { id: '2', type: 'custom', data: { label: 'Run Automation', image: sv }, position: { x: 300, y: 250 } },
//   { id: '3', type: 'custom', data: { label: 'Orchestration Modeling', image: sv }, position: { x: 500, y: 50 } },
//   { id: '4', type: 'custom', data: { label: 'Modeling Orchestration & Data Quality', image: sv }, position: { x: 700, y: 250 } },
// ];

// const initialEdges: Edge[] = [
//   { id: 'e1-2', source: '1', target: '2', type: 'smoothstep' },
//   { id: 'e2-3', source: '2', target: '3', type: 'smoothstep' },
//   { id: 'e3-4', source: '3', target: '4', type: 'smoothstep' },
// ];

// const NewHome: React.FC = () => {
//   const [nodes, setNodes] = React.useState<Node[]>([]);
//   const [edges, setEdges] = React.useState<Edge[]>([]);
//   const { fitView } = useReactFlow();

//   useEffect(()=>{
//     setNodes(initialNodes)
//     setEdges(initialEdges)
//   },[])

//   const onConnect = useCallback((params: Edge | Connection) => setEdges((eds) => addEdge(params, eds)), []);

//   useEffect(() => {
//     fitView({ padding: 0.2 });
//     const handleResize = () => {
//       fitView({ padding: 0.2 });
//     };
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, [fitView]);

//   return (
//     <div style={{ height: '100vh', width: '100vw' }}>
// =======
// const edgeTypes: EdgeTypes = {
//   custom: CustomEdge,
//   customBottom: CustomEdgeToBottom,
// };


// const NewHome: React.FC = () => {
//   const { nodes,edges,onConnect} = useContent();
  
//   return (
//     <div style={{ height: '90vh', width: '90vw' }}>
//       <SideMessage />
// >>>>>>> develop
//       <ReactFlow
//         nodes={nodes}
//         edges={edges}
//         panOnDrag={false}
//         panOnScroll={false}
//         zoomOnScroll={false}
// <<<<<<< HEAD
// =======
//         zoomOnDoubleClick={false}
// >>>>>>> develop
//         onConnect={onConnect}
//         fitView
//         fitViewOptions={{ padding: 0.2 }}
//         nodeTypes={nodeTypes}
// <<<<<<< HEAD
//       >
//         <MiniMap />
// =======
//         edgeTypes={edgeTypes}
//         nodesConnectable={false} // Disable node connection
//         nodesDraggable={false} // Disable node dragging
//       >
//         {/* <MiniMap /> */}
// >>>>>>> develop
//         <Controls />
//         <Background color="#aaa" gap={16} />
//       </ReactFlow>
//     </div>
//   );
// };

// export default NewHome;





import React from 'react';
import ReactFlow, {
  Controls,
  Background,
  NodeProps,
  Handle,
  Position,
  NodeTypes,
  getBezierPath,
  EdgeProps,
  EdgeTypes,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Nav, OverlayTrigger } from 'react-bootstrap';
import "./Index.scss"
import { Link} from 'react-router-dom';
import { useContent } from './newHomeHook';

const SideMessage: React.FC = () => {
  return (
    <div style={{ 
      position: 'absolute',
      top: '10%',
      left: '2%', 
      maxWidth: '300px',
      color: '#1E2134',
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: '1.5',
      zIndex: 10,
      marginLeft:"300px"
    }}>
      <div>Five Steps</div>
      <div style={{width:"400px"}}>to Manage and Govern</div>
      <div>Your Data Platform</div>
    </div>
  );
};

const CustomNode = ({ data }: NodeProps) => {
  const { getContentByHeading, determineRouteAndActive } = useContent();

  const { to, active } = determineRouteAndActive(data.label);

  return (
    <>
    <Nav.Link as={Link} to={to} active={active}>
    <div style={{ 
      padding: 10, 
      border: '1px solid #ddd', 
      borderRadius: 5, 
      backgroundColor: '#fff', 
      width: '140px',  
      textAlign: 'center',
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
    }}>
      <div style={{width:"100%", display:"flex", flexDirection:"row"}}>
        <div style={{width:"20%", borderRadius:"60%", border:"1px solid black", height:"10%", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"12px"}}>{data.step}</div>
        <div style={{width:"80%", marginRight:20, display:"flex", justifyContent:"center", alignItems:"center"}}>
        <OverlayTrigger
          key="top"
          placement={data.label === "Monitor & Govern Your Data" ? "top" : data.label === "Orchestration Modeling" ? "left" : "right"}
          overlay={
              <div className="custom-tooltip">
                {/* {getContentByHeading(data.label)} */}
                {getContentByHeading(data.label).split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
          }
        >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:"50px", }}>
          {data.image}
        </div>
      </OverlayTrigger>

      {data.label !== "Select Data Source" && (
        <Handle type="target" position={Position.Left} style={{ background: '#555' }} isConnectable={false}/>
      )}
      {data.label === "Select Data Source" && (
      <Handle type="target" position={Position.Bottom} style={{ background: '#555' }} isConnectable={false}/>
      )}
      {data.label === "Data Modeling" && (
      <Handle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={false}/>
      )}
      {data.label === "Run Automation" && (
      <Handle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={false}/>
      )}
      {data.label === "Orchestration Modeling" && (
      <Handle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={false}/>
      )}
      {(  data.label === "Monitor & Govern Your Data") && (
      <Handle type="source" position={Position.Bottom} style={{ background: '#555' }} isConnectable={false}/>
      )} 
      {(data.label === "Select Data Source" &&
      <Handle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={false}/>
      )} 
        </div>
      </div>
      <div style={{ width:"100%"}}>
        <div style={{ fontWeight: 'bold', marginBottom: 5 , fontSize:"10px"}}>{data.label}</div>
      </div>
    </div>
    </Nav.Link>
    </>
  );
};

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  return (
    <>
      <defs>
        <marker
          id="arrowhead"
          viewBox="0 0 10 10"
          refX="8"
          refY="5"
          markerWidth="6"
          markerHeight="6"
          orient="auto"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill="#555" />
        </marker>
      </defs>
      <path
        id={id}
        style={{ ...style, strokeDasharray: '12,5' }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd="url(#arrowhead)"
      />
    </>
  );
};

const CustomEdgeToBottom = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) => {
  // Calculate a path that moves down to the bottom of the screen and then horizontally to the target
  const midY = Math.max(sourceY, targetY) + 80; // Adjust this value to control how far down the edge travels

  const edgePath = `
    M ${sourceX},${sourceY}
    L ${sourceX},${midY}
    L ${targetX},${midY}
    L ${targetX},${targetY}
  `;

  return (
    <>
      <defs>
        <marker
          id="arrowhead"
          viewBox="0 0 10 10"
          refX="8"
          refY="5"
          markerWidth="6"
          markerHeight="6"
          orient="auto"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill="#555" />
        </marker>
      </defs>
      <path
        id={id}
        style={{ ...style, strokeDasharray: '12,5' }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd="url(#arrowhead)"
      />
    </>
  );
};

const nodeTypes: NodeTypes = {
  custom: CustomNode,
};

const edgeTypes: EdgeTypes = {
  custom: CustomEdge,
  customBottom: CustomEdgeToBottom,
};


const NewHome: React.FC = () => {
  const { nodes,edges,onConnect} = useContent();
  
  return (
    <div style={{ height: '90vh', width: '90vw' }}>
      <SideMessage />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        panOnDrag={false}
        panOnScroll={false}
        zoomOnScroll={false}
        zoomOnDoubleClick={false}
        onConnect={onConnect}
        fitView
        fitViewOptions={{ padding: 0.2 }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodesConnectable={false} // Disable node connection
        nodesDraggable={false} // Disable node dragging
      >
        {/* <MiniMap /> */}
        <Controls />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
};

export default NewHome;