import {
  IGetCbcInterface,
  IGetCbcRelationInterface,
  IGetEnsembleModellingInterface,
  IGetEnsembleRelationInterface,
  IPostCbcInterface,
  IPostCbcRelationInterface,
  IPostEnsembleModellingInterface,
  IPostEnsembleRelationInterface,
  IPutCbcInterface,
  IPutEnsembleModellingInterface,
} from "../interfaces/ensembleModelling.interfaces";
import * as api from "./middleware/api.middleware";

const serviceUrl = "ensemble_model";

const getEnvironmentId = () => {
  return localStorage.getItem("environment_id");
};

const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export function getEnsembleModellingDetails() {
  const getResponse = api.get<IGetEnsembleModellingInterface[]>(
    `environment/${getEnvironmentId()}/${serviceUrl}`,
    getAccessToken()
  );
  return getResponse;
}

export function postEnsembleModellingDetails(data: any) {
  const getResponse = api.post<IPostEnsembleModellingInterface>(
    `environment/${getEnvironmentId()}/${serviceUrl}`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function putEnsembleModellingDetails(data: any, id: number) {
  const getResponse = api.put<IPutEnsembleModellingInterface>(
    `environment/${getEnvironmentId()}/${serviceUrl}/${id}`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function deleteEnsembleModellingDetails(id: number) {
  const getResponse = api.del<any>(
    `environment/${getEnvironmentId()}/${serviceUrl}/${id}`,
    null,
    getAccessToken()
  );
  return getResponse;
}

export function getCbcDetails(ensemble_model_id: number) {
  const getResponse = api.get<IGetCbcInterface[]>(
    `environment/${getEnvironmentId()}/${serviceUrl}/${ensemble_model_id}/ensemble_model_cbc`,
    getAccessToken()
  );
  return getResponse;
}

export function postCbcDetails(data: any, ensemble_model_id: number) {
  const getResponse = api.post<IPostCbcInterface>(
    `environment/${getEnvironmentId()}/${serviceUrl}/${ensemble_model_id}/ensemble_model_cbc`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function putCbcDetails(
  data: any,
  ensemble_model_id: number,
  ensemble_model_cbc_id: number
) {
  const getResponse = api.put<IPutCbcInterface>(
    `environment/${getEnvironmentId()}/${serviceUrl}/${ensemble_model_id}/ensemble_model_cbc/${ensemble_model_cbc_id}`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function deleteCbcDetails(
  ensemble_model_id: number,
  ensemble_model_cbc_id: number
) {
  const queryParams: any = {
    ensemble_model_id: ensemble_model_id,
  };

  const queryString = Object.keys(queryParams)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join("&");

  const getResponse = api.del<any>(
    `environment/${getEnvironmentId()}/ensemble_model_cbc/${ensemble_model_cbc_id}?${queryString}`,
    null,
    getAccessToken()
  );
  return getResponse;
}

export function getEnsembleModelRelation() {
  const getResponse = api.get<IGetEnsembleRelationInterface>(
    `environment/${getEnvironmentId()}/ensemble_model_relation`,
    getAccessToken()
  );
  return getResponse;
}

export function getEnsembleModelRelationById(ensembleModelId: any) {
  const getResponse = api.get<IGetEnsembleRelationInterface>(
    `environment/${getEnvironmentId()}/ensemble_model/${ensembleModelId}/ensemble_model_relation`,
    getAccessToken()
  );
  return getResponse;
}

export function postEnsembleModelRelation(data: any) {
  const getResponse = api.post<IPostEnsembleRelationInterface>(
    `environment/${getEnvironmentId()}/ensemble_model_relation`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function deleteEnsembleModelRelation(
  ensemble_model_relation_id: number
) {
  const getResponse = api.del<any>(
    `environment/${getEnvironmentId()}/ensemble_model_relation/${ensemble_model_relation_id}`,
    null,
    getAccessToken()
  );
  return getResponse;
}

export function getEnsembleModelCbcRelation(ensemble_model_id: number) {
  const getResponse = api.get<IGetCbcRelationInterface[]>(
    `environment/${getEnvironmentId()}/ensemble_model/${ensemble_model_id}/ensemble_model_cbc_relation`,
    getAccessToken()
  );
  return getResponse;
}

export function getEnsembleModelCbcRelationById(ensemble_model_cbc_id: number) {
  const getResponse = api.get<IGetCbcRelationInterface[]>(
    `environment/${getEnvironmentId()}/ensemble_model_cbc/${ensemble_model_cbc_id}/ensemble_model_cbc_relation`,
    getAccessToken()
  );
  return getResponse;
}

export function postEnsembleModelCbcRelation(
  data: any,
  ensemble_model_id: number
) {
  const getResponse = api.post<IPostCbcRelationInterface>(
    `environment/${getEnvironmentId()}/ensemble_model/${ensemble_model_id}/ensemble_model_cbc_relation`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function deleteEnsembleModelCbcRelation(
  ensemble_model_cbc_relation_id: number
) {
  const getResponse = api.del<any>(
    `environment/${getEnvironmentId()}/ensemble_model_cbc_relation/${ensemble_model_cbc_relation_id}`,
    null,
    getAccessToken()
  );
  return getResponse;
}
