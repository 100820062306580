import React from "react";

import style from "./ConfirmEnvironmentSetupPopup.module.scss";
import { Button, Modal } from "react-bootstrap";
import closeIcon from "../../components/Icons/closeIconGreen.svg"

export interface PopUpProps {
  show: boolean;
  onHide: any;
}

const AddNewEnvironmentPopup = (props: PopUpProps) => {
  const { show, onHide } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className={style.GuideEnvironmentCloseIconModal}
    >
      <div className={style.modalBodayMain}>
        <Modal.Body>
          <div className={style.GuideEnvironmentSUBCloseIcon}>
                    <img
                    onClick={onHide}
                        src={closeIcon}
                        alt="closeIcon"
                        width={19.94}
                        height={19.9}
                    />
                </div>
          <div className={style.modalBoday}>
            <span className={style.modalBodayLite}>
            You would be re-directed to
            </span>
            <span className={style.modalBodayLite}>
            <span className={style.modalBodayDark}>Market Place</span>
            to add new environment
            </span>
            <div className={style.modalBodayFooterMessage}>
              <span className={style.modalBodayDark}>
              Would you like to proceed?
              </span>
            </div>
          </div>
          <div className={style.ConfirmEnvironmentFooter}>
            <Button
              className={style.ConfirmEnvironmentFooterNo}
              onClick={onHide}
            >
              No
            </Button>
            <Button
              className={style.ConfirmEnvironmentFooterYes}
              onClick={onHide}
              target="_blank"
              href="https://portal.azure.com/#view/Microsoft_Azure_Marketplace/GalleryItemDetailsBladeNopdl/id/wadeinsightab1619844900722.wade_enterprise/selectionMode~/false/resourceGroupId//resourceGroupLocation//dontDiscardJourney~/false/selectedMenuId/home/launchingContext~/%7B%22galleryItemId%22%3A%22wadeinsightab1619844900722.wade_enterprisewade_base_orchestration%22%2C%22source%22%3A%5B%22GalleryFeaturedMenuItemPart%22%2C%22VirtualizedTileDetails%22%5D%2C%22menuItemId%22%3A%22home%22%2C%22subMenuItemId%22%3A%22Search%20results%22%2C%22telemetryId%22%3A%227dfceb25-8689-4f21-bb07-2e89ce73d59b%22%7D/searchTelemetryId/b6978d06-28ce-418a-a5bf-ed0410af588f/isLiteSearchFlowEnabled~/false"
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
export default AddNewEnvironmentPopup;
