import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

interface PreviewModalProps {
  show: boolean;
  onCross: () => void;
  selectedItems: string[];
  formattedString: string;
  onContinue : (e:any)=>void
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  show,
  onCross,
  selectedItems,
  formattedString,
    onContinue
}) => {
  const modalStyle = {
    backgroundColor: "#d3e2e7",
    color: "rgb(3, 35, 74)",
  };

  const buttonStyle = {
    backgroundColor: "rgb(3, 35, 74)",
    color: "#d3e2e7",
    border: `1px solid #d3e2e7`,
  };

  const convertStringToObject = (str: string): { [key: string]: string } => {
    return str.split(',').reduce((acc: { [key: string]: string }, pair) => {
      const [key, value] = pair.split(':');
      if (key && value) {
        acc[key.trim()] = value.trim();
      }
      return acc;
    }, {});
  };

  const formattedObject = convertStringToObject(formattedString);

  return (
    <Modal show={show} onHide={onCross} centered>
      <Modal.Header closeButton style={modalStyle}>
        <Modal.Title>New View Configurations</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalStyle}>
        <h5>Selected Fields:</h5>
        <ul>
          {selectedItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <h5>Selected Filters With Their Values:</h5>
        <pre>{JSON.stringify(formattedObject, null, 2)}</pre>
      </Modal.Body>
      <Modal.Footer style={modalStyle}>
        <Button style={buttonStyle} onClick={onContinue}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewModal;