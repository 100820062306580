import * as api from "./middleware/api.middleware";
const serviceUrl = "users";

export function getIsLoggedInCheck(
  id: number,
  username: string,
  password: string
) {
  const user_name = username;
  const pass_word = password;
  const encodedCredentials = btoa(`${user_name}:${pass_word}`);
  const authHeader = `Basic ${encodedCredentials}`;

  const getResponse = api.get<any>(`${serviceUrl}/${id}/2fa`, {
    Authorization: authHeader,
  });
  return getResponse;
}
