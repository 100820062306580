import { createAction } from "@reduxjs/toolkit";
import { LoginConstants } from "./constants";

export const setIsAuthorized = createAction(
  LoginConstants.USER_SET_IS_AUTORIZED,
  (isAuthorized: boolean) => ({
    payload: { isAuthorized },
  })
);

export const setPassword = createAction(
  LoginConstants.SET_PASSWORD,
  (password: string) => ({
    payload: { password },
  })
);
