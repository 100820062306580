
import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { getCatalogueFilteredData } from '../../services/catalogue.service';
import './selectDataSet.scss';
import * as api from "../../services/middleware/api.middleware";

interface ExampleComponentProps {
  setData: any;
  selectedItems: any;
  formattedString: any;
  multipleSelection: string[];
  setMultipleSelection: any;
  selectedEndpoints: string[];
  setSelectedEndpoints: any;
  setShowErrorModal: any;
  currentPage: number;
}

const SelectedDataSet: React.FC<ExampleComponentProps> = ({
  setData,
  selectedItems,
  formattedString,
  multipleSelection,
  setMultipleSelection,
  selectedEndpoints,
  setSelectedEndpoints,
  setShowErrorModal,
  currentPage
}) => {
  const [dataSourceList, setDataSourceList] = useState<{ data_source_name: string, endpoints: string[] }[]>([]);
  const [expandedDataSources, setExpandedDataSources] = useState<string[]>([]);

  const getEnvironmentId = () => {
    return localStorage.getItem("environment_id");
  };

  const getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  };

  useEffect(() => {
    const fetchDataSources = async () => {
      try {
        const response = await api.get<any>(`environments/${getEnvironmentId()}/data_sources`, getAccessToken());
        const endpoints: { data_source_name: string, endpoints: string[] }[] = response.map((dataSource: any) => ({
          data_source_name: dataSource.data_source_name,
          endpoints: dataSource.source_entity.map((entity: any) => entity.source_entity_name)
        }));
        setDataSourceList(endpoints);
      } catch (error) {
        console.error('Error fetching data sources:', error);
      }
    };

    fetchDataSources();
  }, []);

  const handleDataSourceSelect = (dataSource: string) => {
    setMultipleSelection((prev: any[] = []) => {
      if (prev?.includes(dataSource)) {
        const updatedEndpoints = selectedEndpoints.filter(
          (endpoint) => !dataSourceList.find((ds) => ds.data_source_name === dataSource)?.endpoints?.includes(endpoint)
        );
        setSelectedEndpoints(updatedEndpoints);
        setExpandedDataSources((expanded) => expanded.filter((item) => item !== dataSource)); 
        return prev.filter((item) => item !== dataSource);
      } else {
        setExpandedDataSources((expanded) => [...expanded, dataSource]);
        return [...prev, dataSource];
      }
    });
  };
  const handleEndpointSelect = (endpoint: string, dataSource: string) => {
    setSelectedEndpoints((prevList: any[] = []) => {
      if (prevList?.includes(endpoint)) {
        return prevList.filter((item) => item !== endpoint);
      } else {
        setMultipleSelection((prev: any) => {
          if (!prev?.includes(dataSource)) {
            return [...prev, dataSource];
          }
          return prev;
        });
        return [...prevList, endpoint];
      }
    });
  };

  const toggleDataSource = (dataSource: string) => {
    setExpandedDataSources((prev: string[]) => {
      if (prev?.includes(dataSource)) {
        return prev.filter(item => item !== dataSource);
      } else {
        return [...prev, dataSource];
      }
    });
  };

  const fetchResults = async () => {
    try {
      const response = await getCatalogueFilteredData(
        selectedItems,
        formattedString,
        multipleSelection,
        selectedEndpoints,
        currentPage
      );
      
      if (response.data.length === 0) {
        setShowErrorModal(true);
        setMultipleSelection([]);  
        setSelectedEndpoints([]); 
        setExpandedDataSources([]); 
      } else {
        setData(response);
      }
    } catch (error) {
      console.error('Error fetching results:', error);
    }
  };
  
  const handleSave = () => {
    fetchResults();
    setExpandedDataSources((prev) => [
      ...Array.from(new Set([...prev, ...multipleSelection])) 
    ]);
  };

  return (
    <div className="selected-data-set-container">
      <div className="fixedHeader">
        <thead>
            <tr>
                <th>Select Data Sources and Endpoints</th>
            </tr>
        </thead>
      </div>
      <ul className="data-source-list" style={{ overflow: "auto" }}>
        {dataSourceList.map((dataSource, index) => (
          <li key={index} className="data-source-item">
            <div className="data-source-header" onClick={() => toggleDataSource(dataSource.data_source_name)}>
              <Form.Check
                type="checkbox"
                checked={multipleSelection?.includes(dataSource.data_source_name)}
                onChange={() => handleDataSourceSelect(dataSource.data_source_name)}
                className="checkbox"
              />
              <span>{dataSource.data_source_name}</span>
            </div>
            {expandedDataSources?.includes(dataSource.data_source_name) && (
              <ul className="endpoint-list">
                {dataSource.endpoints.map((endpoint, endpointIndex) => (
                  <li key={endpointIndex} className="endpoint-item">
                    <Form.Check
                      type="checkbox"
                      checked={selectedEndpoints?.includes(endpoint)}
                      onChange={() => handleEndpointSelect(endpoint, dataSource.data_source_name)}
                      className="checkbox"
                    />
                    <span>{endpoint}</span>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <div className="footer">
        <Button className="btn-save" onClick={handleSave} disabled={multipleSelection?.length === 0}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default SelectedDataSet;