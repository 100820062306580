import React from "react";
import YesTick from "../../../Icons/YesTick.svg";
import style from "./PasswordResetMessage.module.scss";
import { Modal } from "react-bootstrap";
import closeIcon from "../../../Icons/closeIconGreen.svg";

export interface PasswordResetMessageProps {
  OnCloseIconClick?: () => void;
  show: boolean;
  onHide: any;
}

const PasswordResetMessage = (props: PasswordResetMessageProps) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={style.GuideEnvironmentCloseIconModal}
    >
      <div className={style.modalBodayMain}>
        <Modal.Body>
          <div className={style.GuideEnvironmentCloseIcon}>
            <img
              onClick={props.onHide}
              src={closeIcon}
              alt="closeIcon"
              width={19.94}
              height={19.9}
            />
          </div>
          <div className={style.modalBoday}>
            <img
              id="yes-tick-icon-id"
              src={YesTick}
              alt="YesTick"
              width={189.64}
              height={113.36}
            />
            <span className={style.modalBodayHeader}>
              The password has been reset successfully!
            </span>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default PasswordResetMessage;
