import React from "react";
interface Group2348 extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
const Group2348 = (props: Group2348) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <g id="Group_2348" data-name="Group 2348" transform="translate(-83 -30)">
        <circle
          id="Ellipse_82"
          data-name="Ellipse 82"
          cx="36"
          cy="36"
          r="36"
          transform="translate(83 30)"
          fill="#03234a"
        />
        <g
          id="Group_2347"
          data-name="Group 2347"
          transform="translate(7516.875 -22901)"
        >
          <path
            id="Path_1840"
            data-name="Path 1840"
            d="M18,8a1.334,1.334,0,0,1,1.334,1.334v.718a6.06,6.06,0,0,1,1.931.8l.508-.508a1.334,1.334,0,1,1,1.886,1.886l-.508.508a6.06,6.06,0,0,1,.8,1.931h.718a1.334,1.334,0,0,1,0,2.667h-.718a6.06,6.06,0,0,1-.8,1.931l.508.508a1.334,1.334,0,0,1-1.886,1.886l-.508-.508a6.058,6.058,0,0,1-1.931.8v.718a1.334,1.334,0,0,1-2.667,0v-.718a6.058,6.058,0,0,1-1.931-.8l-.508.508a1.334,1.334,0,1,1-1.886-1.886l.508-.508a6.058,6.058,0,0,1-.8-1.931h-.718a1.334,1.334,0,0,1,0-2.667h.718a6.06,6.06,0,0,1,.8-1.931l-.508-.508a1.334,1.334,0,0,1,1.886-1.886l.508.508a6.064,6.064,0,0,1,1.931-.8V9.334A1.334,1.334,0,0,1,18,8Zm4,8a4,4,0,1,1-4-4A4,4,0,0,1,22,16Z"
            transform="translate(-7415.875 22947.998)"
            fill="white"
            fill-rule="evenodd"
          />
          <path
            id="Path_1841"
            data-name="Path 1841"
            d="M3.429,28.273V9.364A4.325,4.325,0,0,1,7.714,5H36.286a4.325,4.325,0,0,1,4.286,4.364V28.273A1.442,1.442,0,0,1,42,29.727v2.909A4.325,4.325,0,0,1,37.714,37H6.286A4.325,4.325,0,0,1,2,32.636V29.727A1.442,1.442,0,0,1,3.429,28.273ZM37.714,9.364V28.273H6.286V9.364A1.442,1.442,0,0,1,7.714,7.909H36.286A1.442,1.442,0,0,1,37.714,9.364ZM30.189,31.182a2.847,2.847,0,0,1-2.475,1.455H16.286a2.847,2.847,0,0,1-2.475-1.455H4.857v1.455a1.442,1.442,0,0,0,1.429,1.455H37.714a1.442,1.442,0,0,0,1.429-1.455V31.182Z"
            transform="translate(-7419.875 22946)"
            fill="white"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};
export default Group2348;
