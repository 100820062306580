import React from "react";
import { Doughnut } from "react-chartjs-2";
import style from "./DoughnutChart.module.scss";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import bronzeIcon from "../../components/Icons/bronzeIcon.svg";
import silverIcon from "../../components/Icons/silverIcon.svg";
import goldIcon from "../../components/Icons/goldIcon.svg";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({
  chartData,
  type,
  label,
  setFilteredTableData,
  tableData,
  setIsChartSelected,
  setFilterData,
  filterData,
  setFilterOptions,
  dataVaultPage,
}: any) => {

  const percentageData = chartData?.filter(
    (value: any) => value.chartType === type
  );
  let datasets: any = [];
  if (percentageData) {
    datasets = [
      {
        data: [
          percentageData[0]?.failedPercentage,
          percentageData[0]?.passedPercentage,
          percentageData[0]?.noRecordPercentage,
        ],
        backgroundColor: ["#f80000", "#119f33", "#808080"],
        hoverBackgroundColor: ["#f80000", "#119f33", "#808080"],
      },
    ];
  }
  const data = {
    labels: label,
    datasets: datasets,
    options: {
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      animation: {
        duration: 1000,
      },
      onClick: (event: any, chartElements: any) => {
        if (dataVaultPage !== undefined) {
          if (chartElements.length > 0) {
            if (dataVaultPage) {
              const filteredData = tableData?.filter(
                (value: any) =>
                  value.data_vault_ensemble === type &&
                  value.data_vault_test_passed ===
                    (chartElements[0].element.options.backgroundColor ===
                    "#119f33"
                      ? "PASSED"
                      : "FAILED")
              );
              setFilteredTableData(filteredData);
            } else {
              const filteredData = tableData?.filter(
                (value: any) =>
                  value.data_mart_table_1 === type &&
                  value.data_mart_test_passed ===
                    (chartElements[0].element.options.backgroundColor ===
                    "#119f33"
                      ? "PASSED"
                      : "FAILED")
              );
              setFilteredTableData(filteredData);
            }
            setIsChartSelected(true);
          }
        }
      },
    },
  };

  return (
    <div className={style.doughnutContainer}>
      <div
        className={
          dataVaultPage === undefined
            ? style.doughnutChartHome
            : style.doughnutChart
        }
      >
        <Doughnut
          width={180}
          height={180}
          data={data}
          options={data.options}
          id="speedometer-chart"
        />
        <div className={style.icon}>
          {type === "Bronze DQ Status" ? (
            <img alt="addPlusicon" src={bronzeIcon} width={50} />
          ) : type === "Silver DQ Status" ? (
            <img alt="addPlusicon" src={silverIcon} width={50} />
          ) : type === "Gold DQ Status" ? (
            <img alt="addPlusicon" src={goldIcon} width={50} />
          ) : null}
        </div>
      </div>
      <div
        className={
          dataVaultPage === undefined
            ? style.textStyleJobHome
            : style.textStyleJob
        }
      >
        {type}
      </div>
    </div>
  );
};

export default DoughnutChart;
