import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { addEdge, Connection, Edge, Node, useReactFlow } from 'reactflow';
import Group2348 from "../../assets/images/svg/Group2348";
import Group2417 from "../../assets/images/svg/Group2417";
import Group2363 from "../../assets/images/svg/Group2363";
import Group2357 from "../../assets/images/svg/Group2357";
import React from 'react';

// Define a type for content keys
type ContentKeys =
    | 'Select Data Source'
    | 'Data Modeling'
    | 'Run Automation'
    | 'Orchestration Modeling'
    | 'Monitor & Govern Your Data';

// Define the content associated with each key
const content: Record<ContentKeys, string> = {
    'Select Data Source': `Data transformation made effortless. Discover the advantage of autogenerating the bronze layer. This process step includes selecting various data sources and endpoints that should be stored in your Data Lakehouse's bronze layer. Raw data from the endpoints is automatically ingested and transformed into Delta Lake format.\n
  The bronze layer forms the foundation for further processing of data into the other layers, i.e., silver and gold layers. The WADE SaaS solution autogenerates the bronze layer, which includes a delta comparison of new data with already ingested/transformed data. A full history of data records is maintained, and deleted source records are flagged. The so-called data preparation step is a cumbersome and complex task that is handled automatically by WADE.`,
  
    'Data Modeling': `Discover the future of data management. Focus on data understanding, design, and add metadata about your data models, and let the WADE solution generate the physical data model and its associated ETL/ELT code. Experience the benefits of Data Vault Model Automation for the silver layer.\n
  Seamlessly streamline your already transformed raw data and integrate it into a structured data model that reflects the business view of data. Enter your model metadata describing the different information entities and how they relate to each other. Collaborate with business stakeholders to design a conceptual model with the various information entities for the specific subject area, then input the model data to map data source entities to the data vault model to enable automation in the next step.\n
  Experience the benefits of Data Mart (Kimball) model automation for the gold layer. Harness the power of our automated solution to effortlessly transform your silver layer (Data Vault model) into analytics-ready insights in your Data Mart (Kimball). Seamlessly build data marts that empower strategic decisions and maximize business data insights.`,
  
    'Run Automation': `Automate the complete ETL process for loading data to your silver and gold layers. This process step reads the provided data model metadata and generates all ETL/ELT code to load the data model in the silver layer (Data Vault) and finally generates the code for loading the final layer – Gold (Data Mart). The automation process generates standardized and already tested high-quality code to be executed in your data platform of choice.`,
  
    'Orchestration Modeling': `Introducing the ease-of-use data orchestration management solution from WADE. With this solution, you can effortlessly create and manage your entire data platform orchestration, and create ETL jobs into workflows with dependencies between jobs.\n
  Define the essential metadata to determine what should be included in your process. Organize your job tasks into so-called Job Task Groups (Child Jobs), complete with metadata about data source entities and target entities (i.e., data vault and data mart entities) for each data layer (bronze, silver, and gold). Simplify complex data workflows by graphically designing the orchestration and managing dependencies between jobs. Seamlessly integrate, transform, and move data within your Data Lakehouse environment.`,
  
    'Monitor & Govern Your Data': `Monitor and govern your orchestration process and data quality using the WADE portal's monitoring dashboard. View the execution status and duration for jobs, job task groups, and job tasks. Analyze data quality metrics and diagrams to assess the quality status across different layers within the Data Lakehouse. Use the data lineage function to track the origins, movements, and transformations of data, ensuring accuracy, compliance, and traceability. Enrich your metadata about data sources with the Data Catalog function.`,
  };

// Define a map for routing based on labels
const routeMap: Record<string, string | string[]> = {
    'Select Data Source': '/dataSources',
    'Data Modeling': '/importDataModel',
    'Orchestration Modeling': '/jobDependency',
    'Monitor & Govern Your Data': '/monitor',
};

// Custom hook to provide content and route mapping
export const useContent = () => {
    // Use useMemo to avoid recalculating unless dependencies change
    const getContentByHeading = useMemo(
        () => (heading: ContentKeys): string => {
            return content[heading] || 'Heading not found';
        },
        []
    );

    const location = useLocation();

    const determineRouteAndActive = (label: string) => {
        const route = routeMap[label];
        if (typeof route === 'string') {
            return {
                to: route,
                active: location.pathname === route,
            };
        } else if (Array.isArray(route)) {
            return {
                to: route[0], // Default to the first route for link navigation
                active: route.includes(location.pathname),
            };
        }
        return { to: '', active: false }; // Fallback route
    };

    const initialNodes: Node[] = [
        { id: '1', type: 'custom', data: { label: 'Select Data Source', image: <Group2348 />, step:"1" }, position: { x: -200, y: 100 } },
        { id: '2', type: 'custom', data: { label: 'Data Modeling', image: <Group2417 />, step:"2" }, position: { x: 10, y: 0 } },
        { id: '3', type: 'custom', data: { label: 'Run Automation', image: <Group2363 />, step:"3" }, position: { x: 200, y: 150 } },
        { id: '4', type: 'custom', data: { label: 'Orchestration Modeling', image: <Group2357 />, step:"4" }, position: { x: 380, y: 0 } },
        { id: '5', type: 'custom', data: { label: 'Monitor & Govern Your Data', image: <Group2363 />, step:"5" }, position: { x: 600, y: 150 } },
    ];

    const initialEdges: Edge[] = [
        { id: 'e1-2', source: '1', target: '2', type: 'custom' },
        { id: 'e2-3', source: '2', target: '3', type: 'custom' },
        { id: 'e3-4', source: '3', target: '4', type: 'custom' },
        { id: 'e4-5', source: '4', target: '5', type: 'custom' },
        { id: 'e5-1', source: '5', target: '1', type: 'customBottom' },
    ];

    const [nodes, setNodes] = React.useState<Node[]>([]);
    const [edges, setEdges] = React.useState<Edge[]>(initialEdges);
    const { fitView } = useReactFlow();

    useEffect(()=>{
        setNodes(initialNodes)
    },[])
    
    const onConnect = useCallback((params: Edge | Connection) => setEdges((eds) => addEdge({ ...params, type: 'custom' }, eds)), []);
    
    useEffect(() => {
        fitView({ padding: 0.2 });
        const handleResize = () => {
            fitView({ padding: 0.2 });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [fitView]);

    return { getContentByHeading, determineRouteAndActive, initialNodes, initialEdges, nodes, edges, onConnect };
};