// // Request
import { typedRequest } from './request';
// // Interfaces
import { IMiddlewareOptions } from './interfaces';
// Helpers
import { getHeaders } from './helpers';

export interface ITokenValidatorOptions {
    isUrlExclusive?: boolean;
}

export const get = <T>(url: string, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> =>
    typedRequest<T>(
        url,
        {
            method: 'GET',
            headers: getHeaders(options),
        },
        middlewareOptions
    );

export const post = <T>(url: string, data: object, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> =>
    typedRequest<T>(
        url,
        {
            method: 'POST',
            headers: getHeaders(options),
            body: typeof data === 'object' ? JSON.stringify(data) : data,
        },
        middlewareOptions
    );

export const patch = <T>(url: string, data: object, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> =>
    typedRequest<T>(
        url,
        {
            method: 'PATCH',
            headers: getHeaders(options),
            body: typeof data === 'object' ? JSON.stringify(data) : data,
        },
        middlewareOptions
    );

export const put = <T>(url: string, data: object, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> =>
    typedRequest<T>(
        url,
        {
            method: 'PUT',
            headers: getHeaders(options),
            body: typeof data === 'object' ? JSON.stringify(data) : data,
        },
        middlewareOptions);

export const putFormData = <T>(url: string, data: FormData, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> =>
    typedRequest<T>(
        url,
        {
            method: 'PUT',
            headers: getHeaders(options, true),
            body: data,
        },
        middlewareOptions
    );

export const postFormData = <T>(url: string, data: FormData, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> =>
    typedRequest<T>(
        url,
        {
            method: 'POST',
            headers: getHeaders(options, true),
            body: data,
        },
        middlewareOptions
    );

export const del = <T>(url: string, data?: object, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> =>
    typedRequest<T>(
        url,
        {
            method: 'DELETE',
            headers: getHeaders(options),
            body: typeof data === 'object' ? JSON.stringify(data) : data,
        },
        middlewareOptions
    );