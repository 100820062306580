import * as api from "./middleware/api.middleware";
import { environmentsDetail, usersDetails } from "../interfaces";
const serviceUrl = "customers";
const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export function getEnvironmentsDetail(customer_id: number,environment_id:number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<environmentsDetail>(`data/${serviceUrl}/environment_types.json`)
      : api.get<environmentsDetail>(`${serviceUrl}/${customer_id}/environments/${environment_id}`, getAccessToken());
  return getResponse;
}
export function getCustomerDetail(customer_id: number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<environmentsDetail>(`data/${serviceUrl}/environment_types.json`)
      : api.get<environmentsDetail>(`${serviceUrl}/${customer_id}`, getAccessToken());
  return getResponse;
}

export function assignEnvironmentDetailsDropDown(customer_id: number) {
  const getResponse = api.get<any>(
    `${serviceUrl}/${customer_id}/environments`,
    getAccessToken()
  );
  return getResponse;
}

export function getUsersDetails(customer_id: number,user_id:number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<usersDetails>(`data/${serviceUrl}/environment_types.json`)
      : api.get<usersDetails>(`${serviceUrl}/${customer_id}/users/${user_id}`, getAccessToken());
  return getResponse;
}