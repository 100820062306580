
import { staticConfig } from "../config/static";
import * as api from "../services/middleware/api.middleware";

const getEnvironmentId = () => {
    return localStorage.getItem("environment_id");
};
const getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    return {
        Authorization: `Bearer ${accessToken}`,
    };
};

export const getCatalogueFilteredData = (columns: any, filters: string, source: string[] = [], endpoint: string[] = [], page: number = 1, limit: number = 5) => {

    const filtersParam = filters ? encodeURIComponent(filters) : '';

    const sourceParam = source.map(encodeURIComponent).join(',');
    const endpointParam = endpoint.map(encodeURIComponent).join(',');

    const response = api.get<any>(
        `environment/${getEnvironmentId()}/data_catalogue_filtered?columns=${columns}&filters=${filtersParam}&source=${sourceParam}&endpoint=${endpointParam}&page=${page}&limit=${limit}`,
        getAccessToken()
    );


    return response
}



export const updateCatalogueData = (data: any) => {

    const filteredData = Object.keys(data).reduce((acc: any, key: any) => {
        if (key !== "source_entity_name" && key !== "data_source_name") {
            acc[key] = data[key];
        }
        return acc;
    }, {});


    const response = api.put<any>(
        `environment/${getEnvironmentId()}/update_catalogue_data`,
        filteredData,
        getAccessToken()
    )

    return response
}


export const getViewConfigurationsData = async (forSpecificView: boolean = null, selectedcatalogId: number = null) => {
    const timestamp = new Date().getTime(); // Cache-busting query parameter
    const url = forSpecificView && selectedcatalogId
        ? `https://wade-saas-portal-api-dev.azurewebsites.net/environment/${getEnvironmentId()}/view_configuration/${selectedcatalogId}?_=${timestamp}`
        : `https://wade-saas-portal-api-dev.azurewebsites.net/environment/${getEnvironmentId()}/view_configuration_all/?_=${timestamp}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `${getAccessToken().Authorization}`,
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching view configurations:', error);
        throw error;
    }
};

export const createView = async (viewName: string, selectedFields: string[], filterData: string, selectedDataSources: string[] = [], selectedEndpoints: string[] = []) => {
    const url = `environment/${getEnvironmentId()}/create_view/`;

    const requestData = {
        view_name: viewName,
        selected_fields: selectedFields,
        filter_data: filterData,
        selected_data_sources: selectedDataSources,
        selected_endpoints: selectedEndpoints
    };

    try {
        const getResponse = await api.post<any>(
            url,
            requestData,
            getAccessToken()
        );
        return getResponse.data;

    } catch (error) {
        console.error('Error creating view:', error);
        throw error;
    }
};



export async function updateViewConfiguration(viewConfigId: number, selectedFields: string[], filters: string, viewName: string = undefined, selectedDataSources: string[] = [], selectedEndpoints: string[] = []) {

    const payload = {
        view_config_id: parseInt(`${viewConfigId}`),
        view_name: viewName,
        selected_fields: selectedFields,
        filters: filters || null,
        selected_data_sources: selectedDataSources,
        selected_endpoints: selectedEndpoints
    };

    const response = api.put<any>(
        `environment/${getEnvironmentId()}/update_view_configuration`,
        payload,
        getAccessToken()
    )

    return response
}


export const deleteView = async (viewId: number) => {
    const settings = {
        apiBaseUrl: staticConfig.apiBaseUrl,
    };

    const url = `${settings.apiBaseUrl}environment/${getEnvironmentId()}/delete_catalogue_view/${viewId}`;

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `${getAccessToken().Authorization}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const getResponse = await response.json();

        return getResponse;

    } catch (error) {
        console.error('Error deleting view:', error);
        throw error;
    }
};


