import * as api from "./middleware/api.middleware";

const serviceUrl = "users";
const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

const getEnvironmentId = () => {
  return localStorage.getItem("environment_id");
};

const getCustomerId = () => {
  return localStorage.getItem("customer_id");
};


export function getUserDetails() {
  const getResponse = api.get<any>(
    `customers/${getCustomerId()}/${serviceUrl}`,
    getAccessToken()
  );
  return getResponse;
}

export function getUser(user_id: number) {
  const getResponse = api.get<any>(`${serviceUrl}/${user_id}`, getAccessToken());
  return getResponse;
}

export function postUserDetail(data: any) {
  const getResponse = api.post<any>(
    `customers/${getCustomerId()}/invite`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function updateUserDetails(data: any, userId: number) {
  const getResponse = api.put<any>(
    `customers/${getCustomerId()}/${serviceUrl}/${userId}`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function deleteUserDetails(userId: number) {
  const getResponse = api.del<any>(
    `customers/${getCustomerId()}/${serviceUrl}/${userId}`,
    getAccessToken(),
    getAccessToken(),
  );
  return getResponse;
}

export function environmentSpecificUserDetails() {
  const getResponse = api.get<any>(
    `customers/${getCustomerId()}/environments/${getEnvironmentId()}/${serviceUrl}`,
    getAccessToken()
  );
  return getResponse;
}

export function assignEnvironmentDetails() {
  const getResponse = api.get<any>(
    `customers/${getCustomerId()}/environments/short`,
    getAccessToken()
  );
  return getResponse;
}
