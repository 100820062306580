import React, { useState } from "react";
import WadeLogo from "../../../../components/Icons/WadeLogo.svg";
import Input from "../../../Input/Input";
import styles from "./ForgotPassword.module.scss";
import LoginLeftIcon from "../../../Icons/LoginLeftIcon.png";
import { useNavigate } from "react-router-dom";
import { getForgetPasswordToken } from "../../../../services/forgotPassword.service";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [emailUserID, setEmailUserID] = useState("");
  const [emailUserIDEmpty, setEmailUserIDEmpty] = useState(false);
  const [invalidEmailID, setInvalidEmailID] = useState(false);
  const [
    incorrectforgotPasswordInputChange,
    setIncorrectforgotPasswordInputChange,
  ] = useState(false);

  const validateEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailUserID)) {
      return true;
    } else {
      return false;
    }
  };

  const onEmailUserIDChangeHandler = (evnt: any) => {
    if (invalidEmailID === true || emailUserIDEmpty === true) {
      setIncorrectforgotPasswordInputChange(true);
    }
    setEmailUserID(evnt.target.value);
  };

  const newPasswordCreationHandler = () => {
    setIncorrectforgotPasswordInputChange(false);
    if (emailUserID === "") {
      setInvalidEmailID(false);
      setEmailUserIDEmpty(true);
    } else {
      if (validateEmail()) {
        navigate("/reset_password", {
          state: { emailUserID: emailUserID },
        });
        getForgetPasswordToken(emailUserID);
      } else {
        setEmailUserIDEmpty(false);
        setInvalidEmailID(true);
      }
    }
  };

  return (
    <div className={styles.forgotPageContainer}>
      <div className={styles.forgotPageLeftPanel}>
        <img
          id={styles.forgotLeftIconId}
          src={LoginLeftIcon}
          alt="LoginLeftIcon"
          width={"521px"}
          height={"386px"}
        />
        <div className={styles.forgotPageMainHeading}>
          Accelerated Data Insights. Made Simple.
        </div>
        <div className={styles.forgotPageSubHeading}>
          We transform your domain experts and data engineers to a high
          performing team, focusing on understanding and deriving value out of
          your key data assets.
        </div>
      </div>
      <div className={styles.forgotPageRightPanel}>
        <img
          id={styles.wadeLogoIconId}
          src={WadeLogo}
          alt="WADELOGO"
          width={"100px"}
        />
        <div id={styles.forgotPageLoginHeading}>Forgot Password</div>
        <div className={styles.userIdContainer}>
          <Input
            id={`${
              incorrectforgotPasswordInputChange
                ? `${styles.forgotPageEmailInput}`
                : `${
                    invalidEmailID || emailUserIDEmpty
                      ? `${styles.forgotPasswordWrongInput}`
                      : `${styles.forgotPageEmailInput}`
                  }`
            }`}
            labelId={styles.forgotPageLabelInput}
            label="Email*"
            name="userID*"
            type="email"
            placeholder="Enter your registered Email ID"
            ariaDescribedBy="Enter your registered Email ID"
            value={emailUserID}
            maxlength={100}
            isDisabled={false}
            onChange={(e) => onEmailUserIDChangeHandler(e)}
          />
        </div>
        {emailUserIDEmpty && (
          <div
            id="invalidMessageDisplay"
            className={styles.forgotPasswordInvalidText}
          >
            Email field cannot be empty. Please enter Email
          </div>
        )}
        {invalidEmailID && (
          <div
            id="invalidMessageDisplay"
            className={styles.forgotPasswordInvalidText}
          >
            Invalid Email. Please try again.
          </div>
        )}
        <div className={styles.forgotPagePasswordResetText}>
          Password reset token would be shared to above email ID
        </div>
        <button
          type="button"
          id={styles.forgotPageNewUserIdProceedButton}
          onClick={newPasswordCreationHandler}
        >
          PROCEED
        </button>

        <div id={styles.forgotCopyRightText}>
          <span>©2023 All Rights Reserved</span>
          <span id={styles.innerCopyRightText}>I</span>
          <a href="https://wadeinsight.com/privacy-policy/" target="_blank"> Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
