import React, { useState } from "react";
import styles from "./EmailVerification.module.scss";
import wadeIcon from "../../../components/Icons/wadeIcon.svg";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../../components/Input/Input";
import { cn } from "../../../helpers";
import { getEmailEnable2FAService } from "../../../services/emailEnable2FA.service";

const EmailVerification = () => {
  const [email, setEmail] = useState("");
  const [invalidMailId, setInvalidMailId] = useState(false);
  const [incorrectEmailInputChange, setIncorrectEmailInputChange] =
    useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const validateEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  const onInputChange = (e: any) => {
    if (e.target.name === "email" && invalidMailId) {
      setIncorrectEmailInputChange(true);
    }
    setEmail(e.target.value);
  };

  const onGetCodeButtonClickHandler = () => {
    setIncorrectEmailInputChange(false);
    if (validateEmail()) {
      getEmailEnable2FAService(
        location.state.email,
        location.state.password,
        location.state.user_id
      );
      navigate("/codeVerification", {
        state: {
          email: email,
          password: location.state.password,
          verifyType: "email",
          user_id: location.state.user_id,
        },
      });
    } else {
      setInvalidMailId(true);
    }
  };

  return (
    <div className={cn(styles.emailMain, "d-flex justify-content-center")}>
      <div
        className={cn(
          styles.emailMainBody,
          "d-flex justify-content-center align-items-center"
        )}
      >
        <div>
          <span
            className={cn(
              styles.emailBodyHeadWade,
              "d-flex justify-content-center"
            )}
          >
            <img src={wadeIcon} />
          </span>
          <span
            className={cn(
              styles.emailBodyHead,
              "d-flex justify-content-center"
            )}
          >
            <p>Email code verification</p>
          </span>
          <div
            className={cn(
              styles.emailBodyOptionEmail,
              "d-flex justify-content-center"
            )}
          >
            <div className={styles.emailBodyEmail}>
              <Input
                id={`${
                  incorrectEmailInputChange
                    ? `${styles.emailInput}`
                    : `${
                        invalidMailId
                          ? `${styles.emailWrongInput}`
                          : `${styles.emailInput}`
                      }`
                }`}
                labelId={styles.emailLabel}
                name="email"
                label="Email"
                placeholder="Enter Email ID"
                type="text"
                ariaDescribedBy="Enter Password"
                value={email}
                onChange={(e) => onInputChange(e)}
                maxlength={100}
                isDisabled={false}
              />
              {invalidMailId && (
                <div className={styles.invalidMessageDisplay}>
                  Invalid Email. Please try again.
                </div>
              )}
            </div>
          </div>
          <div
            className={cn(
              styles.emailBodyBTNBody,
              "d-flex justify-content-center"
            )}
          >
            <Button
              disabled={email == ""}
              className={styles.emailBodyBTN}
              onClick={onGetCodeButtonClickHandler}
            >
              GET CODE
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailVerification;
