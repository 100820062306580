import React, { useState, useEffect } from "react";
import WadeLogo from "../../../../components/Icons/WadeLogo.svg";
import styles from "./ChangePassword.module.scss";
import LoginLeftIcon from "../../../Icons/LoginLeftIcon.png";
import { useNavigate, useLocation } from "react-router-dom";
import { cn } from "../../../../helpers";
import PasswordInput from "../../../PasswordInput/PasswordInput";
import { changePasswordService } from "../../../../services/changePassword.service";
import PasswordChangeMessage from "../../Message/PasswordChangeMessage/PasswordChangeMessage";

const ChangePassword = () => {
  const [passwordInput, setPasswordInput] = useState("");
  const [incorrectTemporaryPassword, setIncorrectTemporaryPassword] =
    useState(false);
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const [createPasswordEmpty, setCreatePasswordEmpty] = useState(false);
  const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(false);
  const [createAndConfirmPasswordEmpty, setCreateAndConfirmPasswordEmpty] =
    useState(false);
  const [
    createAndConfirmPasswordMismatch,
    setCreateAndConfirmPasswordMismatch,
  ] = useState(false);
  const [
    incorrectcreatePasswordInputChange,
    setIncorrectcreatePasswordInputChange,
  ] = useState(false);
  const [
    incorrectconfirmPasswordInputChange,
    setIncorrectconfirmPasswordInputChange,
  ] = useState(false);
  const [passwordCriteriaMismatch, setPasswordCriteriaMismatch] =
    useState(false);
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const regexPasswordPattern =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,15}$/;

  useEffect(() => {
    localStorage.removeItem("isLoggedIn");
  }, []);

  const handlePasswordChange = (evnt: any) => {
    setCreateAndConfirmPasswordMismatch(false);
    setIncorrectTemporaryPassword(false);
    if (createPasswordEmpty === true) {
      setIncorrectcreatePasswordInputChange(false);
    }
    setIncorrectcreatePasswordInputChange(true);
    setPasswordInput(evnt.target.value);
  };
  const handleConfirmPasswordChange = (evnt: any) => {
    setCreateAndConfirmPasswordMismatch(false);
    setIncorrectTemporaryPassword(false);
    if (createPasswordEmpty === true) {
      setIncorrectconfirmPasswordInputChange(false);
    }
    setIncorrectconfirmPasswordInputChange(true);
    setConfirmPasswordInput(evnt.target.value);
  };

  const passwordCreationHandler = () => {
    setIncorrectcreatePasswordInputChange(false);
    setIncorrectconfirmPasswordInputChange(false);
    setCreatePasswordEmpty(false);
    setConfirmPasswordEmpty(false);
    setCreateAndConfirmPasswordEmpty(false);
    setCreateAndConfirmPasswordMismatch(false);
    setPasswordCriteriaMismatch(false);
    if (passwordInput === "" && confirmPasswordInput === "") {
      setCreateAndConfirmPasswordEmpty(true);
    } else if (passwordInput === "") {
      setCreatePasswordEmpty(true);
    } else if (confirmPasswordInput === "") {
      setConfirmPasswordEmpty(true);
    } else if (passwordInput !== confirmPasswordInput) {
      setCreateAndConfirmPasswordMismatch(true);
    } else if (
      !regexPasswordPattern.test(passwordInput) ||
      !regexPasswordPattern.test(confirmPasswordInput)
    ) {
      setPasswordCriteriaMismatch(true);
    } else {
      changePasswordService(
        location.state.email,
        location.state.temporary_password,
        confirmPasswordInput
      )
        .then(() => {
          setModalShow(true);
        })
        .catch((res) => {
          if (res.status === 401) {
            if(res.resBody.detail.includes("Temporary password has expired"))
            setIncorrectTemporaryPassword(true);
          }
        });
    }
  };
  return (
    <div className={cn(styles.resetPageContainer, "d-flex")}>
      <div
        className={cn(
          styles.resetPageLeftPanel,
          "d-flex flex-column align-items-center"
        )}
      >
        <img
          id={styles.resetLeftIconId}
          src={LoginLeftIcon}
          alt="LoginLeftIcon"
        />
        <div className={styles.resetPageMainHeading}>
          Accelerated Data Insights. Made Simple.
        </div>
        <div className={styles.resetPageSubHeading}>
          We transform your domain experts and data engineers to a high
          performing team, focusing on understanding and deriving value out of
          your key data assets.
        </div>
      </div>
      <div className={styles.resetPageRightPanel}>
        <img
          id={styles.wadeLogoIconId}
          src={WadeLogo}
          alt="WADELOGO"
          width={"100px"}
        />
        <div id={styles.resetPageLoginHeading}>Change Password</div>
        <div>
          <div className={styles.resetPasswordInputContainer}>
            <PasswordInput
              id={`${
                incorrectcreatePasswordInputChange
                  ? `${styles.resetPageInput}`
                  : `${
                      createPasswordEmpty ||
                      createAndConfirmPasswordEmpty ||
                      createAndConfirmPasswordMismatch ||
                      passwordCriteriaMismatch
                        ? `${styles.resetPasswordWrongInput}`
                        : `${styles.resetPageInput}`
                    }`
              }`}
              labelId={styles.resetPageLabelInput}
              label="New Password"
              name="New Password"
              type="password"
              infoIcon={true}
              infoMessage={`Password should match the below criteria
              \n 1. 8 Minimum Characters
              2. 15 Maximum Characters
              3. One lowercase and one upper case alphabet.
              4. Minimum one numeric 0-9
              5. Minimum one special character (!@#$%&*)`}
              placeholder="Password"
              ariaDescribedBy="Enter Password"
              value={passwordInput}
              maxlength={100}
              isDisabled={false}
              onChange={(e) => handlePasswordChange(e)}
              isError={
                !incorrectcreatePasswordInputChange &&
                (createPasswordEmpty ||
                  createAndConfirmPasswordEmpty ||
                  createAndConfirmPasswordMismatch ||
                  passwordCriteriaMismatch)
              }
            />
            {createPasswordEmpty && (
              <div
                id="invalidMessageDisplay"
                className={styles.resetPasswordInvalidText}
              >
                New Password cannot be empty. Please enter new Password
              </div>
            )}
          </div>
          <div className={styles.resetPasswordInputContainer}>
            <PasswordInput
              id={`${
                incorrectconfirmPasswordInputChange
                  ? `${styles.resetPageInput}`
                  : `${
                      confirmPasswordEmpty ||
                      createAndConfirmPasswordEmpty ||
                      createAndConfirmPasswordMismatch ||
                      passwordCriteriaMismatch
                        ? `${styles.resetPasswordWrongInput}`
                        : `${styles.resetPageInput}`
                    }`
              }`}
              labelId={styles.resetPageLabelInput}
              label="Confirm Password"
              name="Confirm Password"
              type="password"
              infoIcon={true}
              infoMessage={`Password should match the below criteria
              \n 1. 8 Minimum Characters
              2. 15 Maximum Characters
              3. One lowercase and one upper case alphabet.
              4. Minimum one numeric 0-9
              5. Minimum one special character (!@#$%&*)`}
              placeholder="Password"
              ariaDescribedBy="Enter Password"
              value={confirmPasswordInput}
              maxlength={100}
              isDisabled={false}
              onChange={(e) => handleConfirmPasswordChange(e)}
              isError={
                !incorrectconfirmPasswordInputChange &&
                (confirmPasswordEmpty ||
                  createAndConfirmPasswordEmpty ||
                  createAndConfirmPasswordMismatch ||
                  passwordCriteriaMismatch)
              }
            />
            {confirmPasswordEmpty && (
              <div
                id="invalidMessageDisplay"
                className={styles.resetPasswordInvalidText}
              >
                Confirm Password cannot be empty. Please enter Confirm Password
              </div>
            )}
            {createAndConfirmPasswordEmpty && (
              <div
                id="invalidMessageDisplay"
                className={styles.resetPasswordInvalidText}
              >
                Create Password and Confirm Password cannot be empty. Please
                Enter it
              </div>
            )}
            {createAndConfirmPasswordMismatch && (
              <div
                id="invalidMessageDisplay"
                className={styles.resetPasswordInvalidText}
              >
                Create new password and Confirm password are mismatched. Please
                verify
              </div>
            )}
            {passwordCriteriaMismatch && (
              <div
                id="invalidMessageDisplay"
                className={styles.resetPasswordInvalidText}
              >
                Password does not meet the criteria. Please try again
              </div>
            )}
            {incorrectTemporaryPassword && (
              <div
                id="invalidMessageDisplay"
                className={styles.resetPasswordInvalidText}
              >
                Temporary password has expired
              </div>
            )}
          </div>
        </div>
        <button
          type="button"
          id={styles.resetPageNewPasswordProceedButton}
          onClick={passwordCreationHandler}
        >
          PROCEED
        </button>
        <div id={styles.resetCopyRightText}>
          <span>©2023 All Rights Reserved</span>
          <span id={styles.innerCopyRightText}>I</span>
          <a href="https://wadeinsight.com/privacy-policy/" target="__blank">
            Privacy Policy
          </a>
        </div>
      </div>
      <PasswordChangeMessage
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          navigate("/");
        }}
      />
    </div>
  );
};

export default ChangePassword;
