import React from "react";
import "./Input.scss";
import Form from "react-bootstrap/Form";
import InputInfoIcon from "../Icons/InputInfoIcon.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface InputProps {
  id?: string;
  labelId?: string;
  type?: string;
  label?: string;
  isRequired? : boolean;
  placeholder?: string;
  name?: string;
  maxNumber?: string;
  ariaDescribedBy?: string;
  infoMessage?: string;
  value?: any;
  infoIcon?: boolean;
  maxlength?: number;
  isDisabled: boolean | false;
  onChange: (e: any) => void;
  inputClassName?: any;
}

const Input = (props: InputProps) => {
  const {
    id,
    type,
    labelId,
    infoIcon,
    isRequired = false,
    label,
    placeholder,
    name,
    ariaDescribedBy,
    maxNumber,
    value,
    maxlength,
    isDisabled,
    infoMessage,
    onChange,
    inputClassName,
  } = props;

  return (
    <React.Fragment>
      <Form.Group id="form_group_id">
        <Form.Label id={labelId}>
          {label}
          {isRequired && <span className="input-required">*</span>}
          {infoIcon && (
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip className="toolTipMessage">{infoMessage}</Tooltip>
              }
            >
              <img className="InputInfoIcon" width={15} src={InputInfoIcon} />
            </OverlayTrigger>
          )}
        </Form.Label>
        <Form.Control
          id={id}
          type={type}
          name={name}
          value={value}
          max={maxNumber}
          autoComplete="off"
          disabled={isDisabled}
          maxLength={maxlength}
          placeholder={placeholder}
          className={inputClassName}
          onChange={(e) => onChange(e)}
          aria-describedby={ariaDescribedBy}
        />
      </Form.Group>
    </React.Fragment>
  );
};

export default Input;
