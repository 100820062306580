import {
  CreateDataSource,
  authenticationTypesDD,
  createEndpointDetail,
  createEnvironment,
  dataFormats,
  deleteDataSource,
  deleteEndPoints,
  editEndpointDetail,
  selectEnvironment,
} from "../interfaces";
import * as api from "./middleware/api.middleware";

const serviceUrl = "data_sources";
const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};



export function getDataPlatformTypes() {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<any>(`data/${serviceUrl}/data_platform_types.json`)
      : api.get<any>(`${serviceUrl}/data_source_types`, getAccessToken());
  return getResponse;
}

//Authentication Type dropDown
export function getAuthenticationTypes(data_source_id: number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<authenticationTypesDD>(
        `data/${serviceUrl}/source_entities/authentication_types_db.json`
      )
      : api.get<authenticationTypesDD>(
        `${serviceUrl}/${data_source_id}/source_entities/authentication_types_db`,
        getAccessToken()
      );
  return getResponse;
}

//Data formet dropDown
export function getdataFormetDropDown(data_source_id: number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<dataFormats>(
        `data/${serviceUrl}/source_entities/data_formats.json`
      )
      : api.get<dataFormats>(
        `${serviceUrl}/${data_source_id}/source_entities/data_formats`,
        getAccessToken()
      );
  return getResponse;
}

//column Delimiters dropDown
export function getColumnDelimitersDropDown(data_source_id: number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<dataFormats>(
        `data/${serviceUrl}/source_entities/column_delimiters.json`
      )
      : api.get<dataFormats>(
        `${serviceUrl}/${data_source_id}/source_entities/column_delimiters`,
        getAccessToken()
      );
  return getResponse;
}

//encoding Types dropDown
export function getEncodingTypesDropDown(data_source_id: number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<dataFormats>(
        `data/${serviceUrl}/source_entities/encoding_types.json`
      )
      : api.get<dataFormats>(
        `${serviceUrl}/${data_source_id}/source_entities/encoding_types`,
        getAccessToken()
      );
  return getResponse;
}

export function updateDataSourcesDetails(
  data: any,
  Envid: number,
  datssourcetId: number
) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<any>(`data/${serviceUrl}/environment_types.json`)
      : api.put<any>(
        `environments/${Envid}/${serviceUrl}/${datssourcetId}`,
        data,
        getAccessToken()
      );
  return getResponse;
}
export function createDataSourcesDetails(data: any, Envid: number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<CreateDataSource>(`data/${serviceUrl}/environment_types.json`)
      : api.post<CreateDataSource>(
        `environments/${Envid}/${serviceUrl}`,
        data,
        getAccessToken()
      );
  return getResponse;
}
// DataSource EndPointDetails
export function createEndPointDetails(data: any, DataSourceId: number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<createEndpointDetail>(
        `data/${serviceUrl}/environment_types.json`
      )
      : api.post<createEndpointDetail>(
        `${serviceUrl}/${DataSourceId}/source_entity`,
        data,
        getAccessToken()
      );
  return getResponse;
}

export function updateEndPointDetails(
  data: any,
  datssourcetId: number,
  EndPointID: number
) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<editEndpointDetail>(`data/${serviceUrl}/environment_types.json`)
      : api.put<editEndpointDetail>(
        `${serviceUrl}/${datssourcetId}/source_entity/${EndPointID}`,
        data,
        getAccessToken()
      );
  return getResponse;
}
export function deleteDataSourcesDetails(Envid: number, DataSourceId: number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<deleteDataSource>(`data/${serviceUrl}/environment_types.json`)
      : api.del<deleteDataSource>(
        `environments/${Envid}/${serviceUrl}/${DataSourceId}`,
        getAccessToken(),
        getAccessToken(),
      );
  return getResponse;
}
export function deleteEndPointDetails(
  DataSourceId: number,
  EndPointIs: Number
) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<deleteEndPoints>(`data/${serviceUrl}/environment_types.json`)
      : api.del<deleteEndPoints>(
        `${serviceUrl}/${DataSourceId}/source_entity/${EndPointIs}`,
        getAccessToken(),
        getAccessToken(),
      );
  return getResponse;
}

// Import Data Sources Select Environments
export function selectEnvironments(data: any, EnvId: number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<selectEnvironment>(`data/${serviceUrl}/environment_types.json`)
      : api.post<selectEnvironment>(
        `environments/${EnvId}/${serviceUrl}/compare_data_sources`,
        data,
        getAccessToken()
      );
  return getResponse;
}

// Import Data Sources Proceed
export function createEnvironments(data: any, EnvId: number) {
  const getResponse =
    process.env.REACT_APP_API_URL === "http://localhost:3000/"
      ? api.get<createEnvironment>(`data/${serviceUrl}/environment_types.json`)
      : api.post<createEnvironment>(
        `environments/${EnvId}/${serviceUrl}/import_data_sources`,
        data,
        getAccessToken()
      );
  return getResponse;
}
