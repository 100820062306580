import * as api from './middleware/api.middleware';

const serviceUrl = "environments";
const getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  };

export function getDataPlatformTargetType() {
    const getResponse = process.env.REACT_APP_API_URL === "http://localhost:3000/" ?
        api.get<any>(`data/${serviceUrl}/environment_types.json`) :
        api.get<any>(`${serviceUrl}/data_platform_types?is_on_prem=${false}`,getAccessToken())
    return getResponse;
}
export function getOnPremisesDataPlatformTypes() {
    const getResponse = process.env.REACT_APP_API_URL === "http://localhost:3000/" ?
        api.get<any>(`data/${serviceUrl}/environment_types.json`) :
        api.get<any>(`${serviceUrl}/data_platform_types?is_on_prem=${true}`,getAccessToken())
    return getResponse;
}
export function getEnvironmentsDetails() {
    const getResponse = process.env.REACT_APP_API_URL === "http://localhost:3000/" ?
        api.get<any>(`data/${serviceUrl}/environment_types.json`) :
        api.get<any>(`${serviceUrl}/environment_types`,getAccessToken())
    return getResponse;
}
export function getDataSources(Id:number) {
    const getResponse = process.env.REACT_APP_API_URL === "http://localhost:3000/" ?
        api.get<any>(`data/${serviceUrl}/data_platform_types.json`) :
        api.get<any>(`${serviceUrl}/${Id}/data_sources`,getAccessToken())
    return getResponse;
}
export function getDataSourcesForEnvironments(Id:number) {
    const getResponse = process.env.REACT_APP_API_URL === "http://localhost:3000/" ?
        api.get<any>(`data/${serviceUrl}/data_platform_types.json`) :
        api.get<any>(`${serviceUrl}/${Id}/data_sources/${2}`,getAccessToken())
    return getResponse;
}
export function getEnvironmentSetupFiles(Id:number,environment_id:number) {
    const getResponse = process.env.REACT_APP_API_URL === "http://localhost:3000/" ?
        api.get<any>(`data/${serviceUrl}/data_platform_types.json`) :
        api.get<any>(`customers/${Id}/${serviceUrl}/${environment_id}/environment_setup_files`,getAccessToken())
    return getResponse;
}
export function updateEnvironmentsDetails(data:any,customer_id:number,environment_id:number) {
    const getResponse = process.env.REACT_APP_API_URL === "http://localhost:3000/" ?
        api.get<any>(`data/${serviceUrl}/environment_types.json`) :
        api.put<any>(`customers/${customer_id}/${serviceUrl}/${environment_id}`,data,getAccessToken())
    return getResponse;
}