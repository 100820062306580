import React, { useEffect, useRef, useState } from "react";
import style from "./DataQuality.module.scss";
import DoughnutChart from "./DoughnutChart";
import { Accordion, Card } from "react-bootstrap";
import {
  getDataMartHistory,
  getDmDataQuality,
  getRawDataVaultHistory,
  getRdvDataQuality,
} from "../../services/Dashboard.service";
import LineChart from "./LineChart";
import refreshIcon from "../../components/Icons/refresh.svg";
import CustomLoader from "../../components/Loader/Spinner";

const DataQuality = ({ setDqMonitorTab }: any) => {
  const [dataVaultData, setDataVaultData] = useState();
  const [dataMartData, setDataMartData] = useState();
  const [dataVaultPage, setDataVaultPage] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const [chartData, setChartData] = useState([]);
  const [isChartSelected, setIsChartSelected] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [filterOptions, setFilterOptions] = useState<any>({});
  const [rdvChartData, setRdvChartData] = useState<any>();
  const [dataMartChartData, setDataMartChartData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [rdvHistoryData, setRdvHistoryData] = useState([]);
  const [dmHistoryData, setDmHistoryData] = useState([]);
  const chartRef = useRef(null);

  const fetchSilverDataQualityData = () => {
    getRdvDataQuality().then((res: any) => {
      res.sort((a: any, b: any) => {
        const nameA = a.data_vault_ensemble.toUpperCase();
        const nameB = b.data_vault_ensemble.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setDataVaultData(res);
      loadDataVaultPage(res);
      setIsLoading(false);
    });
  };

  const fetchGoldDataQualityData = () => {
    getDmDataQuality().then((res: any) => {
      const modifiedArray = res.map((obj: any) => {
        const { data_mart_name, ...rest } = obj;
        return { data_mart: data_mart_name, ...rest };
      });
      setDataMartData(modifiedArray);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchSilverDataQualityData();
    fetchGoldDataQualityData();
    getRawDataVaultHistory().then((res) => {
      setRdvHistoryData(res);
      getRdvChartData(res);
    });

    getDataMartHistory().then((res) => {
      setDmHistoryData(res);
      getDataMartChartData(res);
    });
  }, []);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (isChartSelected === true) {
        if (chartRef.current && chartRef.current.contains(event.target)) {
          setIsChartSelected(true);
        } else {
          const filtered = tableData.filter((item) =>
            Object.keys(filterOptions)?.every((property) => {
              if (filterOptions[property].size === 0) return true;
              return filterOptions[property].has(item[property]);
            })
          );
          setFilteredTableData(filtered);
          calculatePercentageOfStatus(filtered);
          setIsChartSelected(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isChartSelected]);

  useEffect(() => {
    const filtered = tableData.filter((item) =>
      Object.keys(filterOptions)?.every((property) => {
        if (filterOptions[property].size === 0) return true;
        return filterOptions[property].has(item[property]);
      })
    );

    let filteredLineChartData: any = [];
    if (dataVaultPage) {
      filteredLineChartData = rdvHistoryData.filter((item: any) =>
        Object.keys(filterOptions)?.every((property) => {
          if (property === "subject_area") {
            if (filterOptions[property].size === 0) return true;
            return filterOptions[property].has(item[property]);
          } else {
            return true;
          }
        })
      );
    } else {
      filteredLineChartData = dmHistoryData.filter((item: any) =>
        Object.keys(filterOptions)?.every((property) => {
          if (property === "data_mart") {
            if (filterOptions[property].size === 0) return true;
            return filterOptions[property].has(item[property]);
          } else {
            return true;
          }
        })
      );
    }

    if (!isChartSelected) {
      setFilteredTableData(filtered);
      calculatePercentageOfStatus(filtered);
      if (dataVaultPage) {
        getRdvChartData(filteredLineChartData);
      } else {
        getDataMartChartData(filteredLineChartData);
      }
    }
  }, [filterOptions, tableData]);

  const getRdvChartData = (res: any) => {
    const getRandomColor = () =>
      `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    function getLatestRecords(data: any) {
      const latestRecordsMap = data.reduce((acc: any, current: any) => {
        const key = `${current.subject_area}_${current.execution_datetime.slice(
          0,
          10
        )}`;
        const existingRecord = acc.get(key);

        if (
          !existingRecord ||
          new Date(current.execution_datetime) >
            new Date(existingRecord.execution_datetime)
        ) {
          acc.set(key, current);
        }

        return acc;
      }, new Map<string, any>());

      return Array.from(latestRecordsMap.values());
    }

    const latestRecords: any = getLatestRecords(res);
    let uniqueDatesSet;
    uniqueDatesSet = new Set(
      latestRecords.map((entry: any) => entry.execution_datetime.split("T")[0])
    );

    const uniqueDates = Array.from(uniqueDatesSet);

    const datasets = latestRecords.map((runId: any) => {
      const counts = uniqueDates.map((date) => {
        const entry = latestRecords.find(
          (item: any) =>
            item.execution_datetime.split("T")[0] === date &&
            item.subject_area === runId.subject_area
        );
        return entry ? entry.failed_test_cases : 0;
      });

      const color = getRandomColor();
      return {
        label: runId.subject_area,
        data: counts,
        borderColor: color,
        backgroundColor: color,
        tension: 0,
        borderDash: [0],
      };
    });

    const uniqueArray = datasets.reduce((accumulator: any, current: any) => {
      const existingItem = accumulator.find(
        (item: any) => item.label === current.label
      );
      if (!existingItem) {
        return [...accumulator, current];
      }
      return accumulator;
    }, []);

    const chartData: any = {
      labels: uniqueDates,
      datasets: uniqueArray,
    };

    setRdvChartData(chartData);
  };

  const getDataMartChartData = (res: any) => {
    const getRandomColor = () =>
      `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    function getLatestRecords(data: any) {
      const latestRecordsMap = data.reduce((acc: any, current: any) => {
        const key = `${current.data_mart}_${current.execution_datetime.slice(
          0,
          10
        )}`;
        const existingRecord = acc.get(key);

        if (
          !existingRecord ||
          new Date(current.execution_datetime) >
            new Date(existingRecord.execution_datetime)
        ) {
          acc.set(key, current);
        }

        return acc;
      }, new Map<string, any>());

      return Array.from(latestRecordsMap.values());
    }

    const latestRecords: any = getLatestRecords(res);
    let uniqueDatesSet;
    uniqueDatesSet = new Set(
      latestRecords.map((entry: any) => entry.execution_datetime.split("T")[0])
    );

    const uniqueDates = Array.from(uniqueDatesSet);

    const datasets = latestRecords.map((runId: any) => {
      const counts = uniqueDates.map((date) => {
        const entry = latestRecords.find(
          (item: any) => item.execution_datetime.split("T")[0] === date
        );
        return entry ? entry.failed_test_cases : 0;
      });

      const color = getRandomColor();
      return {
        label: runId.data_mart,
        data: counts,
        borderColor: color,
        backgroundColor: color,
        tension: 0,
        borderDash: [0],
      };
    });

    const uniqueArray = datasets.reduce((accumulator: any, current: any) => {
      const existingItem = accumulator.find(
        (item: any) => item.id === current.id
      );
      if (!existingItem) {
        return [...accumulator, current];
      }
      return accumulator;
    }, []);

    const chartData: any = {
      labels: uniqueDates,
      datasets: uniqueArray,
    };

    setDataMartChartData(chartData);
  };

  const loadDataVaultPage = (res: any) => {
    setTableData(res);
    setFilteredTableData(res);
    calculatePercentageOfStatus(res);
    getFilterDataForDataVault(res);
  };

  const loadDataMartPage = (res: any) => {
    res.sort((a: any, b: any) => {
      const nameA = a?.data_mart.toUpperCase();
      const nameB = b?.data_mart.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    setTableData(res);
    setFilteredTableData(res);
    calculatePercentageOfStatus(res);
    getFilterDataForDataMart(res);
  };

  const calculatePercentageOfStatus = (data: any) => {
    const jobGroups = new Map();
    data.forEach((item: any) => {
      const data_vault_ensemble = item.data_vault_ensemble
        ? item.data_vault_ensemble
        : item.data_mart_table_1;
      const data_vault_test_passed = item.data_vault_test_passed
        ? item.data_vault_test_passed
        : item.data_mart_test_passed;

      if (!jobGroups.has(data_vault_ensemble)) {
        jobGroups.set(data_vault_ensemble, { total: 0, passed: 0, failed: 0 });
      }

      const group = jobGroups.get(data_vault_ensemble);
      group.total++;

      if (data_vault_test_passed === "PASSED") {
        group.passed++;
      } else if (data_vault_test_passed === "FAILED") {
        group.failed++;
      }
    });

    const jobPercentages: any = [];

    jobGroups.forEach((group, data_vault_ensemble) => {
      const { passed, failed } = group;
      const passedPercentage = passed;
      const failedPercentage = failed;

      jobPercentages.push({
        chartType: data_vault_ensemble,
        passedPercentage,
        failedPercentage,
      });
    });

    setChartData(jobPercentages);
  };

  const getFilterDataForDataVault = (data: any) => {
    const options: any = {};
    data.forEach((item: any) => {
      Object.keys(item).forEach((key) => {
        if (!options[key]) {
          options[key] = new Set();
        }
        options[key].add(item[key].toString());
      });
    });

    Object.keys(options).forEach((key) => {
      options[key] = Array.from(options[key]);
    });

    const requiredFilter: any = [
      {
        id: 1,
        filterName: "data_vault_test_passed",
        options: options.data_vault_test_passed.map(
          (item: any, index: any) => ({
            id: index,
            name: item,
            selected: false,
          })
        ),
      },
      {
        id: 2,
        filterName: "subject_area",
        options: options.subject_area.map((item: any, index: any) => ({
          id: index,
          name: item,
          selected: false,
        })),
      },
      {
        id: 3,
        filterName: "data_vault_ensemble",
        options: options.data_vault_ensemble.map((item: any, index: any) => ({
          id: index,
          name: item,
          selected: false,
        })),
      },
      {
        id: 4,
        filterName: "data_vault_test",
        options: options.data_vault_test.map((item: any, index: any) => ({
          id: index,
          name: item,
          selected: false,
        })),
      },
    ];

    setFilterData(requiredFilter);
  };

  const getFilterDataForDataMart = (data: any) => {
    const options: any = {};
    data.forEach((item: any) => {
      Object.keys(item).forEach((key) => {
        if (!options[key]) {
          options[key] = new Set();
        }
        options[key].add(item[key]?.toString());
      });
    });

    Object.keys(options).forEach((key) => {
      options[key] = Array.from(options[key]);
    });

    const requiredFilter: any = [
      {
        id: 1,
        filterName: "data_mart_test_passed",
        options: options?.data_mart_test_passed?.map(
          (item: any, index: any) => ({
            id: index,
            name: item,
            selected: false,
          })
        ),
      },
      {
        id: 2,
        filterName: "data_mart",
        options: options?.data_mart?.map((item: any, index: any) => ({
          id: index,
          name: item,
          selected: false,
        })),
      },
      {
        id: 3,
        filterName: "data_mart_test",
        options: options.data_mart_test?.map((item: any, index: any) => ({
          id: index,
          name: item,
          selected: false,
        })),
      },
      {
        id: 4,
        filterName: "data_mart_table_1",
        options: options.data_mart_table_1?.map((item: any, index: any) => ({
          id: index,
          name: item,
          selected: false,
        })),
      },
    ];

    setFilterData(requiredFilter);
  };

  const handleFilterItemSelect = (
    filterId: number,
    itemId: number,
    filterName: string,
    itemName: string,
    checked: boolean
  ) => {
    const updatedFilteredData = filterData.map((value: any) => {
      if (filterId === value.id) {
        const newOptions = value.options.map((item: any) => {
          if (item.id === itemId) {
            return { ...item, selected: checked };
          } else {
            return item;
          }
        });
        return { ...value, options: newOptions };
      } else {
        return value;
      }
    });
    setFilterData(updatedFilteredData);

    const newOptions: any = new Set(filterOptions[filterName]);
    if (newOptions.has(itemName)) {
      newOptions.delete(itemName);
    } else {
      newOptions.add(itemName);
    }

    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      [filterName]: newOptions,
    }));
  };

  const handleFilterSelect = (
    filterId: number,
    filterName: string,
    checked: boolean
  ) => {
    const updatedFilteredData = filterData.map((value: any) => {
      if (filterId === value.id) {
        const newOptions = value.options.map((item: any) => {
          return { ...item, selected: checked };
        });
        return { ...value, options: newOptions };
      } else {
        return value;
      }
    });
    setFilterData(updatedFilteredData);
    const newOptions: any = new Set(filterOptions[filterName]);

    filterData.forEach((value: any) => {
      if (filterId === value.id) {
        value.options.forEach((item: any) => {
          checked && newOptions.add(item.name);
          !checked && newOptions.delete(item.name);
        });
      }
    });

    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      [filterName]: newOptions,
    }));
  };

  const clearFilter = () => {
    const updatedFilterData = filterData.map((item: any) => ({
      ...item,
      options: item.options.map((option: any) => ({
        ...option,
        selected: false,
      })),
    }));
    setFilterData(updatedFilterData);
    setFilterOptions({});
  };

  const handleSilverDqDataRefresh = () => {
    setIsLoading(true);
    fetchSilverDataQualityData();
  };

  const handleGoldDqDataRefresh = () => {
    setIsLoading(true);
    fetchGoldDataQualityData();
  };

  return (
    <>
      <div className={style.dataQualityContainer}>
        <div className={style.dataQualityContainerHeader}>
          <div
            className={`${style.jobDetailsContainerHeader} ${
              dataVaultPage === true ? style.selectedJobContainer : ""
            }`}
            onClick={() => {
              setDataVaultPage(true);
              loadDataVaultPage(dataVaultData);
              setFilterOptions({});
            }}
          >
            Silver DQ History Status
          </div>
          <div
            className={`${style.jobTaskContainerHeader} ${
              dataVaultPage === false ? style.selectedJobContainer : ""
            }`}
            onClick={() => {
              setDataVaultPage(false);
              loadDataMartPage(dataMartData);
              setFilterOptions({});
            }}
          >
            Gold DQ History Status
          </div>
          <div className={style.backButton} style={{display:"flex", justifyContent:"end"}}>
            <span
              onClick={() => setDqMonitorTab(false)}
              className={style.dashboardLink}
            >
              Switch to Dashboard View
            </span>
          </div>
        </div>
        <div className={style.dataQualityContentContainer}>
          <div className={style.chartAndFilterBox}>
            <div className={style.chartBox}>
              <div className={style.chartBoxHeader}>
                <div className={style.orchestrationHeaderHeading}>
                  {dataVaultPage ? "Data Vault History Status" : "Data Mart History Status"}
                </div>
                <img
                  alt="refresh_icon"
                  src={refreshIcon}
                  width={30}
                  className={style.refreshIcon}
                  onClick={
                    dataVaultPage
                      ? handleSilverDqDataRefresh
                      : handleGoldDqDataRefresh
                  }
                ></img>
              </div>
              <div className={style.chartBoxContent}>
                <div className={style.chartBoxFirstRow}>
                  {isLoading && <CustomLoader variant="blue" />}
                  {!isLoading &&
                    chartData.map((value: any) => {
                      return (
                        <div className={style.dataQualityChartBox}>
                          <DoughnutChart
                            chartData={chartData}
                            type={value.chartType}
                            label={["Failed", "Succeeded"]}
                            setFilteredTableData={setFilteredTableData}
                            tableData={tableData}
                            ref={chartRef}
                            setIsChartSelected={setIsChartSelected}
                            setFilterData={setFilterData}
                            filterData={filterData}
                            setFilterOptions={setFilterOptions}
                            dataVaultPage={dataVaultPage}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className={style.chartBoxSecondRow}>
                  <div className={style.dataQualityChartBox}>
                    <div className={style.dataQualityStatus}>
                      <div className={style.dataQualityStatusItem}>
                        <div
                          className={style.dataQualityStatusColorGreen}
                        ></div>
                        Succeeded
                      </div>
                      <div className={style.dataQualityStatusItem}>
                        <div className={style.dataQualityStatusColorRed}></div>
                        Failed
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.filterBox}>
              <div className={style.filterBoxHeader}>Filter</div>
              <div className={style.accordionContainer}>
                <div className={style.clearFilter} onClick={clearFilter}>
                  Clear Filter
                </div>
                <div className={style.accordion}>
                  {filterData.map((item) => (
                    <div className={style.customAccordionContainer}>
                      <Accordion className={style.customAccordion}>
                        <Card className={style.customCard}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header
                              className={style.accordionItemHeader}
                            >
                              <label className={style.checkboxContainer}>
                                <input
                                  type="checkbox"
                                  name="selectedOption"
                                  checked={item.options?.every(
                                    (option: any) => option.selected
                                  )}
                                  onChange={(e) => {
                                    handleFilterSelect(
                                      item.id,
                                      item.filterName,
                                      e.target.checked
                                    );
                                  }}
                                />
                                <span className={style.checkmark}></span>
                                <span className={style.checkmarkLable}>
                                  {item.filterName}
                                </span>
                              </label>
                            </Accordion.Header>

                            <Accordion.Body className={style.accordionBody}>
                              {item.options?.map((option: any) => (
                                <div
                                  className={
                                    style.accordionBodyElementsContainer
                                  }
                                >
                                  <label className={style.checkboxContainer}>
                                    <input
                                      key={option.id}
                                      type="checkbox"
                                      name="selectedOption"
                                      checked={option.selected}
                                      onChange={(e) => {
                                        handleFilterItemSelect(
                                          item.id,
                                          option.id,
                                          item.filterName,
                                          option.name,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <span className={style.checkmark}></span>
                                    <span className={style.checkmarkLable}>
                                      {option.name}
                                    </span>
                                  </label>
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Card>
                      </Accordion>
                      <div className={style.accordionLine}></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={style.graphBox}>
              <div className={style.graphBoxHeader}>
                {dataVaultPage ? "Data Vault Tests Over Time" : "Data Mart Tests Over Time"}
              </div>
              {/* <div className={style.graphContainer}> */}
              <div style={{ height: "100%", }}>
                {rdvChartData && (
                  <LineChart
                    data={dataVaultPage ? rdvChartData : dataMartChartData}
                    type="dataQuality"
                    page={dataVaultPage}
                  />
                )}
              </div>
              {/* </div> */}
            </div>
          <div className={style.tableBox}>
            <div className={style.tableBoxHeader}>
              {dataVaultPage ? "Data Vault DQ Tests" : "Data Mart DQ Tests"}
            </div>
            {dataVaultPage && (
              <div
                className={`${style.jobManagementMainContainer} table-responsive`}
              >
                {isLoading && <CustomLoader variant="blue" />}
                {!isLoading && (
                  <table className={`${style.jobManagementTableStrip}`}>
                    <thead>
                      <tr className={style.jobManagementTableHead}>
                        <th className={`align-middle `}>Subject Area</th>
                        <th className={`align-middle `}>Data Vault Ensemble</th>
                        <th className={`align-middle ${style.wideColumn}`}>
                          DQ Test
                        </th>
                        <th className={`align-middle `}>Table Type</th>
                        <th className={`align-middle `}>Data Vault Table 1</th>
                        <th className={`align-middle `}>Data Vault Table 2</th>
                        <th className={`align-middle `}>DQ Test Passed</th>
                        <th className={`align-middle `}>Row Count</th>
                        <th className={`align-middle `}>Row Count Distinct</th>
                        <th className={`align-middle `}>System Id</th>
                        <th className={`align-middle ${style.wideColumn}`}>
                          Created at
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTableData.map((data) => (
                        <tr
                          className={style.jobManagementTableRow}
                          key={data.raw_data_vault_test_id}
                        >
                          <td className="align-middle">{data.subject_area}</td>
                          <td className="align-middle">
                            {data.data_vault_ensemble}
                          </td>
                          <td className="align-middle">
                            {data.data_vault_test}
                          </td>
                          <td className="align-middle">
                            {data.data_vault_type}
                          </td>
                          <td className={`align-middle `}>
                            {data.data_vault_table_1}
                          </td>
                          <td className={`align-middle `}>
                            {data.data_vault_table_2}
                          </td>
                          <td className={`align-middle `}>
                            {data.data_vault_test_passed}
                          </td>
                          <td className={`align-middle `}>{data.row_count}</td>
                          <td className={`align-middle `}>
                            {data.row_count_distinct}
                          </td>
                          <td className={`align-middle `}>
                            {data.md_system_id}
                          </td>
                          <td className={`align-middle `}>
                            {data.created_at.substring(0, 19)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
            {!dataVaultPage && (
              <div
                className={`${style.jobManagementMainContainer} table-responsive`}
              >
                {isLoading && <CustomLoader variant="blue" />}
                {!isLoading && (
                  <table className={`${style.jobManagementTableStrip}`}>
                    <thead>
                      <tr className={style.jobManagementTableHead}>
                        <th className={`align-middle `}>Data Mart</th>
                        <th className={`align-middle ${style.wideColumn}`}>
                          DQ Test
                        </th>
                        <th className={`align-middle `}>Table Type</th>
                        <th className={`align-middle `}>Data Mart Table 1</th>
                        <th className={`align-middle `}>Data Mart Table 2</th>
                        <th className={`align-middle `}>DQ Test Passed</th>
                        <th className={`align-middle `}>Row Count</th>
                        <th className={`align-middle `}>Row Count Distinct</th>
                        <th className={`align-middle `}>Table Construct</th>
                        <th className={`align-middle ${style.wideColumn}`}>
                          Created at
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTableData.map((data) => (
                        <tr
                          className={style.jobManagementTableRow}
                          key={data.data_mart_test_id}
                        >
                          <td className="align-middle">{data.data_mart}</td>
                          <td className="align-middle">
                            {data.data_mart_test}
                          </td>
                          <td className="align-middle">
                            {data.data_mart_tbl_type}
                          </td>
                          <td className="align-middle">
                            {data.data_mart_table_1}
                          </td>
                          <td className={`align-middle `}>
                            {data.data_mart_table_2}
                          </td>
                          <td className={`align-middle `}>
                            {data.data_mart_test_passed}
                          </td>
                          <td className={`align-middle `}>{data.row_count}</td>
                          <td className={`align-middle `}>
                            {data.row_count_distinct}
                          </td>
                          <td className={`align-middle `}>
                            {data.data_mart_construct}
                          </td>
                          <td className={`align-middle `}>
                            {data.created_at.substring(0, 19)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DataQuality;
