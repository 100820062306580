import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

interface WarningModalProps {
  show: boolean;
  onCross: any;
  openPreview:any
  message: any;
  primaryColor?: string;
  secondaryColor?: string;
}

const WarningModal: React.FC<WarningModalProps> = ({ show, onCross, openPreview, message, primaryColor = '#6c757d', secondaryColor = '#6c757d' }) => {
  const modalStyle = {
    backgroundColor: "#d3e2e7",
    color: "rgb(3, 35, 74)",
  };

  const buttonStyle = {
    backgroundColor: secondaryColor,
    color: primaryColor,
    border: `1px solid ${primaryColor}`,
  };

  const handleFinal = () =>{
    onCross(false)
    openPreview(true)
  }

  return (
    <Modal show={show} onHide={()=>{onCross(false)}} centered>
      <Modal.Header closeButton style={modalStyle}>
        <Modal.Title>Message</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalStyle}>
        <p>{message()}</p>
      </Modal.Body>
      <Modal.Footer style={modalStyle}>
        <Button style={buttonStyle} onClick={handleFinal}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;