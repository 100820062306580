import React, { useEffect, useState } from "react";
import { ViewMode, Gantt } from "gantt-task-react";
import styles from "./NewGanttChart.module.scss";
import "gantt-task-react/dist/index.css";
import "./NewGanttChart.scss";

function getStartEndDateForProject(tasks: any, projectId: any) {
  const projectTasks = tasks.filter((t: any) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;
  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}

const getBarColor = (job_status: string) => {
  switch (job_status) {
    case "Queued":
      return "#808080";
    case "Initiated":
      return "#0000ff";
    case "Failed":
      return "#f80000";
    case "Running":
      return "#f7ba00";
    case "Succeeded":
      return "#119f33";
    default:
      return "#000000";
  }
};

const NewGanttChart = ({ data }: any) => {
  const [view] = React.useState(ViewMode.Hour);
  const [tasks, setTasks] = useState<any>([]);
  const [initialTasks, setInitialTasks] = useState<any>([]);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [isChecked] = React.useState(true);

  useEffect(() => {
    let newData: any = [];
    if (data != null) {
      data.forEach((jobData: any, jobIndex: any) => {
        let updatedEndDate: any;

        if (jobData.job_duration < 600) {
          const date = new Date(jobData.job_start_time);
          date.setMinutes(date.getMinutes() + 10);
          const newDateString = date.toISOString();
          updatedEndDate = new Date(
            new Date(newDateString).getFullYear(),
            new Date(newDateString).getMonth(),
            new Date(newDateString).getDate(),
            new Date(newDateString).getHours(),
            new Date(newDateString).getMinutes(),
            new Date(newDateString).getSeconds()
          );
        } else {
          updatedEndDate = new Date(
            new Date(jobData.job_end_time).getFullYear(),
            new Date(jobData.job_end_time).getMonth(),
            new Date(jobData.job_end_time).getDate(),
            new Date(jobData.job_end_time).getHours(),
            new Date(jobData.job_end_time).getMinutes(),
            new Date(jobData.job_end_time).getSeconds()
          );
        }

        newData.push({
          // prettier-ignore
          start: new Date(
            (new Date(jobData.job_start_time)).getFullYear(),
            (new Date(jobData.job_start_time)).getMonth(),
            (new Date(jobData.job_start_time)).getDate(),
            (new Date(jobData.job_start_time)).getHours(),
            (new Date(jobData.job_start_time)).getMinutes(),
            (new Date(jobData.job_start_time)).getSeconds(),
          ),
          // prettier-ignore
          end: updatedEndDate,
          name: `(Job). ${jobData.job_name} ${
            jobData.job_start_time.split("T")[0]
          }`,
          id: `${jobIndex + 1}. ${jobData.job_name}`,
          duration: jobData.formatted_duration,
          actualEndDate: jobData.job_end_time,
          status: jobData.job_status,
          type: "project",
          hideChildren: true,
          styles: {
            backgroundColor: getBarColor(jobData.job_status),
            backgroundSelectedColor: getBarColor(jobData.job_status),
          },
        });
        jobData.job_task_groups.forEach(
          (jobTaskGroupData: any, jtgIndex: any) => {
            let updatedEndDate: any;

            if (jobTaskGroupData.job_task_group_duration < 600) {
              const date = new Date(jobTaskGroupData.job_task_group_start_time);
              date.setMinutes(date.getMinutes() + 10);
              const newDateString = date.toISOString();
              updatedEndDate = new Date(
                new Date(newDateString).getFullYear(),
                new Date(newDateString).getMonth(),
                new Date(newDateString).getDate(),
                new Date(newDateString).getHours(),
                new Date(newDateString).getMinutes(),
                new Date(newDateString).getSeconds()
              );
            } else {
              updatedEndDate = new Date(
                new Date(
                  jobTaskGroupData.job_task_group_end_time
                ).getFullYear(),
                new Date(jobTaskGroupData.job_task_group_end_time).getMonth(),
                new Date(jobTaskGroupData.job_task_group_end_time).getDate(),
                new Date(jobTaskGroupData.job_task_group_end_time).getHours(),
                new Date(jobTaskGroupData.job_task_group_end_time).getMinutes(),
                new Date(jobTaskGroupData.job_task_group_end_time).getSeconds()
              );
            }

            newData.push({
              // prettier-ignore
              start: new Date(
               (new Date(jobTaskGroupData.job_task_group_start_time)).getFullYear(),
               (new Date(jobTaskGroupData.job_task_group_start_time)).getMonth(),
               (new Date(jobTaskGroupData.job_task_group_start_time)).getDate(),
               (new Date(jobTaskGroupData.job_task_group_start_time)).getHours(),
               (new Date(jobTaskGroupData.job_task_group_start_time)).getMinutes(),
               (new Date(jobTaskGroupData.job_task_group_start_time)).getSeconds(),
            ),
              // prettier-ignore
              end: updatedEndDate,
              name: `(JTG). ${jobTaskGroupData.job_task_group_name}`,
              id: `${jobIndex + 1}.${jtgIndex + 1}. ${
                jobTaskGroupData.job_task_group_name
              }`,
              actualEndDate: jobTaskGroupData.job_task_group_end_time,
              duration: jobTaskGroupData.formatted_duration,
              status: jobTaskGroupData.job_task_group_status,
              layer: jobTaskGroupData.layer,
              type: "project",
              project: `${jobIndex + 1}. ${jobData.job_name}`,
              hideChildren: true,
              styles: {
                backgroundColor: getBarColor(
                  jobTaskGroupData.job_task_group_status
                ),
                backgroundSelectedColor: getBarColor(
                  jobTaskGroupData.job_task_group_status
                ),
              },
            });
            jobTaskGroupData.job_tasks.forEach(
              (jobTaskData: any, jtIndex: any) => {
                let updatedEndDate: any;

                if (jobTaskData.job_task_duration < 600) {
                  const date = new Date(jobTaskData.job_task_start_time);
                  date.setMinutes(date.getMinutes() + 10);
                  const newDateString = date.toISOString();
                  updatedEndDate = new Date(
                    new Date(newDateString).getFullYear(),
                    new Date(newDateString).getMonth(),
                    new Date(newDateString).getDate(),
                    new Date(newDateString).getHours(),
                    new Date(newDateString).getMinutes(),
                    new Date(newDateString).getSeconds()
                  );
                } else {
                  updatedEndDate = new Date(
                    new Date(jobTaskData.job_task_end_time).getFullYear(),
                    new Date(jobTaskData.job_task_end_time).getMonth(),
                    new Date(jobTaskData.job_task_end_time).getDate(),
                    new Date(jobTaskData.job_task_end_time).getHours(),
                    new Date(jobTaskData.job_task_end_time).getMinutes(),
                    new Date(jobTaskData.job_task_end_time).getSeconds()
                  );
                }

                newData.push({
                  // prettier-ignore
                  start: new Date(
                  (new Date(jobTaskData.job_task_start_time)).getFullYear(),
                  (new Date(jobTaskData.job_task_start_time)).getMonth(),
                  (new Date(jobTaskData.job_task_start_time)).getDate(),
                  (new Date(jobTaskData.job_task_start_time)).getHours(),
                  (new Date(jobTaskData.job_task_start_time)).getMinutes(),
                  (new Date(jobTaskData.job_task_start_time)).getSeconds(),
                ),
                  end: updatedEndDate,
                  name: `(JT). ${jobTaskData.job_task_name} - ${jobTaskData.job_step_type_name} `,
                  id: `${jobIndex + 1}.${jtgIndex + 1}.${jtIndex + 1} ${
                    jobTaskData.job_task_name
                  }`,
                  layer: jobTaskData.layer,
                  actualEndDate: jobTaskData.job_task_end_time,
                  jobStepTypeId: jobTaskData.job_step_type_name,
                  duration: jobTaskData.formatted_duration,
                  type: "project",
                  status: jobTaskData.job_task_status,
                  project: `${jobIndex + 1}.${jtgIndex + 1}. ${
                    jobTaskGroupData.job_task_group_name
                  }`,
                  styles: {
                    backgroundColor: getBarColor(jobTaskData.job_task_status),
                    backgroundSelectedColor: getBarColor(
                      jobTaskData.job_task_status
                    ),
                  },
                });
              }
            );
          }
        );
      });
      setTasks(newData);
      setInitialTasks(newData);
    }
  }, [data]);

  const handleTaskChange = (task: any) => {
    let newTasks = tasks.map((t: any) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t: any) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t: any) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  const handleProgressChange = async (task: any) => {
    setTasks(tasks.map((t: any) => (t.id === task.id ? task : t)));
  };

  const handleExpanderClick = (task: any) => {
    if (task.name.includes("Job")) {
      setTasks(
        initialTasks.map((t: any) => (t.id === task.id ? { ...task } : t))
      );
      setSelectedTask(task);
    } else {
      setTasks(tasks.map((t: any) => (t.id === task.id ? { ...task } : t)));
    }
    let elementWithTitle: any = document.querySelectorAll(
      `[title="${task.name}"]`
    );
    let elementWithJobs: any = document.querySelectorAll("[title*=Job]");

    if (elementWithJobs) {
      elementWithJobs.forEach((elementWithJobs: any) => {
        elementWithJobs.style.fontSize = "18px";
        elementWithJobs.style.fontWeight = "200";
        if (
          elementWithJobs.title === selectedTask?.name &&
          task.name.includes("JTG")
        ) {
          elementWithJobs.style.fontWeight = "800";
        }
      });
    }
    if (elementWithTitle) {
      elementWithTitle.forEach((elementWithTitle: any) => {
        elementWithTitle.style.fontSize = "18px";
        elementWithTitle.style.fontWeight = "600";
        if (task.name.includes("JTG")) {
          elementWithTitle.style.paddingLeft = "10px";
        }
        if (task.name.includes("Job")) {
          elementWithTitle.style.fontWeight = "800";
        }
      });
    }
  };

  const customTooltipContent = (task: any) => {
    const newEndDate: any = new Date(
      new Date(task.task.actualEndDate).getFullYear(),
      new Date(task.task.actualEndDate).getMonth(),
      new Date(task.task.actualEndDate).getDate(),
      new Date(task.task.actualEndDate).getHours(),
      new Date(task.task.actualEndDate).getMinutes(),
      new Date(task.task.actualEndDate).getSeconds()
    );
    const options = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const dateOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const startTime = task.task.start.toLocaleTimeString("en-US", options);
    const endTime = newEndDate.toLocaleTimeString("en-US", options);
    const startDate = task.task.start.toLocaleDateString("en-GB", dateOptions);
    const endDate = newEndDate.toLocaleDateString("en-GB", dateOptions);
    const duration = task.task.duration;
    const status = task.task.status;
    const layer = task.task.layer;
    const jobStepTypeId = task.task.jobStepTypeId;

    return (
      <div className={styles.tooltipContainer}>
        <div>
          Start : {startDate} {startTime}
        </div>
        <div>
          End : {endDate} {endTime}
        </div>
        <div>Duration : {duration} minutes</div>
        <div>Status : {status}</div>
        {layer && <div>Layer : {layer}</div>}
        {jobStepTypeId && <div>Job Step : {jobStepTypeId}</div>}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {tasks.length > 0 && (
        <Gantt
          tasks={tasks}
          viewMode={view}
          preStepsCount={new Date(tasks[0].start).getHours()}
          onDateChange={handleTaskChange}
          onProgressChange={handleProgressChange}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "400px" : ""}
          columnWidth={40}
          rowHeight={50}
          fontSize="13"
          TooltipContent={customTooltipContent}
          barFill={80}
        />
      )}
    </div>
  );
};
export default NewGanttChart;
