import * as api from "./middleware/api.middleware";

const serviceUrl = "role";
const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export function getRoles() {
  const getResponse = api.get<any[]>(`${serviceUrl}`, getAccessToken());
  return getResponse;
}
