import React from "react";
interface userIcon extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
const Group543 = (props: userIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_540"
            data-name="Rectangle 540"
            width="21"
            height="21"
            transform="translate(0.267 0.267)"
            fill="#03234a"
          />
        </clipPath>
      </defs>
      <g id="Group_543" data-name="Group 543" transform="translate(-1789 -21)">
        <circle
          id="Ellipse_54"
          data-name="Ellipse 54"
          cx="19"
          cy="19"
          r="19"
          transform="translate(1789 21)"
          fill="#ecfebb"
        />
        <g
          id="Group_542"
          data-name="Group 542"
          transform="translate(1797.733 29.079)"
        >
          <g
            id="Group_541"
            data-name="Group 541"
            transform="translate(0 -0.347)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_510"
              data-name="Path 510"
              d="M1.762,21.142A1.725,1.725,0,0,1,0,19.38c0-1.762,1.762-7.047,10.571-7.047s10.571,5.285,10.571,7.047a1.725,1.725,0,0,1-1.762,1.762Zm8.809-10.571A5.285,5.285,0,1,0,5.285,5.285a5.285,5.285,0,0,0,5.285,5.285"
              transform="translate(0 0.393)"
              fill="#03234a"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default Group543;
