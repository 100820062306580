import * as api from "./middleware/api.middleware";
const serviceUrl = "users";

const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export function readUsers(username: string, password: string) {
  const user_name = username;
  const pass_word = password;
  const encodedCredentials = btoa(`${user_name}:${pass_word}`);
  const authHeader = `Basic ${encodedCredentials}`;

  const getResponse = api.get<any>(`${serviceUrl}/me`, {
    Authorization: authHeader,
  });
  return getResponse;
}

export function getUserDetailsByToken() {
  const getResponse = api.get<any>(`${serviceUrl}/me/token`, getAccessToken());
  return getResponse;
}
