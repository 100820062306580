import * as api from "./middleware/api.middleware";

const serviceUrl = "first_login";
export function changePasswordService(
  email: string,
  temporary_password: string,
  password: string
) {
  const getResponse = api.post<any>(`users/${serviceUrl}`, {
    email: email,
    temporary_password: temporary_password,
    password: password,
  });
  return getResponse;
}
