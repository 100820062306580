import React, { useState, useRef, useEffect } from "react";
import styles from "./CodeVerification.module.scss";
import wadeIcon from "../../../components/Icons/wadeIcon.svg";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { setIsAuthorized } from "../../../redux/login/actions";
import { useDispatch } from "react-redux";
import { getEmailVerify2FAService } from "../../../services/emailVerify2FA.service";
import { getVerify2FAService } from "../../../services/Verify2FA.service";
import { cn } from "../../../helpers";
import { getExistingUserVerify2FAService } from "../../../services/existingUserVerfiy2FA.service";
import { getEmailEnable2FAService } from "../../../services/emailEnable2FA.service";
import VerifiedAccount from "../../../components/Login/Message/VerifiedAccount/VerifiedAccount";
interface OTPInputProps {
  length: number;
  isLoggedInHandler: () => void;
}

const CodeVerification: React.FC<OTPInputProps> = ({
  length,
  isLoggedInHandler,
}) => {
  const [otp, setOTP] = useState<string[]>([]);
  const inputs = useRef<HTMLInputElement[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [onSubmit, SetOnSubmit] = useState(false);
  const [validOTP, setValidOTP] = useState(true);
  const [resendCodeMessageStatus, setResendCodeMessageStatus] = useState(false);

  const onDoneButtonClick = () => {
    dispatch(setIsAuthorized(true));
    navigate("/onboard");
  };
  const onCloseIconClick = () => {
    SetOnSubmit(false);
    dispatch(setIsAuthorized(true));
    navigate("/onboard");
  };
  useEffect(() => {
    if (inputs.current.length > 0) {
      inputs.current[0].focus();
    }
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (isNaN(Number(value))) {
      return;
    }

    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value !== "") {
      if (index === length - 1) {
        inputs.current[index].blur();
      } else {
        inputs.current[index + 1].focus();
      }
    }
  };

  const onPaste = (event: React.ClipboardEvent) => {
    const { clipboardData } = event;
    const pastedText = clipboardData.getData("text");
    setOTP(pastedText.split(""));
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && otp[index] === "" && index !== 0) {
      inputs.current[index - 1].focus();
    }
  };

  const onSubmitButtonHandler = () => {
    if (location.state.verifyType === "microsoft") {
      getVerify2FAService(
        location.state.user_id,
        otp.join(""),
        location.state.email,
        location.state.password
      )
        .then((res) => {
          res.valid ? SetOnSubmit(!onSubmit) : setValidOTP(false);
          setOTP([]);
        })
        .catch((response) => {
          setValidOTP(false);
          setOTP([]);
        });
    } else if (location.state.verifyType === "email") {
      getEmailVerify2FAService(
        location.state.user_id,
        otp.join(""),
        location.state.email,
        location.state.password
      )
        .then((res: any) => {
          res.message === "ok" ? SetOnSubmit(!onSubmit) : setValidOTP(true);
        })
        .catch((response) => {
          setValidOTP(false);
          setResendCodeMessageStatus(false);
          setOTP([]);
        });
    } else if (location.state.verifyType === "existingUser") {
      getExistingUserVerify2FAService(
        location.state.user_id,
        otp.join(""),
        location.state.email,
        location.state.password
      )
        .then((res: any) => {
          res.is_active === true ? SetOnSubmit(!onSubmit) : setValidOTP(true);
        })
        .catch((response) => {
          setValidOTP(false);
          setResendCodeMessageStatus(false);
          setOTP([]);
        });
    }
  };

  const onResendCodeClick = () => {
    setValidOTP(true);
    setResendCodeMessageStatus(true);
    setOTP([]);
    if (
      location.state.verifyType === "email" ||
      location.state.authMethod === "EMAIL"
    ) {
      getEmailEnable2FAService(
        location.state.email,
        location.state.password,
        location.state.user_id
      );
    }
  };
  return (
    <div>
      {!onSubmit && (
        <div
          className={cn(
            styles.codeVerificationMain,
            "d-flex justify-content-center"
          )}
        >
          <div
            className={cn(
              styles.codeVerificationMainBody,
              "d-flex justify-content-center align-items-center"
            )}
          >
            <div>
              <span
                className={cn(
                  styles.codeVerificationBodyHeadWade,
                  "d-flex justify-content-center"
                )}
              >
                <img src={wadeIcon} />
              </span>
              <span
                className={cn(
                  styles.codeVerificationBodyHeadEmailVerification,
                  "d-flex justify-content-center"
                )}
              >
                {location.state.verifyType === "microsoft" ? (
                  <p>Microsoft code verification</p>
                ) : location.state.authMethod === "AUTHENTICATOR" ? (
                  <p>Microsoft code verification</p>
                ) : (
                  <p>Email code verification</p>
                )}
              </span>
              <span className={styles.codeVerificationBodyHeadCodeNote}>
                {location.state.verifyType === "microsoft" ? (
                  <p>Please enter the code from the Microsoft Authenticator</p>
                ) : location.state.authMethod === "AUTHENTICATOR" ? (
                  <p>Microsoft code verification</p>
                ) : (
                  <p>
                    Please enter the code that has been sent to your registered
                    email id
                  </p>
                )}
              </span>
              <div
                className={cn(
                  styles.codeVerificationBodyOptionEmail,
                  "d-flex justify-content-center"
                )}
              >
                <div
                  className={cn(
                    styles.codeVerificationBodyCode,
                    "d-flex justify-content-center"
                  )}
                >
                  {Array(length)
                    .fill("")
                    .map((_, index) => (
                      <div className={styles.codeVerificationBodyEmailInput}>
                        <input
                          key={index}
                          type="text"
                          maxLength={1}
                          value={otp[index] || ""}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          onPaste={(e) => onPaste(e)}
                          ref={(ref) =>
                            (inputs.current[index] = ref as HTMLInputElement)
                          }
                          autoFocus={index === 0}
                        />
                      </div>
                    ))}
                </div>
              </div>
              {!validOTP && (
                <div
                  className={cn(
                    styles.invalidOTPMessage,
                    "d-flex justify-content-center"
                  )}
                >
                  Invalid Code. Please Enter Valid Code
                </div>
              )}
              {resendCodeMessageStatus && (
                <div
                  className={cn(
                    styles.invalidOTPMessage,
                    "d-flex justify-content-center"
                  )}
                >
                  Code has been sent to the Registered Email
                </div>
              )}
              <div
                className={cn(
                  styles.codeVerificationBodyBTNBody,
                  "d-flex justify-content-center"
                )}
              >
                <Button
                  disabled={otp.length != 6}
                  className={styles.codeVerificationBodyBTN}
                  onClick={onSubmitButtonHandler}
                >
                  SUBMIT
                </Button>
              </div>
              {(location.state.verifyType === "email" ||
                location.state.authMethod === "EMAIL") && (
                <div
                  className={cn(
                    styles.codeVerificationResendCode,
                    "d-flex justify-content-center"
                  )}
                  onClick={onResendCodeClick}
                >
                  <p>Resend Code</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <VerifiedAccount
        show={onSubmit}
        onHide={onCloseIconClick}
        onDoneButtonClick={onDoneButtonClick}
      />
    </div>
  );
};
export default CodeVerification;
