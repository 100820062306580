import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import style from "./SpeedoMeterChart.module.scss";
import bronzeIcon from "../../components/Icons/bronzeIcon.svg";
import silverIcon from "../../components/Icons/silverIcon.svg";
import goldIcon from "../../components/Icons/goldIcon.svg";

const SpeedoMeterChart = ({
  currentSpeed,
  totalSpeed,
  height,
  width,
  type,
}: any) => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 500);
  }, []);

  const data = {
    labels: ["Failed", "Running", "Succeeded"],
    datasets: [
      {
        data: [100, 100, 100, 100, 100],
        backgroundColor: [
          "#808080",
          "#0000FF",
          "#f80000",
          "#f7ba00",
          "#119f33",
        ],
        hoverBackgroundColor: [
          "#808080",
          "#0000FF",
          "#f80000",
          "#f7ba00",
          "#119f33",
        ],
        circumference: 180,
        rotation: 270,
      },
    ],
    options: {
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      animation: {
        duration: 1000,
      },
    },
  };

  const needleStyle: any = {
    position: "absolute",
    width: "0.313rem",
    height: "45%",
    backgroundColor: "black",
    top: "28%",
    left: "50%",
    transformOrigin: "bottom",
    transform: `rotate(${
      animation ? (currentSpeed / totalSpeed) * 180 - 90 : -90
    }deg) translate(-50%, 0)`,
    transition: "transform 1s ease-in-out",
  };

  return (
    <div style={{ position: "relative" }}>
      <Doughnut
        width={width}
        height={height}
        data={data}
        options={data.options}
        id="speedometer-chart"
      />
      <div style={needleStyle}></div>
      {type === "Job" && (
        <div className={style.textStyleJob}>Current Job Status</div>
      )}
      {type != "Job" && (
        <div className={style.textStyleJtg}>
          {type === "Bronze" ? (
            <div>
              <img alt="addPlusicon" src={bronzeIcon} width={32} />
              Bronze
            </div>
          ) : type === "Silver" ? (
            <div>
              <img alt="addPlusicon" src={silverIcon} width={32} />
              Silver
            </div>
          ) : (
            <div>
              <img alt="addPlusicon" src={goldIcon} width={32} />
              Gold
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SpeedoMeterChart;
