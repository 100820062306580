import React from 'react';
import { Table, Container, Row, Col } from 'react-bootstrap';
import './tableSkeleton.css';

interface TableSkeletonProps {
  columns: number;
  rows?: number;
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({ columns, rows = 5 }) => {
  return (
    <Container fluid style={{ width: "90vw", padding: 0 }}>
      <Row>
        <Col>
          <Table striped bordered hover>
            <tbody>
            {Array.from({ length: rows }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {Array.from({ length: columns }).map((_, colIndex) => (
                    <td key={colIndex} style={{ textAlign: "center" }}>
                      <div className="skeleton skeleton-cell"></div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default TableSkeleton;