import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./EmailVerification.module.scss";
import wadeIcon from "../../../components/Icons/wadeIcon.svg";
import { Button, Modal } from "react-bootstrap";
import Input from "../../../components/Input/Input";
import { cn } from "../../../helpers";
import { getEmailEnable2FAService } from "../../../services/emailEnable2FA.service";
import CodeVerificationDialog from "../CodeVerifiaction/CodeVerificationDialog";
import { setIsAuthorized } from "../../../redux/login/actions";
import VerifiedAccount from "../../../components/Login/Message/VerifiedAccount/VerifiedAccount";
import { readUsers } from "../../../services/readUsers.service";

export interface EmailVerificationProps {
  show: boolean;
  onEmailDialogHide: any;
  email: string;
  password: string;
  user_id: number;
}

const EmailVerificationDialog = (props: EmailVerificationProps) => {
  const { show, onEmailDialogHide, email, password, user_id } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailId, setEmail] = useState("");
  const [codeVerificationDialog, setCodeVerificationDialog] = useState(false);
  const [invalidMailId, setInvalidMailId] = useState(false);
  const [incorrectEmailInputChange, setIncorrectEmailInputChange] =
    useState(false);
  const [onSubmit, SetOnSubmit] = useState(false);

  const validateEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) {
      return true;
    } else {
      return false;
    }
  };

  const onInputChange = (e: any) => {
    if (e.target.name === "email" && invalidMailId) {
      setIncorrectEmailInputChange(true);
    }
    setEmail(e.target.value);
  };

  const onGetCodeButtonClickHandler = () => {
    setIncorrectEmailInputChange(false);
    if (validateEmail()) {
      getEmailEnable2FAService(email, password, user_id);
      onEmailDialogHide();
      setCodeVerificationDialog(true);
    } else {
      setInvalidMailId(true);
    }
  };

  const onDoneButtonClick = () => {
    readUsers(email, password).then((response) => {
      if (response.is_verified === false) {
        navigate("/");
        SetOnSubmit(false);
      } else {
        dispatch(setIsAuthorized(true));
        navigate("/onboard");
      }
    });
  };
  const onCloseIconClick = () => {
    SetOnSubmit(false);
    dispatch(setIsAuthorized(true));
    navigate("/onboard");
  };

  const handleSuccessSubmit = () => {
    setCodeVerificationDialog(false);
    SetOnSubmit(!onSubmit);
  };

  return (
    <div>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={onEmailDialogHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles.emailMain}
      >
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <div
              className={cn(
                styles.emailMainBody,
                "d-flex justify-content-center align-items-center"
              )}
            >
              <div>
                <span
                  className={cn(
                    styles.emailBodyHeadWade,
                    "d-flex justify-content-center"
                  )}
                >
                  <img src={wadeIcon} />
                </span>
                <span
                  className={cn(
                    styles.emailBodyHead,
                    "d-flex justify-content-center"
                  )}
                >
                  <p>Email code verification</p>
                </span>
                <div
                  className={cn(
                    styles.emailBodyOptionEmail,
                    "d-flex justify-content-center"
                  )}
                >
                  <div className={styles.emailBodyEmail}>
                    <Input
                      id={`${
                        incorrectEmailInputChange
                          ? `${styles.emailInput}`
                          : `${
                              invalidMailId
                                ? `${styles.emailWrongInput}`
                                : `${styles.emailInput}`
                            }`
                      }`}
                      labelId={styles.emailLabel}
                      name="email"
                      label="Email"
                      placeholder="Enter Email ID"
                      type="text"
                      ariaDescribedBy="Enter Password"
                      value={emailId}
                      onChange={(e: any) => onInputChange(e)}
                      maxlength={100}
                      isDisabled={false}
                    />
                    {invalidMailId && (
                      <div className={styles.invalidMessageDisplay}>
                        Invalid Email. Please try again.
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={cn(
                    styles.emailBodyBTNBody,
                    "d-flex justify-content-center"
                  )}
                >
                  <Button
                    disabled={emailId === ""}
                    className={styles.emailBodyBTN}
                    onClick={onGetCodeButtonClickHandler}
                  >
                    GET CODE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {codeVerificationDialog && (
        <CodeVerificationDialog
          show={codeVerificationDialog}
          handleSuccessSubmit={handleSuccessSubmit}
          email={email}
          password={password}
          user_id={user_id}
          verifyType="email"
          length={6}
        />
      )}
      {onSubmit && (
        <VerifiedAccount
          show={onSubmit}
          onHide={onCloseIconClick}
          onDoneButtonClick={onDoneButtonClick}
        />
      )}
    </div>
  );
};
export default EmailVerificationDialog;
