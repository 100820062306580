import React from "react";
import { Handle, Position, Node, NodeProps } from "reactflow";
import style from "./CustomNode.module.scss";
import Group2417 from "../../assets/images/svg/Group2417";
type NodeData = {
  label: string;
  status: boolean;
  isSelected: boolean;
  setShowJobTaskPage: any;
  showJobTaskPage: any;
  connected: boolean;
  setShowPlugInModal: any;
  setModalDependecyChange: any;
  setConnectedNodes: any;
};

type CustomNode = Node<NodeData>;

const CustomNode = ({ data }: NodeProps<NodeData>) => {
  const handleJobTaskGroupsLinkClick = () => {
    data.setShowJobTaskPage(true);
    data.setModalDependecyChange(false);
  };

  const handleJobTaskLinkClick = (e: any) => {
    e.stopPropagation();
    data.setShowPlugInModal(true);
    data.setConnectedNodes([]);
  };
  return (
    <>
      <div
        className={`${style.nodeContainer} ${
          data.isSelected ? style.nodeStyleSelected : style.nodeStyle
        }`}
      >
        <Group2417 height="144" width="144" />
        <div className={style.label}>
          {data.label.length > 18
            ? `${data.label.slice(0, 18)}...`
            : data.label}
        </div>
        {data.isSelected}
        <Handle type="target" position={Position.Left} />
        <div className={style.endpointWrapper}>
          <Handle type="source" position={Position.Right} />
        </div>
        {data.isSelected && !data.showJobTaskPage && (
          <div className={style.link} onClick={handleJobTaskGroupsLinkClick}>
            View Data Model Details
          </div>
        )}
        {data.isSelected && data.showJobTaskPage && (
          <div
            className={style.link}
            onClick={(e) => {
              handleJobTaskLinkClick(e);
            }}
          >
            View Job Task
          </div>
        )}
      </div>
    </>
  );
};

export default CustomNode;
