// Interfaces
// import { ISystemError } from '../../interfaces/systemerror.interfaces';
import { IErrorCodeOptions } from './interfaces';

export const getHeaders = (options?: object, isFormData = false): Record<string, string> => {
    const headers: any = options ? { ...options } : {};

    if (!isFormData) {
        // default values 
        headers['Content-Type'] = headers['Content-Type'] || 'application/json';
        headers['Accept'] = 'application/json';
    }

    return headers;
};

export const isResponseStatusHandled = (response: Response, errorCodeOptions?: IErrorCodeOptions): boolean =>
    errorCodeOptions?.errorCodesHandled?.includes(response.status);

export const redirectToSystemError = (response: Response, errorCodeNotFound: number): void => {
    //redirect for system error logic goes here
};