import React from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "./SetUpEnvironmentCard.module.scss";
import Group536 from "../../assets/images/svg/Group 536.svg";
import { useNavigate } from "react-router-dom";
import { cn } from "../../helpers";
import Group264 from "../../assets/images/svg/Group 264.svg";
interface OTPInputProps {
  isLoggedInHandler: () => void;
}
const SetUpEnvironmentCard: React.FC<OTPInputProps> = ({
  isLoggedInHandler,
}) => {
  const navigate = useNavigate();
  const onSkipButtonClick = () => {
    localStorage.setItem("isLoggedIn", "true");
    isLoggedInHandler();
    navigate("/home");
  };

  return (
    <div className={styles.onboardContainer}>
      <div className={cn(styles.setupEnvSkipButton, "text-end")}>
        <span onClick={onSkipButtonClick}>Skip</span>
      </div>

      <Carousel
        nextIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
          >
            <path
              id="arrow-right-circle-fill"
              d="M30,0A30,30,0,1,1,0,30,30,30,0,0,1,30,0ZM16.875,28.125a1.875,1.875,0,0,0,0,3.75H38.6l-8.051,8.047A1.877,1.877,0,1,0,33.2,42.577l11.25-11.25a1.875,1.875,0,0,0,0-2.655L33.2,17.422a1.877,1.877,0,0,0-2.655,2.655L38.6,28.125Z"
              fill="#03234a"
            />
          </svg>
        }
        prevIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
          >
            <path
              id="arrow-right-circle-fill"
              d="M30,0A30,30,0,1,0,60,30,30,30,0,0,0,30,0ZM43.125,28.125a1.875,1.875,0,0,1,0,3.75H21.4l8.051,8.047A1.877,1.877,0,1,1,26.8,42.577l-11.25-11.25a1.875,1.875,0,0,1,0-2.655L26.8,17.422a1.877,1.877,0,0,1,2.655,2.655L21.4,28.125Z"
              fill="#03234a"
            />
          </svg>
        }
        className="setup-env-carousel"
      >
        <Carousel.Item interval={null}>
          <div
            className={cn(
              styles.onBoardContent,
              "d-flex justify-content-center align-items-center"
            )}
          >
            <div className={styles.setUpEnvData}>
              <div className={styles.setupEnvCarouselItemWelcomeCard}>
                <img src={Group536} />

                <div
                  className={cn(
                    styles.welcomeMessageHead,
                    "d-flex justify-content-center align-items-center m-0"
                  )}
                >
                  Hello!
                </div>
                <div
                  className={cn(
                    styles.welcomeMessageContent,
                    "d-flex justify-content-center align-items-center m-0 p-0"
                  )}
                >
                  Welcome to WADE Portal
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={null}>
          <div
            className={cn(
              styles.onBoardContent,
              "d-flex justify-content-center align-items-center"
            )}
          >
            <div className={cn(styles.bgWadeWhite, "row")}>
              <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                <img src={Group264} style={{ width: "100%" }} />
              </div>
              <div className={cn(styles.setupEnvRightPanel, "col-6")}>
                <div className={styles.setupEnvRightPanelHeader}>
                  Setup Environment
                </div>
                <div className={styles.setUpRightData}>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>1</div>
                    </div>
                    <div className={styles.setupEnvRightPanelText}>
                      <div>Add Environment via Azure Marketplace.</div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>2</div>
                    </div>
                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        Choose menu option via Nav bar Environment/Add
                        Environment (You will now be directed to Azure
                        Marketplace).
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>3</div>
                    </div>
                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        Add Azure tenant information, subscription name and ID
                        for the new WADE environment.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>4</div>
                    </div>
                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        When information about Tenant/subscription has been
                        added to marketplace you need to login into the portal
                        and configure the environment (select subscription name)
                        under the Environment navigation bar.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>5</div>
                    </div>
                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        In the Environment Details screen you need to configure
                        DW/Data Lakehouse target, if it’s a on-premises target
                        and environment name, type and description.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>6</div>
                    </div>
                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        When the environment is configured then you can then
                        start adding data sources and endpoints.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item interval={null}>
          <div
            className={cn(
              styles.onBoardContent,
              "d-flex justify-content-center align-items-center"
            )}
          >
            <div className={cn(styles.bgWadeWhite, "row")}>
              <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                <img src={Group264} style={{ width: "100%" }} />
              </div>
              <div className={cn(styles.setupEnvRightPanel, "col-6")}>
                <div className={styles.setupEnvRightPanelHeader}>
                  Add Data Sources & End Points to the Environment
                </div>
                <div className={styles.setUpRightData}>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>1</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        Select menu option Orchestration/Data Source management
                        in the Navigation bar
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>2</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        In the screen Data Source Management, you press the (+)
                        sign to add a new Data Source, then the Data Source
                        Details side screen appears
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>3</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        In the Data Source Detail side screen, you can
                        information about data source name, description, type
                        etc. If the data source should ingested via WADE then
                        select ‘Skip ingestion’
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>4</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        Depending on Data source type (for example REST,
                        Database) detailed fields will appear to fill in.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>5</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        For each Data source you can add one or multiple
                        endpoints by selecting Data Source and in the Endpoints
                        section press the (+) button to add a single endpoint
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>6</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        In the Endpoint Details you can add information such as
                        endpoint name, description, data format, column
                        delimiter, transform path, natural keys etc the fields
                        to fill in depends on type of data source.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={null}>
          <div
            className={cn(
              styles.onBoardContent,
              "d-flex justify-content-center align-items-center"
            )}
          >
            <div className={cn(styles.bgWadeWhite, "row")}>
              <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                <img src={Group264} style={{ width: "100%" }} />
              </div>

              <div className={cn(styles.setupEnvRightPanel, "col-6")}>
                <div className={styles.setupEnvRightPanelHeader}>
                  Orchestration - Job management
                </div>
                <div className={styles.setUpRightData}>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>1</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        Select menu option – Orchestration/Job Management.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>2</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        Press the (+) sign to add a new Job (ETL-job) then a Job
                        detailed screen will appear. Add information such as Job
                        name/description and set status (default Active), you
                        can also add scheduling information when the job should
                        run.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>3</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        Add Job Task Group to job and select for which layer
                        (bronze, silver or gold) (based on Lakehouse medallion
                        architecture)
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>4</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        If the Job task group is for Bronze layer you will
                        select Data Sources, endpoints and custom plugin that
                        you want to add to that Job task group. When saved you
                        could then also active/inactive the job task group, data
                        source or endpoints, custom plugin.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>5</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        If the Job task group is for Silver layer you will
                        select Data Vault Subject areas, ensembles, data vault
                        entities and custom plugin that you want to add to that
                        Job task group. When saved you could then also
                        active/inactivate the job task group, subject area,
                        ensemble or data vault entity and custom plugin.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>6</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        If the Job task group is for Gold layer you will select
                        a Data Mart and included dimensions and fact tables, you
                        can also add a custom plugin. When saved you could then
                        also active/inactive the job task group, data mart,
                        dimension, fact table, custom plugin.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={null}>
          <div
            className={cn(
              styles.onBoardContent,
              "d-flex justify-content-center align-items-center"
            )}
          >
            <div className={cn(styles.bgWadeWhite, "row")}>
              <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                <img src={Group264} style={{ width: "100%" }} />
              </div>
              <div className={cn(styles.setupEnvRightPanel, "col-6")}>
                <div className={styles.setupEnvRightPanelHeader}>
                  Orchestration - Job dependency management
                </div>
                <div className={styles.setUpRightData}>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>1</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        Select the menu option Orchestration/Job dependency
                        management.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>2</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        In the screen that appears you can draw dependencies
                        between different Job, you can set upstream/downstream
                        dependencies and also decide if the job should continue
                        on failure or not.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>3</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        For each job you can view details about included job
                        task groups and set dependencies between those job task
                        groups
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>4</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        For the job task group you can set dependency between
                        job task groups within the layer (for example within
                        bronze) or between a job task groups between two layer
                        for example between bronze and silver job task groups,
                        how ever you cannot create a dependency between bronze
                        and gold.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>5</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        For each job and job task group you can also active and
                        inactive job and job task groups.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={null}>
          <div
            className={cn(
              styles.onBoardContent,
              "d-flex justify-content-center align-items-center"
            )}
          >
            <div className={cn(styles.bgWadeWhite, "row")}>
              <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                <img src={Group264} style={{ width: "100%" }} />
              </div>
              <div className={cn(styles.setupEnvRightPanel, "col-6")}>
                <div className={styles.setupEnvRightPanelHeader}>
                  Custom plugin
                </div>
                <div className={styles.setUpRightData}>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>1</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        Select menu option Orchestration/Custom plugin
                        management.
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>2</div>
                    </div>
                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        A list with available custom plugin will appear, for
                        each existing plugin you can edit, delete,
                        active/inactive
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>3</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        To add a new custom plugin you can press the (+) sign
                        and a Custom plugin detail screen will appear, add
                        information such as plugin name, description, notebook
                        path etc
                      </div>
                    </div>
                  </div>
                  <div className={styles.setupEnvRightPanelList}>
                    <div>
                      <div className={styles.setupEnvRightPanelNum}>4</div>
                    </div>

                    <div className={styles.setupEnvRightPanelText}>
                      <div>
                        Default the new custom plugin will be activated and can
                        be assigned to job and job task groups for execution. If
                        the plugin is inactivated it will inactive all instances
                        of this in all job/job task groups
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
export default SetUpEnvironmentCard;
