import React from "react";
import { Spinner } from "react-bootstrap";
import styles from "./Spinner.module.scss";

interface CustomLoaderProps {
  variant: "blue" | "red";
}

const CustomLoader: React.FC<CustomLoaderProps> = ({ variant }) => {
  const customColor: React.CSSProperties = {
    color: variant === "blue" ? "#03234a" : "red",
  };

  return (
    <div className={styles.loader}>
      <Spinner animation="border" role="status" style={customColor}>
        <span className="sr-only"></span>
      </Spinner>
    </div>
  );
};

export default CustomLoader;
