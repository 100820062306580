import * as api from "./middleware/api.middleware";

const serviceUrl = "audit_history";
const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

const getEnvironmentId = () => {
  return localStorage.getItem("environment_id");
};

export function getAuditHistoryList() {
  const getResponse = api.get<any[]>(
    `environments/${getEnvironmentId()}/${serviceUrl}`,
    getAccessToken()
  );
  return getResponse;
}
