import React from "react";
import style from "./NotFound.module.scss";

const NotFound = () => {
  return (
    <div className={style.notFoundMainContainer}>
      <div>
        <h1>404 - Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default NotFound;
