import * as api from "./middleware/api.middleware";

const serviceUrl = "environments";

const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

const getEnvironmentId = () => {
  return localStorage.getItem("environment_id");
};

export function getRdvDataQuality() {
  const getResponse = api.get<any[]>(
    `${serviceUrl}/${getEnvironmentId()}/data_quality/raw_data_vault`,
    getAccessToken()
  );
  return getResponse;
}

export function getDmDataQuality() {
  const getResponse = api.get<any[]>(
    `${serviceUrl}/${getEnvironmentId()}/data_quality/data_mart`,
    getAccessToken()
  );
  return getResponse;
}

export function getRawDataVaultHistory() {
  const getResponse = api.get<any[]>(
    `${serviceUrl}/${getEnvironmentId()}/data_quality/raw_data_vault/history`,
    getAccessToken()
  );
  return getResponse;
}

export function getDataMartHistory() {
  const getResponse = api.get<any[]>(
    `${serviceUrl}/${getEnvironmentId()}/data_quality/data_mart/history`,
    getAccessToken()
  );
  return getResponse;
}
