import React from "react";
interface PlusCircle extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
const DarkPlusIconCircle = (props: PlusCircle) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g id="Group_604" data-name="Group 604" transform="translate(-330 -269)">
        <rect
          id="Rectangle_85"
          data-name="Rectangle 85"
          width="32"
          height="32"
          rx="16"
          transform="translate(330 269)"
          fill="#03234a"
        />
        <g id="plus-circle" transform="translate(338 277)">
          <path
            id="Path_214"
            data-name="Path 214"
            d="M8,15a7,7,0,1,1,7-7,7,7,0,0,1-7,7Zm0,1A8,8,0,1,0,0,8,8,8,0,0,0,8,16Z"
            fill="#fff"
          />
          <path
            id="Path_215"
            data-name="Path 215"
            d="M8,4a.5.5,0,0,1,.5.5v3h3a.5.5,0,0,1,0,1h-3v3a.5.5,0,0,1-1,0v-3h-3a.5.5,0,0,1,0-1h3v-3A.5.5,0,0,1,8,4Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
export default DarkPlusIconCircle;
