import React, { useEffect, useState } from "react";
import style from "./JobMonitor.module.scss";
import { Accordion, Card, Form } from "react-bootstrap";
import LineChart from "./LineChart";
import refreshIcon from "../../components/Icons/refresh.svg";
import CustomLoader from "../../components/Loader/Spinner";
import NewGanttChart from "./NewGanttChart";

const JobMonitor = ({
  chartData,
  jobMonitorData,
  isLoading,
  setIsLoading,
  fetchJobMonitorDetails,
  jobExecutionStatus,
  jobExecutionHistory,
  jobExecutionHistoryChartData,
  setJobMonitorTab,
  setJobDurationStartDate,
  setJobDurationEndDate,
  jobDurationStartDate,
  jobDurationEndDate,
  setJobExecutionStartDate,
  setJobExecutionEndDate,
  jobExecutionStartDate,
  jobExecutionEndDate,
}: any) => {
  const [filterData, setFilterData] = useState([]);
  const [jobExecutionFilterData, setJobExecutionFilterData] = useState([]);
  const [filterOptions, setFilterOptions] = useState<any>({});
  const [jobExecutionFilterOptions, setJobExecutionFilterOptions] =
    useState<any>({});
  const [jobExecutionPage, setJobExecutionPage] = useState<boolean>(true);
  const [
    filteredJobDurationHistoryChartData,
    setFilteredJobDurationHistoryChartData,
  ] = useState(jobExecutionHistoryChartData);
  const [
    filteredJobExecutionHistoryChartData,
    setFilteredJobExecutionHistoryChartData,
  ] = useState(jobExecutionStatus);

  useEffect(() => {
    const uniqueRecords = jobExecutionHistory.filter(
      (item: any, index: any, array: any) => {
        return !array
          .slice(0, index)
          .some((otherItem: any) => otherItem.job_name === item.job_name);
      }
    );

    const selectedFilter = filterData[0]?.options
      ?.filter((value: any) => value.selected === true)
      .map((value: any) => value.name);

    setFilterData([
      {
        id: 1,
        filterName: "Job Name",
        options: uniqueRecords.map((item: any, index: any) => ({
          id: index,
          name: item.job_name,
          selected: selectedFilter?.includes(item.job_name) ? true : false,
        })),
      },
    ]);
  }, [jobExecutionHistory]);

  useEffect(() => {
    const allJobs: any = [];
    const allJobTaskGroups: any = [];
    const allJobTasks: any = [];

    jobExecutionStatus.forEach((jobDetail: any) => {
      allJobs.push({ job_name: jobDetail.job_name });

      jobDetail.job_task_groups.forEach((jobTaskGroup: any) => {
        allJobTaskGroups.push({
          job_task_group_name: jobTaskGroup.job_task_group_name,
        });

        jobTaskGroup.job_tasks.forEach((jobTask: any) => {
          allJobTasks.push({ job_task_name: jobTask.job_task_name });
        });
      });
    });

    const uniqueJobSet: any = new Set(
      allJobs.map((item: any) => item.job_name)
    );
    const uniqueJobNames = [...uniqueJobSet];
    const uniqueJobs = uniqueJobNames.map((jobName) => ({
      job_name: jobName,
    }));

    const uniqueJTtgSet: any = new Set(
      allJobTaskGroups.map((item: any) => item.job_task_group_name)
    );
    const uniqueJtgNames = [...uniqueJTtgSet];
    const uniqueJtg = uniqueJtgNames.map((jobName) => ({
      job_task_group_name: jobName,
    }));

    const uniqueJtSet: any = new Set(
      allJobTasks.map((item: any) => item.job_task_name)
    );
    const uniqueJtNames = [...uniqueJtSet];
    const uniqueJt = uniqueJtNames.map((jobName) => ({
      job_task_name: jobName,
    }));

    const selectedJobs = jobExecutionFilterData[0]?.options
      ?.filter((value: any) => value.selected === true)
      .map((value: any) => value.name);

    const selectedStatus = jobExecutionFilterData[1]?.options
      ?.filter((value: any) => value.selected === true)
      .map((value: any) => value.name);

    const selectedJtg = jobExecutionFilterData[2]?.options
      ?.filter((value: any) => value.selected === true)
      .map((value: any) => value.name);

    const selectedJt = jobExecutionFilterData[3]?.options
      ?.filter((value: any) => value.selected === true)
      .map((value: any) => value.name);

    setJobExecutionFilterData([
      {
        id: 1,
        filterName: "Job Name",
        options: uniqueJobs.map((item: any, index: any) => ({
          id: index,
          name: item.job_name,
          selected: selectedJobs?.includes(item.job_name) ? true : false,
        })),
      },
      {
        id: 2,
        filterName: "Job Status",
        options: ["Queued", "Initiated", "Failed", "Running", "Succeeded"].map(
          (item: any, index: any) => ({
            id: index,
            name: item,
            selected: selectedStatus?.includes(item) ? true : false,
          })
        ),
      },
      {
        id: 3,
        filterName: "Job Task Group Name",
        options: uniqueJtg.map((item: any, index: any) => ({
          id: index,
          name: item.job_task_group_name,
          selected: selectedJtg?.includes(item.job_task_group_name)
            ? true
            : false,
        })),
      },
      {
        id: 4,
        filterName: "Job Task Name",
        options: uniqueJt.map((item: any, index: any) => ({
          id: index,
          name: item.job_task_name,
          selected: selectedJt?.includes(item.job_task_name) ? true : false,
        })),
      },
    ]);
  }, [jobExecutionStatus]);

  useEffect(() => {
    const filtered = jobExecutionHistoryChartData?.datasets?.filter(
      (item: any) =>
        Object.keys(filterOptions)?.every((property) => {
          if (filterOptions[property].size === 0) return true;
          return filterOptions[property].has(item[property]);
        })
    );

    setFilteredJobDurationHistoryChartData({
      datasets: filtered,
      labels: jobExecutionHistoryChartData.labels,
    });
  }, [filterOptions, jobExecutionHistoryChartData]);

  useEffect(() => {
    const filtered = jobExecutionStatus?.filter((item: any) =>
      Object.keys(jobExecutionFilterOptions)?.every((property) => {
        if (jobExecutionFilterOptions[property].size === 0) {
          return true;
        } else {
          if (property === "job_name" || property === "job_status") {
            return jobExecutionFilterOptions[property].has(item[property]);
          } else if (property === "job_task_group_name") {
            return item.job_task_groups.some((group: any) =>
              jobExecutionFilterOptions[property].has(group[property])
            );
          } else if (property === "job_task_name") {
            return item.job_task_groups.some((group: any) =>
              group.job_tasks.some((task: any) =>
                jobExecutionFilterOptions[property].has(task[property])
              )
            );
          } else {
            return false;
          }
        }
      })
    );
    setFilteredJobExecutionHistoryChartData(filtered);
  }, [jobExecutionFilterOptions, jobExecutionStatus]);

  const handleJobMonitorRefresh = () => {
    setIsLoading(true);
    fetchJobMonitorDetails();
  };

  const handleFilterItemSelect = (
    filterId: number,
    itemId: number,
    filterName: string,
    itemName: string,
    checked: boolean
  ) => {
    if (filterName === "Job Name") {
      filterName = "label";
    }
    const updatedFilteredData = filterData.map((value: any) => {
      if (filterId === value.id) {
        const newOptions = value.options.map((item: any) => {
          if (item.id === itemId) {
            return { ...item, selected: checked };
          } else {
            return item;
          }
        });
        return { ...value, options: newOptions };
      } else {
        return value;
      }
    });
    setFilterData(updatedFilteredData);

    const newOptions: any = new Set(filterOptions[filterName]);
    if (newOptions.has(itemName)) {
      newOptions.delete(itemName);
    } else {
      newOptions.add(itemName);
    }

    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      [filterName]: newOptions,
    }));
  };

  const handleFilterSelect = (
    filterId: number,
    filterName: string,
    checked: boolean
  ) => {
    if (filterName === "Job Name") {
      filterName = "label";
    }
    const updatedFilteredData = filterData.map((value: any) => {
      if (filterId === value.id) {
        const newOptions = value.options.map((item: any) => {
          return { ...item, selected: checked };
        });
        return { ...value, options: newOptions };
      } else {
        return value;
      }
    });
    setFilterData(updatedFilteredData);
    const newOptions: any = new Set(filterOptions[filterName]);

    filterData.forEach((value: any) => {
      if (filterId === value.id) {
        value.options.forEach((item: any) => {
          checked && newOptions.add(item.name);
          !checked && newOptions.delete(item.name);
        });
      }
    });

    setFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      [filterName]: newOptions,
    }));
  };

  const handleJobExecutionFilterItemSelect = (
    filterId: number,
    itemId: number,
    filterName: string,
    itemName: string,
    checked: boolean
  ) => {
    switch (filterName) {
      case "Job Name":
        filterName = "job_name";
        break;
      case "Job Task Group Name":
        filterName = "job_task_group_name";
        break;
      case "Job Task Name":
        filterName = "job_task_name";
        break;
      case "Job Status":
        filterName = "job_status";
        break;
    }
    const updatedFilteredData = jobExecutionFilterData.map((value: any) => {
      if (filterId === value.id) {
        const newOptions = value.options.map((item: any) => {
          if (item.id === itemId) {
            return { ...item, selected: checked };
          } else {
            return item;
          }
        });
        return { ...value, options: newOptions };
      } else {
        return value;
      }
    });
    setJobExecutionFilterData(updatedFilteredData);

    const newOptions: any = new Set(jobExecutionFilterOptions[filterName]);
    if (newOptions.has(itemName)) {
      newOptions.delete(itemName);
    } else {
      newOptions.add(itemName);
    }

    setJobExecutionFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      [filterName]: newOptions,
    }));
  };

  const handleJobExecutionFilterSelect = (
    filterId: number,
    filterName: string,
    checked: boolean
  ) => {
    switch (filterName) {
      case "Job Name":
        filterName = "job_name";
        break;
      case "Job Task Group Name":
        filterName = "job_task_group_name";
        break;
      case "Job Task Name":
        filterName = "job_task_name";
        break;
      case "Job Status":
        filterName = "job_status";
        break;
    }
    const updatedFilteredData = jobExecutionFilterData.map((value: any) => {
      if (filterId === value.id) {
        const newOptions = value.options.map((item: any) => {
          return { ...item, selected: checked };
        });
        return { ...value, options: newOptions };
      } else {
        return value;
      }
    });
    setJobExecutionFilterData(updatedFilteredData);
    const newOptions: any = new Set(jobExecutionFilterOptions[filterName]);

    jobExecutionFilterData.forEach((value: any) => {
      if (filterId === value.id) {
        value.options.forEach((item: any) => {
          checked && newOptions.add(item.name);
          !checked && newOptions.delete(item.name);
        });
      }
    });

    setJobExecutionFilterOptions((prevOptions: any) => ({
      ...prevOptions,
      [filterName]: newOptions,
    }));
  };

  const clearDurationFilter = () => {
    const updatedFilterData = filterData.map((item: any) => ({
      ...item,
      options: item.options.map((option: any) => ({
        ...option,
        selected: false,
      })),
    }));
    setFilterData(updatedFilterData);
    setFilterOptions({});
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    setJobDurationStartDate(thirtyDaysAgo.toISOString().split("T")[0]);
    setJobDurationEndDate(currentDate.toISOString().split("T")[0]);
  };

  const clearExecutionFilter = () => {
    const updatedFilterData = jobExecutionFilterData.map((item: any) => ({
      ...item,
      options: item.options.map((option: any) => ({
        ...option,
        selected: false,
      })),
    }));
    setJobExecutionFilterData(updatedFilterData);
    setJobExecutionFilterOptions({});
    const currentDate = new Date();
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(currentDate.getDate() - 3);
    setJobExecutionStartDate(threeDaysAgo.toISOString().split("T")[0]);
    setJobExecutionEndDate(currentDate.toISOString().split("T")[0]);
  };

  const handleJobDurationDateFilter = (e: any, type: string) => {
    if (type === "start") {
      setJobDurationStartDate(e.target.value);
      const currentDate = e.target.value;
      const ninetyDaysFromNow = new Date(currentDate);
      ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() + 90);
      const sevenDaysAgoDate = ninetyDaysFromNow.toISOString().split("T")[0];

      if (
        jobDurationEndDate > sevenDaysAgoDate ||
        jobDurationEndDate < currentDate
      ) {
        setJobDurationEndDate(sevenDaysAgoDate);
      }
    } else {
      setJobDurationEndDate(e.target.value);
      const currentDate = e.target.value;
      const ninetyDaysAgo = new Date(currentDate);
      ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
      const sevenDaysBeforeDate = ninetyDaysAgo.toISOString().split("T")[0];

      if (
        sevenDaysBeforeDate > jobDurationStartDate ||
        jobDurationStartDate > currentDate
      ) {
        setJobDurationStartDate(sevenDaysBeforeDate);
      }
    }
  };

  const handleJobExecutionDateFilter = (e: any, type: string) => {
    if (type === "start") {
      setJobExecutionStartDate(e.target.value);
      const currentDate = e.target.value;
      const sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() + 7);
      const sevenDaysAgoDate = sevenDaysAgo.toISOString().split("T")[0];

      if (
        jobExecutionEndDate > sevenDaysAgoDate ||
        jobExecutionEndDate < currentDate
      ) {
        setJobExecutionEndDate(sevenDaysAgoDate);
      }
    } else {
      setJobExecutionEndDate(e.target.value);
      const currentDate = e.target.value;
      const sevenDaysBefore = new Date(currentDate);
      sevenDaysBefore.setDate(sevenDaysBefore.getDate() - 7);
      const sevenDaysBeforeDate = sevenDaysBefore.toISOString().split("T")[0];

      if (
        sevenDaysBeforeDate > jobExecutionStartDate ||
        jobExecutionStartDate > currentDate
      ) {
        setJobExecutionStartDate(sevenDaysBeforeDate);
      }
    }
  };

  return (
    <>
      <div className={style.jobMonitorContainer}>
        <div className={style.dataQualityContainerHeader}>
          <div
            className={`${style.jobDetailsContainerHeader} ${
              jobExecutionPage === true ? style.selectedJobContainer : ""
            }`}
            onClick={() => {
              setJobExecutionPage(true);
              setJobExecutionFilterOptions({});
            }}
          >
            Job Execution
          </div>
          <div
            className={`${style.jobTaskContainerHeader} ${
              jobExecutionPage === false ? style.selectedJobContainer : ""
            }`}
            onClick={() => {
              setJobExecutionPage(false);
              setFilterOptions({});
            }}
          >
            Job Duration
          </div>
          <div className={style.backButton}>
            <span
              onClick={() => setJobMonitorTab(false)}
              className={style.dashboardLink}
            >
              Switch to Dashboard View
            </span>
          </div>
        </div>
        {jobExecutionPage ? (
          <div className={style.jobExecutionContainer}>
            <div className={style.jobMonitorGraphContainer}>
              <div className={style.jobMonitorGraphContainerHeader}>
                <div className={style.orchestrationHeaderHeading}>
                  Job Execution Status
                </div>
                <img
                  alt="refresh_icon"
                  src={refreshIcon}
                  width={30}
                  className={style.refreshIcon}
                  onClick={handleJobMonitorRefresh}
                ></img>
              </div>
              <div className={style.jobMonitorGraphContainerStatus}>
                <div className={style.jobMonitorStatusItem}>
                  <div className={style.jobMonitorStatusColorGrey}></div>
                  Queued
                </div>
                <div className={style.jobMonitorStatusItem}>
                  <div className={style.jobMonitorStatusColorBlue}></div>
                  Initiated
                </div>
                <div className={style.jobMonitorStatusItem}>
                  <div className={style.jobMonitorStatusColorRed}></div>
                  Failed
                </div>
                <div className={style.jobMonitorStatusItem}>
                  <div className={style.jobMonitorStatusColorYellow}></div>
                  Running
                </div>
                <div className={style.jobMonitorStatusItem}>
                  <div className={style.jobMonitorStatusColorGreen}></div>
                  Succeeded
                </div>
              </div>
              <div className={style.jobMonitorGraphContainerContent}>
                {isLoading && <CustomLoader variant="blue" />}
                {!isLoading && (
                  <div className={style.chartArea}>
                    {filteredJobExecutionHistoryChartData.length > 0 ? (
                      <NewGanttChart
                        data={filteredJobExecutionHistoryChartData}
                      />
                    ) : (
                      <div className={style.noData}>No Record Found</div>
                    )}
                  </div>
                )}
              </div>
              <div className={style.jobMonitorGraphContainerFooter}>
                <div>
                  * <b>JTG</b> : Job Task Group
                </div>
                <div className={style.jobTask}>
                  * <b>JT</b> : Job Task
                </div>
              </div>
            </div>
            <div className={style.jobExecutionFilterContainer}>
              <div className={style.jobMonitorFilterContainerHeader}>
                Job Execution Filter
              </div>

              <div className={style.jobMonitorFilterContainerContent}>
                <div className={style.clearFilter}>
                  <div
                    className={style.filterText}
                    onClick={clearExecutionFilter}
                  >
                    Clear Filter
                  </div>
                </div>
                <div className={style.dateMessage}>
                  Maximum days in interval - 7 Days
                </div>
                <div className={style.dateContainer}>
                  <div className={style.dataPickerTab}>
                    <Form.Label className={style.formLabel}>
                      Start Date :
                    </Form.Label>
                    <Form.Control
                      type="date"
                      className={style.datePicker}
                      name="start_date"
                      value={jobExecutionStartDate || ""}
                      onChange={(e) => {
                        handleJobExecutionDateFilter(e, "start");
                      }}
                    />
                  </div>

                  <div className={style.dataPickerTab}>
                    <Form.Label className={style.formLabel}>
                      End Date :{" "}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      className={style.datePicker}
                      name="start_date"
                      value={jobExecutionEndDate || ""}
                      onChange={(e) => {
                        handleJobExecutionDateFilter(e, "end");
                      }}
                    />
                  </div>
                </div>
                <div className={style.accordionContainer}>
                  <div className={style.accordion}>
                    {jobExecutionFilterData.map((item: any) => (
                      <div className={style.customAccordionContainer}>
                        <Accordion className={style.customAccordion}>
                          <Card className={style.customCard}>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header
                                className={style.accordionItemHeader}
                              >
                                <label className={style.checkboxContainer}>
                                  <input
                                    type="checkbox"
                                    name="selectedOption"
                                    checked={item.options?.every(
                                      (option: any) => option.selected
                                    )}
                                    onChange={(e) => {
                                      handleJobExecutionFilterSelect(
                                        item.id,
                                        item.filterName,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <span className={style.checkmark}></span>
                                  <span className={style.checkmarkLable}>
                                    {item.filterName}
                                  </span>
                                </label>
                              </Accordion.Header>

                              <Accordion.Body className={style.accordionBody}>
                                {item.options?.map((option: any) => (
                                  <div
                                    className={
                                      style.accordionBodyElementsContainer
                                    }
                                  >
                                    <label className={style.checkboxContainer}>
                                      <input
                                        key={option.id}
                                        type="checkbox"
                                        name="selectedOption"
                                        checked={option.selected}
                                        onChange={(e) => {
                                          handleJobExecutionFilterItemSelect(
                                            item.id,
                                            option.id,
                                            item.filterName,
                                            option.name,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                      <span className={style.checkmark}></span>
                                      <span className={style.checkmarkLable}>
                                        {option.name}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Card>
                        </Accordion>
                        <div className={style.accordionLine}></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={style.jobMonitorRightBox}>
            <div className={style.orchestrationLineChartContainer}>
              <div className={style.jobMonitorGraphContainerHeader}>
                <div className={style.orchestrationHeaderHeading}>
                  Job Duration Status
                </div>
                <img
                  alt="refresh_icon"
                  src={refreshIcon}
                  width={30}
                  className={style.refreshIcon}
                  onClick={handleJobMonitorRefresh}
                ></img>
              </div>
              {filteredJobDurationHistoryChartData?.datasets?.length > 0 ? (
                <div className={style.orchestrationLineChart}>
                  <LineChart
                    data={filteredJobDurationHistoryChartData}
                    type="dataMonitor"
                  />
                </div>
              ) : (
                <div className={style.noData}>No Record Found</div>
              )}
            </div>
            <div className={style.jobMonitorFilterContainer}>
              <div className={style.jobMonitorFilterContainerHeader}>
                Job Duration Filter
              </div>

              <div className={style.jobMonitorFilterContainerContent}>
                <div className={style.clearFilter}>
                  <div
                    className={style.filterText}
                    onClick={clearDurationFilter}
                  >
                    Clear Filter
                  </div>
                </div>
                <div className={style.dateMessage}>
                  Maximum days in interval - 90 Days
                </div>
                <div className={style.dateContainer}>
                  <div className={style.dataPickerTab}>
                    <Form.Label className={style.formLabel}>
                      Start Date :
                    </Form.Label>
                    <Form.Control
                      type="date"
                      className={style.datePicker}
                      name="start_date"
                      value={jobDurationStartDate || ""}
                      onChange={(e) => {
                        handleJobDurationDateFilter(e, "start");
                      }}
                    />
                  </div>

                  <div className={style.dataPickerTab}>
                    <Form.Label className={style.formLabel}>
                      End Date :{" "}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      className={style.datePicker}
                      name="start_date"
                      value={jobDurationEndDate || ""}
                      onChange={(e) => {
                        handleJobDurationDateFilter(e, "end");
                      }}
                    />
                  </div>
                </div>
                <div className={style.accordionContainer}>
                  <div className={style.accordion}>
                    {filterData.map((item: any) => (
                      <div className={style.customAccordionContainer}>
                        <Accordion className={style.customAccordion}>
                          <Card className={style.customCard}>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header
                                className={style.accordionItemHeader}
                              >
                                <label className={style.checkboxContainer}>
                                  <input
                                    type="checkbox"
                                    name="selectedOption"
                                    checked={item.options?.every(
                                      (option: any) => option.selected
                                    )}
                                    onChange={(e) => {
                                      handleFilterSelect(
                                        item.id,
                                        item.filterName,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <span className={style.checkmark}></span>
                                  <span className={style.checkmarkLable}>
                                    {item.filterName}
                                  </span>
                                </label>
                              </Accordion.Header>

                              <Accordion.Body className={style.accordionBody}>
                                {item.options?.map((option: any) => (
                                  <div
                                    className={
                                      style.accordionBodyElementsContainer
                                    }
                                  >
                                    <label className={style.checkboxContainer}>
                                      <input
                                        key={option.id}
                                        type="checkbox"
                                        name="selectedOption"
                                        checked={option.selected}
                                        onChange={(e) => {
                                          handleFilterItemSelect(
                                            item.id,
                                            option.id,
                                            item.filterName,
                                            option.name,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                      <span className={style.checkmark}></span>
                                      <span className={style.checkmarkLable}>
                                        {option.name}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Card>
                        </Accordion>
                        <div className={style.accordionLine}></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default JobMonitor;
