import * as api from "./middleware/api.middleware";

const serviceUrl = "logout";
const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export function logout() {
  const getResponse = api.post<any>(`${serviceUrl}`, getAccessToken());
  return getResponse;
}
