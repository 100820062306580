import React from "react";
import EndPointsTable from "./EndPointsTable";

const Endpoints = () => {
    const userDataList = [
      { endPoints: 1, dataSource:'John@Gmail.com',  endPointName: 'John Doe', type: 'Admin', addedOn: 1234567890 },
      { endPoints: 2, dataSource:'Jane@Gmail.com',  endPointName: 'Jane Doe', type: 'User', addedOn: 2345678901 },
      { endPoints: 3, dataSource:'Bob@Gmail.com',  endPointName: 'Bob Smith', type: 'User', addedOn: 3456789012 },
      { endPoints: 4, dataSource:'Alice@Gmail.com',  endPointName: 'Alice Johnson', type: 'Admin', addedOn: 4567890123 },
    ];

  return (
    <React.Fragment>
     <EndPointsTable endpointData={userDataList}/>
    </React.Fragment>
  );
};
export default Endpoints;
