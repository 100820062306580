import React from "react";
interface group11 extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
const Group11 = (props: group11) => {
  return (
    <svg
      id="Group_11"
      data-name="Group 11"
      xmlns="http://www.w3.org/2000/svg"
      width="23.27"
      height="18.482"
      viewBox="0 0 23.27 18.482"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_9"
            data-name="Rectangle 9"
            width="23.27"
            height="18.482"
            fill="#b9b9b9"
          />
        </clipPath>
      </defs>
      <g
        id="Group_10"
        data-name="Group 10"
        transform="translate(0 0)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Path_123"
          data-name="Path 123"
          d="M17.062,13.562A19.879,19.879,0,0,0,20.9,8.853s-4.363-8-11.635-8a10.2,10.2,0,0,0-4.058.855L6.33,2.83a8.648,8.648,0,0,1,2.938-.522,10.846,10.846,0,0,1,7.516,3.573A19.184,19.184,0,0,1,19.2,8.853c-.084.127-.177.266-.284.419a19.179,19.179,0,0,1-2.131,2.552c-.24.24-.49.477-.752.707Z"
          transform="translate(2.367 0.388)"
          fill="#b9b9b9"
        />
        <path
          id="Path_124"
          data-name="Path 124"
          d="M14.386,9.656A5.09,5.09,0,0,0,7.879,3.149l1.2,1.2A3.636,3.636,0,0,1,13.191,7.43a3.592,3.592,0,0,1,0,1.03Zm-4.28,1.889,1.2,1.2A5.09,5.09,0,0,1,4.795,6.233l1.2,1.2a3.636,3.636,0,0,0,3.085,4.114,3.592,3.592,0,0,0,1.03,0"
          transform="translate(2.044 1.296)"
          fill="#b9b9b9"
        />
        <path
          id="Path_125"
          data-name="Path 125"
          d="M4.872,5.562q-.393.349-.753.708A19.184,19.184,0,0,0,1.7,9.241l.284.419a19.179,19.179,0,0,0,2.131,2.552,10.849,10.849,0,0,0,7.516,3.573,8.618,8.618,0,0,0,2.938-.524l1.12,1.123a10.2,10.2,0,0,1-4.058.855c-7.272,0-11.635-8-11.635-8A19.8,19.8,0,0,1,3.841,4.532l1.03,1.031ZM19.846,18.482,2.394,1.03,3.424,0,20.876,17.452Z"
          transform="translate(0 0)"
          fill="#b9b9b9"
        />
      </g>
    </svg>
  );
};
export default Group11;
