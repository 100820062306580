// Store
import { store } from "../../redux";
import { setRequestState } from "../../redux/appreadouts/actions";
import {
  IError,
  ITranslateKeyError,
} from "../../interfaces/general.interfaces";
// Interfaces
import { IErrorCodeOptions, IMiddlewareOptions } from "./interfaces";
// Helpers
import { isResponseStatusHandled, redirectToSystemError } from "./helpers";
// Config
import { staticConfig } from "../../config/static";
import { getRefreshToken } from "../refreshToken.service";

const settings = {
  apiBaseUrl: staticConfig.apiBaseUrl,
};

const getUserName = () => {
  return localStorage.getItem("email");
};

const getRefreshTokenfromLocal = () => {
  return localStorage.getItem("refresh_token");
};

export const typedRequest = <T>(
  url: string,
  options: RequestInit = {},
  middlewareOptions?: IMiddlewareOptions
): Promise<T> =>
  new Promise(async (resolve, reject) => {
    const isUrlExclusive = middlewareOptions?.options?.isUrlExclusive;
    const errorCodeOptions = middlewareOptions?.errorCodeOptions;
    const fetchUrl = isUrlExclusive ? url : settings.apiBaseUrl + url;

    try {
      store.dispatch(setRequestState(true));
      await executeFetchRequest(
        fetchUrl,
        options,
        errorCodeOptions,
        resolve,
        reject
      );
    } catch (error) {
      reject(error);
    } finally {
      store.dispatch(setRequestState(false));
    }
  });

const executeFetchRequest = async <T>(
  fetchUrl: string,
  options: RequestInit,
  errorCodeOptions: IErrorCodeOptions,
  resolve: (value: T | PromiseLike<T>) => void,
  reject: (reason?: any) => void
): Promise<void> => {
  const response = await fetch(fetchUrl, options);
  const responseStatusHandled = isResponseStatusHandled(
    response,
    errorCodeOptions
  );

  if (
    !response.ok &&
    ![400, 401, 403, 404, 409, 422].includes(response.status) &&
    !responseStatusHandled
  ) {
    redirectToSystemError(response, null);
    reject();
  }

  const responseType = response.headers.get("content-type");

  let rawResponse: any;
  if (responseType && responseType.toLowerCase().includes("zip")) {
    rawResponse = await response.blob();
  } else {
    rawResponse = await response.text();
  }
  const responseBody = parseRawResponse(rawResponse);

  if (!response.ok) {
    resolveBody(
      resolve,
      reject,
      responseBody,
      responseStatusHandled,
      response,
      errorCodeOptions
    );
    return;
  }

  resolveBody(
    resolve,
    reject,
    responseBody,
    responseStatusHandled,
    response,
    errorCodeOptions
  );
};

const parseRawResponse = (rawResponse: string): any => {
  try {
    return JSON.parse(rawResponse);
  } catch {
    return rawResponse;
  }
};

const resolveBody = <T>(
  resolve: (value: T | PromiseLike<T>) => void,
  reject: (reason?: any) => void,
  resBody: any,
  responseStatusHandled: boolean,
  response: Response,
  errorCodeOptions?: IErrorCodeOptions
): void => {
  const errorList: Array<IError | ITranslateKeyError> = [];

  if (
    (resBody.validationErrors && resBody.validationErrors.length > 0) ||
    resBody.errorCode
  ) {
    if (resBody.validationErrors) {
      // The validation error logic goes here
    } else if (resBody.errorCode) {
      // The errorcode logic goes here
    }
    reject(errorList);
  } else if (resBody.error_description) {
    reject(resBody);

    return;
  } else {
    if (response.ok) {
      resolve(resBody);

      return;
    }

    if (responseStatusHandled) {
      reject({
        status: response.status,
        body: resBody,
      });

      return;
    }

    if (response.status === 401) {
      reject({
        status: response.status,
        body: response.body,
        resBody: resBody,
      });

      !(resBody?.detail === "Not enough permissions") &&
        getRefreshTokenfromLocal() &&
        getRefreshToken(getRefreshTokenfromLocal(), getUserName())
          .then((res) => {
            localStorage.setItem("access_token", res.access_token);
            window.location.reload();
          })
          .catch((res) => {
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("customer_id");
            localStorage.removeItem("user_id");
            localStorage.removeItem("environment_id");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("access_token");
            localStorage.removeItem("email");
            window.location.href = "/";
          });
    }

    if (response.status === 403) {
      // add errorlist here
      reject(errorList);

      return;
    }

    if (response.status === 400) {
      reject({
        status: response.status,
        body: response.body,
        resBody: resBody,
      });

      return;
    }

    if (response.status === 409) {
      reject({
        status: response.status,
        body: response.body,
        resBody: resBody,
      });

      return;
    }

    if (response.status === 422) {
      reject({
        status: response.status,
        body: response.body,
        resBody: resBody,
      });

      return;
    }

    if (response.status === 404) {
      reject({
        status: response.status,
        body: response.body,
        resBody: resBody,
      });

      return;
    }

    redirectToSystemError(response, null);
  }
};
