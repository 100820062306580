import React from "react";
interface CloseButttonIcon extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
const CloseIconGreen = (props: CloseButttonIcon) => {
  return (
    <svg
      id="Group_172"
      data-name="Group 172"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 20 20"
      onClick={props.onClick}
      className={props.className}
    >
      <g id="Group_13" data-name="Group 13">
        <path
          id="Path_106"
          data-name="Path 106"
          d="M19.229,36.62"
          transform="translate(-9.248 -16.62)"
          fill="#03234a"
        />
        <path
          id="Path_107"
          data-name="Path 107"
          d="M49.392,50.388c-.4.41-.764.82-1.164,1.2-2.064,1.952-4.123,3.909-6.217,5.832a2.731,2.731,0,0,1-1.239.616,1.049,1.049,0,0,1-1.139-.607,1.1,1.1,0,0,1,.1-1.291,7.939,7.939,0,0,1,.737-.755q3.457-3.21,6.92-6.415c.072-.067.134-.143.229-.246-.157-.165-.3-.328-.45-.474q-3.394-3.2-6.79-6.4a6.93,6.93,0,0,1-.563-.574,1.149,1.149,0,0,1,.069-1.677,1.279,1.279,0,0,1,1.775.03c1.244,1.133,2.459,2.293,3.684,3.444,1.249,1.175,2.509,2.34,3.734,3.537.334.326.516.277.822-.014q3.529-3.35,7.085-6.675a3.607,3.607,0,0,1,.6-.467,1.242,1.242,0,0,1,1.514.189,1.1,1.1,0,0,1,.172,1.437,4.573,4.573,0,0,1-.568.633q-3.546,3.368-7.1,6.731c-.083.079-.15.173-.321.373,1.414,1.294,2.83,2.58,4.235,3.876,1.113,1.026,2.221,2.057,3.31,3.1a2.259,2.259,0,0,1,.59.838.987.987,0,0,1-.431,1.168,1.213,1.213,0,0,1-1.36.118,5.908,5.908,0,0,1-1.076-.831Q53.215,53.97,49.9,50.833c-.132-.124-.273-.24-.508-.445"
          transform="translate(-39.471 -39.254)"
          fill="#03234a"
        />
      </g>
    </svg>
  );
};
export default CloseIconGreen;
