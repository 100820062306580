import React from "react";
interface Group2417 extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  height?: string;
  width?: string;
  [key: string]: any;
}
const Group2417 = (props: Group2417) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "72"}
      height={props.height || "72"}
      viewBox={props.viewBox || "0 0 72 72"}
    >
      <g id="Group_2417" data-name="Group 2417" transform="translate(-89 -30)">
        <path
          id="Path_1901"
          data-name="Path 1901"
          d="M36,0A36,36,0,1,1,0,36,36,36,0,0,1,36,0Z"
          transform="translate(89 30)"
          fill="#03234a"
        />
        <g id="Group_2411" data-name="Group 2411" transform="translate(107 48)">
          <path
            id="Path_1902"
            data-name="Path 1902"
            d="M2,2H8.06V8.077H2ZM0,10.077H10.059V0H0Z"
            fill="white"
          />
          <path
            id="Path_1903"
            data-name="Path 1903"
            d="M22.687,1.346H15.314V8.731h7.373Zm-2,5.384H17.314V3.345h3.373Z"
            transform="translate(-1)"
            fill="white"
          />
          <path
            id="Path_1904"
            data-name="Path 1904"
            d="M1.895,28.14h5.74v5.914H1.895Zm26.47,0h5.74v5.914h-5.74Zm-13.236-13.1h5.741v5.912H15.129Zm13.236,0h5.74v5.912h-5.74Zm0-13.1h5.74V7.859h-5.74ZM26.47,0V9.8h3.816V13.1H26.47v3.929H22.764V13.1h-9.53v8.4L8.217,26.2H0V36H9.53V27.6l5.02-4.7h6.9l5.02,4.7V36H36V26.2H27.783l-5.019-4.7V18.973H26.47V22.9H36V13.1H32.182V9.8H36V0Z"
            fill="white"
          />
          <path
            id="Path_1905"
            data-name="Path 1905"
            d="M2,15.462H8.06v6.076H2ZM0,23.538H10.059V13.462H0Z"
            transform="translate(0 -0.5)"
            fill="white"
          />
          <path
            id="Path_1906"
            data-name="Path 1906"
            d="M15.313,35.654h7.373V28.269H15.313Zm2-5.384h3.373v3.385H17.313Z"
            transform="translate(-1 -1)"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};
export default Group2417;
