import React, { useState, useRef, useEffect } from "react";
import styles from "./CodeVerification.module.scss";
import wadeIcon from "../../../components/Icons/wadeIcon.svg";
import { Button, Modal } from "react-bootstrap";
import { getEmailVerify2FAService } from "../../../services/emailVerify2FA.service";
import { getVerify2FAService } from "../../../services/Verify2FA.service";
import { cn } from "../../../helpers";
import { getExistingUserVerify2FAService } from "../../../services/existingUserVerfiy2FA.service";
import { getEmailEnable2FAService } from "../../../services/emailEnable2FA.service";
import { getLoginAccessToken } from "../../../services/login.service";
import { setPassword } from "../../../redux/login/actions";
import { useDispatch } from "react-redux";

interface CodeVerificationProps {
  show: boolean;
  handleSuccessSubmit: any;
  email: string;
  password: string;
  user_id: number;
  verifyType: string;
  authMethod?: string;
  length: number;
}

const CodeVerificationDialog: React.FC<CodeVerificationProps> = ({
  show,
  handleSuccessSubmit,
  email,
  password,
  user_id,
  verifyType,
  authMethod,
  length,
}) => {
  const [otp, setOTP] = useState<string[]>([]);
  const inputs = useRef<HTMLInputElement[]>([]);
  const [validOTP, setValidOTP] = useState(true);
  const [resendCodeMessageStatus, setResendCodeMessageStatus] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inputs.current.length > 0) {
      inputs.current[0].focus();
    }
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (isNaN(Number(value))) {
      return;
    }

    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value !== "") {
      if (index === length - 1) {
        inputs.current[index].blur();
      } else {
        inputs.current[index + 1].focus();
      }
    }
  };

  const onPaste = (event: React.ClipboardEvent) => {
    const { clipboardData } = event;
    const pastedText = clipboardData.getData("text");
    setOTP(pastedText.split(""));
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && otp[index] === "" && index !== 0) {
      inputs.current[index - 1].focus();
    }
  };

  const onSubmitButtonHandler = () => {
    if (verifyType === "microsoft") {
      getVerify2FAService(user_id, otp.join(""), email, password)
        .then((res) => {
          getLoginAccessToken(email, password).then((res) => {
            localStorage.setItem("email", email);
            dispatch(setPassword(password));
            localStorage.setItem("access_token", res.access_token);
            localStorage.setItem("user_id", res.user_id);
            handleSuccessSubmit();
            setOTP([]);
          });
        })
        .catch((response) => {
          setValidOTP(false);
          setOTP([]);
        });
    } else if (verifyType === "email") {
      getEmailVerify2FAService(user_id, otp.join(""), email, password)
        .then((res: any) => {
          getLoginAccessToken(email, password).then((res) => {
            localStorage.setItem("email", email);
            dispatch(setPassword(password));
            localStorage.setItem("access_token", res.access_token);
            localStorage.setItem("user_id", res.user_id);
            handleSuccessSubmit();
            setOTP([]);
          });
        })
        .catch((response) => {
          setValidOTP(false);
          setResendCodeMessageStatus(false);
          setOTP([]);
        });
    } else if (verifyType === "existingUser") {
      getExistingUserVerify2FAService(user_id, otp.join(""), email, password)
        .then((res: any) => {
          localStorage.setItem("email", email);
          dispatch(setPassword(password));
          handleSuccessSubmit();
          setOTP([]);
        })
        .catch((response) => {
          setValidOTP(false);
          setResendCodeMessageStatus(false);
          setOTP([]);
        });
    }
  };

  const onResendCodeClick = () => {
    setValidOTP(true);
    setResendCodeMessageStatus(true);
    setOTP([]);
    if (verifyType === "email" || authMethod === "EMAIL") {
      getEmailEnable2FAService(email, password, user_id);
    }
  };
  return (
    <div>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleSuccessSubmit}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles.modalPopUpCodeVerification}
      >
        <Modal.Body>
          <div>
            <div
              className={cn(
                styles.codeVerificationMain,
                "d-flex justify-content-center"
              )}
            >
              <div
                className={cn(
                  styles.codeVerificationMainBody,
                  "d-flex justify-content-center align-items-center"
                )}
              >
                <div>
                  <span
                    className={cn(
                      styles.codeVerificationBodyHeadWade,
                      "d-flex justify-content-center"
                    )}
                  >
                    <img src={wadeIcon} />
                  </span>
                  <span
                    className={cn(
                      styles.codeVerificationBodyHeadEmailVerification,
                      "d-flex justify-content-center"
                    )}
                  >
                    {verifyType === "microsoft" ? (
                      <p>Microsoft code verification</p>
                    ) : authMethod === "AUTHENTICATOR" ? (
                      <p>Microsoft code verification</p>
                    ) : (
                      <p>Email code verification</p>
                    )}
                  </span>
                  <span className={styles.codeVerificationBodyHeadCodeNote}>
                    {verifyType === "microsoft" ||
                    authMethod === "AUTHENTICATOR" ? (
                      <p>
                        Please enter the code from the Microsoft Authenticator
                      </p>
                    ) : (
                      <p>
                        Please enter the code that has been sent to your
                        registered email id
                      </p>
                    )}
                  </span>
                  <div
                    className={cn(
                      styles.codeVerificationBodyOptionEmail,
                      "d-flex justify-content-center"
                    )}
                  >
                    <div
                      className={cn(
                        styles.codeVerificationBodyCode,
                        "d-flex justify-content-center"
                      )}
                    >
                      {Array(length)
                        .fill("")
                        .map((_, index) => (
                          <div
                            className={styles.codeVerificationBodyEmailInput}
                          >
                            <input
                              key={index}
                              type="text"
                              maxLength={1}
                              value={otp[index] || ""}
                              onChange={(e) => handleChange(e, index)}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              onPaste={(e) => onPaste(e)}
                              ref={(ref) =>
                                (inputs.current[index] =
                                  ref as HTMLInputElement)
                              }
                              autoFocus={index === 0}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  {!validOTP && (
                    <div
                      className={cn(
                        styles.invalidOTPMessage,
                        "d-flex justify-content-center"
                      )}
                    >
                      Invalid Code. Please Enter Valid Code
                    </div>
                  )}
                  {resendCodeMessageStatus && (
                    <div
                      className={cn(
                        styles.invalidOTPMessage,
                        "d-flex justify-content-center"
                      )}
                    >
                      Code has been sent to the Registered Email
                    </div>
                  )}
                  <div
                    className={cn(
                      styles.codeVerificationBodyBTNBody,
                      "d-flex justify-content-center"
                    )}
                  >
                    <Button
                      disabled={otp.length !== 6 || otp.includes("")}
                      className={styles.codeVerificationBodyBTN}
                      onClick={onSubmitButtonHandler}
                    >
                      SUBMIT
                    </Button>
                  </div>
                  {(verifyType === "email" || authMethod === "EMAIL") && (
                    <div
                      className={cn(
                        styles.codeVerificationResendCode,
                        "d-flex justify-content-center"
                      )}
                      onClick={onResendCodeClick}
                    >
                      <p>Resend Code</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default CodeVerificationDialog;
