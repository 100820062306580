import * as api from "./middleware/api.middleware";

const serviceUrl = "login";

export function getLoginAccessToken(x: string, y: string) {
  var bodyFormData = new FormData();
  bodyFormData.append("username", x);
  bodyFormData.append("password", y);
  const getResponse = api.postFormData<any>(
    `${serviceUrl}/access_token`,
    bodyFormData
  );
  return getResponse;
}
