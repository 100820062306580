import React from "react";
interface group7 extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
const Group7 = (props: group7) => {
  return (
    <svg
      id="Group_7"
      data-name="Group 7"
      xmlns="http://www.w3.org/2000/svg"
      width="23.273"
      height="16"
      viewBox="0 0 23.273 16"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_7"
            data-name="Rectangle 7"
            width="23.273"
            height="16"
            fill="#03234a"
          />
        </clipPath>
      </defs>
      <g id="Group_6" data-name="Group 6" clip-path="url(#clip-path)">
        <path
          id="Path_120"
          data-name="Path 120"
          d="M23.273,8S18.909,0,11.636,0,0,8,0,8s4.364,8,11.636,8S23.273,8,23.273,8M1.706,8A19.186,19.186,0,0,1,4.121,5.028a10.838,10.838,0,0,1,7.516-3.574,10.847,10.847,0,0,1,7.517,3.574A19.187,19.187,0,0,1,21.568,8c-.084.127-.177.266-.284.419a19.181,19.181,0,0,1-2.131,2.553,10.85,10.85,0,0,1-7.517,3.574,10.847,10.847,0,0,1-7.517-3.574A19.186,19.186,0,0,1,1.7,8Z"
          fill="#03234a"
        />
        <path
          id="Path_121"
          data-name="Path 121"
          d="M9.591,3.455a3.636,3.636,0,1,0,3.636,3.636A3.636,3.636,0,0,0,9.591,3.455M4.5,7.091a5.091,5.091,0,1,1,5.091,5.091A5.091,5.091,0,0,1,4.5,7.091"
          transform="translate(2.045 0.909)"
          fill="#03234a"
        />
      </g>
    </svg>
  );
};
export default Group7;
