import * as api from "./middleware/api.middleware";

const serviceUrl = "users";

export function getEmailEnable2FAService(
  username: string,
  password: string,
  id: number
) {
  const user_name = username;
  const pass_word = password;
  const encodedCredentials = btoa(`${user_name}:${pass_word}`);
  const authHeader = `Basic ${encodedCredentials}`;

  const getResponse = api.post<any>(
    `${serviceUrl}/${id}/enable_2fa_email`,
    {
      user_id: `${id}`,
    },
    {
      Authorization: authHeader,
    }
  );
  return getResponse;
}
