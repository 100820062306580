import React from "react";
import styles from "./BreadCrumbs.module.scss";
import { Breadcrumb } from "react-bootstrap";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
export interface InputBreadCrumbs {
  breadCrumbsData: { name?: string; url: string }[];
}

const BreadCrumbs = (props: InputBreadCrumbs) => {
  const location = useLocation();
  const { breadCrumbsData } = props;
  const activeLinkIndex = breadCrumbsData.findIndex(
    (breadCrumb) => breadCrumb.url === location.pathname
  );
  return (
    <div className={styles.BreadcrumbContainer}>
      <Breadcrumb className={styles.Breadcrumb}>
        {breadCrumbsData.map((item, index) => {
          const breadcrumbItemClass = classNames(styles.BreadcrumbItem, {
            [styles.active]: index === activeLinkIndex,
          });
          return (
            <Breadcrumb.Item key={index} className={breadcrumbItemClass}>
              <Link to={item.url}>{item.name}</Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      <hr />
    </div>
  );
};

export default BreadCrumbs;
