import React from "react";
import style from "./Support.module.scss";

const SupportPage = () => {
  return (
    <div className={style.supportMainContainer}>
      <div className={style.supportText}>
        For technical support contact :&nbsp;
      </div>
      <a className={style.supportEmail} href="mailto:support@wadeinsight.com">
        support@wadeinsight.com
      </a>
    </div>
  );
};

export default SupportPage;
