import { getUserInterface } from "../interfaces/userList.interface";
import * as api from "./middleware/api.middleware";

const serviceUrl = "users";
const accessToken = localStorage.getItem("access_token");
const token = {
  Authorization: `Bearer ${accessToken}`,
};
export function getUserList() {
  const getResponse = api.get<getUserInterface[]>(`${serviceUrl}`, token);
  return getResponse;
}
