import React, { useState } from "react";
import styles from "./Authenticator.module.scss";
import { Button, Modal } from "react-bootstrap";
import { cn } from "../../../helpers";
import selectRightIcon from "../../../components/Icons/selectRightIcon.svg";
import microsoftLogo from "../../../components/Icons/microsoftLogo.svg";
import phoneIcon from "../../../components/Icons/phoneIcon.png";
import wadeIcon from "../../../components/Icons/wadeIcon.svg";
import emailLogo from "../../../components/Icons/emailLogo.svg";
import MicrosoftAuthenticatorDialog from "../../../containers/Authenticator/MicrosoftAuthentication/MicrosoftAuthenticatorDialog";
import EmailVerificationDialog from "../../../containers/Authenticator/EmailVerification/EmailVerificationDialog";
import { getEnable2FAQRCode } from "../../../services/Enable2FA.service";

export interface AuthenticatorProps {
  show: boolean;
  email: string;
  password: string;
  user_id: number;
  onHide: any;
}

const AuthenticatorDialog = (props: AuthenticatorProps) => {
  const { show, email, password, user_id, onHide } = props;

  const [selectAuthenticator, setSelectAuthenticator] = useState("");
  const [emailVerificationDialog, setEmailVerificationDialog] = useState(false);
  const [microsoftAuthenticationDialog, setMicrosoftAuthenticationDialog] =
    useState(false);
  const [qrvalue, setQrValue] = React.useState("");

  const handleauthenticator = (e: any) => {
    setSelectAuthenticator(e);
  };
  const nextButtonClickHandler = () => {
    if (selectAuthenticator === "email") {
      onHide();
      setEmailVerificationDialog(true);
    } else if (selectAuthenticator === "microsoft") {
      onHide();
      setMicrosoftAuthenticationDialog(true);
    }
  };

  React.useEffect(() => {
    if (selectAuthenticator === "microsoft") {
      getEnable2FAQRCode(user_id, email || "", password || "").then(
        (res: any) => setQrValue(res.provisioning_uri)
      );
    }
  }, [selectAuthenticator]);

  return (
    <div>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles.authenticatorMain}
      >
        <Modal.Body>
          <div
            className={cn(
              styles.authenticatorMain,
              "d-flex justify-content-center"
            )}
          >
            <div
              className={cn(
                styles.authenticatorMainBody,
                "d-flex justify-content-center align-items-center"
              )}
            >
              <div>
                <span
                  className={cn(
                    styles.authenticatorBodyHead,
                    "d-flex justify-content-center"
                  )}
                >
                  <img src={wadeIcon} />
                </span>
                <span className={styles.authenticatorBodyHead}>
                  <p>Choose any one multi-factor authenticator</p>
                </span>
                <div
                  onClick={() => handleauthenticator("microsoft")}
                  className={cn(
                    styles.authenticatorBodyOption,
                    "d-flex justify-content-center"
                  )}
                >
                  <div
                    style={{
                      backgroundColor:
                        selectAuthenticator === "microsoft" && "#D0FB56",
                    }}
                    className={styles.authenticatorBodyLPMicrosoft}
                  >
                    <img
                      className={styles.authenticatorLeftIcons}
                      src={microsoftLogo}
                    />
                  </div>
                  <div className={styles.authenticatorBodyMP}>
                    <h6>Microsoft Authentication</h6>
                    <span>
                      Install the Microsoft authentication app on mobile…
                    </span>
                  </div>
                  {selectAuthenticator === "microsoft" && (
                    <div className={styles.authenticatorBodyRP}>
                      <img
                        src={selectRightIcon}
                        className={styles.authenticatoRightIcon}
                      />
                    </div>
                  )}
                </div>
                <div
                  onClick={() => handleauthenticator("email")}
                  className={cn(
                    styles.authenticatorBodyOption,
                    "d-flex justify-content-center"
                  )}
                >
                  <div
                    style={{
                      backgroundColor:
                        selectAuthenticator === "email" && "#D0FB56",
                    }}
                    className={styles.authenticatorBodyLPEmail}
                  >
                    <img
                      className={styles.authenticatorLeftIcons}
                      src={emailLogo}
                    />
                  </div>
                  <div className={styles.authenticatorBodyMP}>
                    <h6>Email Authentication</h6>{" "}
                    <span>Receive an authentication code to email</span>
                  </div>
                  {selectAuthenticator === "email" && (
                    <div className={styles.authenticatorBodyRP}>
                      <img
                        className={styles.authenticatoRightIcon}
                        src={selectRightIcon}
                      />
                    </div>
                  )}
                </div>
                <div
                  // onClick={() => handleauthenticator("phone")}
                  className={cn(
                    styles.authenticatorBodyOption,
                    "d-flex justify-content-center"
                  )}
                >
                  <div className={styles.authenticatorBodyLPPhone}>
                    <img
                      className={styles.authenticatorLeftIcons}
                      src={phoneIcon}
                      aria-disabled
                    />
                  </div>
                  <div className={styles.authenticatorBodyMPPhone}>
                    <h6>Phone Authentication</h6>
                    <span>Receive an authentication code to phone</span>
                  </div>
                  {selectAuthenticator === "phone" && (
                    <div className={styles.authenticatorBodyRP}>
                      <img
                        className={styles.authenticatoRightIcon}
                        src={selectRightIcon}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={cn(
                    styles.authenticatorBodyBTNBody,
                    "d-flex justify-content-center"
                  )}
                >
                  <Button
                    className={styles.authenticatorBodyBTN}
                    onClick={nextButtonClickHandler}
                    disabled={selectAuthenticator.length === 0}
                  >
                    NEXT
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <EmailVerificationDialog
        show={emailVerificationDialog}
        onEmailDialogHide={() => setEmailVerificationDialog(false)}
        email={email}
        password={password}
        user_id={user_id}
      />
      <MicrosoftAuthenticatorDialog
        show={microsoftAuthenticationDialog}
        onHide={() => setMicrosoftAuthenticationDialog(false)}
        email={email}
        password={password}
        user_id={user_id}
        qrValue={qrvalue}
      />
    </div>
  );
};
export default AuthenticatorDialog;
