import React, { useCallback, useEffect, useState } from "react";
import styles from "./ModelDependency.module.scss";

import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  OnNodesChange,
  Node,
} from "reactflow";

import "reactflow/dist/style.css";
import "./dependency.scss";
import ModelCustomNode from "./ModelCustomNode";
import { Form } from "react-bootstrap";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import BackArrowIcon from "../../assets/images/svg/ArrowLeftCircle";
import DarkPlusIconCircle from "../../assets/images/svg/DarkPlusIconCircle";
import {
  deleteCbcDetails,
  deleteEnsembleModelCbcRelation,
  getCbcDetails,
  getEnsembleModelCbcRelation,
  getEnsembleModelCbcRelationById,
} from "../../services/ensembleModelling.service";
import deleteFile from "../../components/Icons/deleteFile.svg";
import DeleteConfirmationPopup from "../../components/DeletePopup/DeleteConfirmationPopup";

const nodeTypes = { customNode: ModelCustomNode };

const ModalDependency = (props: any) => {
  const {
    setShowJobTaskPage,
    ensembleModelSelectedNode,
    setCbcChanges,
    onCbcDataChange,
    cbcUpdated,
    setCbcDependencyChange,
  } = props;

  const initialNodes: Node[] = [];
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState([]);
  const [clickedNode, setClickedNode] = useState<any>(null);
  const [addNewCbc, setAddNewCbc] = useState<boolean>(false);
  const [connectedNodes, setConnectedNodes] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteEdge, setDeleteEdge] = useState<any>();
  const [cbcDetails, setCbcDetails] = useState({
    ensemble_model_cbc_name: "",
    ensemble_model_cbc_type: "",
    ensemble_name: "",
    business_keys: "",
    description: "",
    canvas_position: "",
  });
  const [deleteCbc, setDeleteCbc] = useState<boolean>(false);
  const [searchData, setSearchData] = useState([]);
  const [addedNodes, setAddedNodes] = useState([]);

  useEffect(() => {
    let updatedEdges: any[] = [];

    getEnsembleModelCbcRelation(ensembleModelSelectedNode.id)
      .then((res: any) => {
        updatedEdges = res.map((value: any) => ({
          id: value.ensemble_model_relation_id,
          source: `${value.src_ensemble_model_cbc_id}`,
          target: `${value.tgt_ensemble_model_cbc_id}`,
          type: "smoothstep",
          style: { stroke: "black", strokeWidth: 2 },
        }));
        setEdges(updatedEdges);
      })
      .catch(() => {
        setEdges([]);
      });
  }, []);

  useEffect(() => {
    getCbcDetails(ensembleModelSelectedNode.id).then((res) => {
      res?.sort((a: any, b: any) => {
        if (a !== null && b !== null) {
          let fa = a?.ensemble_model_cbc_name?.toLowerCase(),
            fb = b?.ensemble_model_cbc_name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
        }
        return 0;
      });

      if (res.length > 0) {
        setSearchData(res);
        setAddNewCbc(false);
        setCbcDetails(res[0]);
      } else {
        setAddNewCbc(true);
      }
    });
  }, [cbcUpdated]);

  useEffect(() => {
    onCbcDataChange({
      cbcDetails: cbcDetails,
      updated: addNewCbc ? false : true,
      addedNodes: addedNodes,
      searchData: searchData,
      nodes: nodes,
      edges: edges,
      addNewCbc: addNewCbc,
    });
  }, [cbcDetails, clickedNode, nodes, edges, addNewCbc, addedNodes]);

  useEffect(() => {
    let updatedNodes: any[] = [];
    let maxObject;
    let maxY = Number.MIN_SAFE_INTEGER;

    for (const obj of searchData) {
      if (obj?.canvas_position?.x === 100 && obj?.canvas_position?.y > maxY) {
        maxY = obj.canvas_position.y;
        maxObject = obj;
      }
    }
    let counter = maxObject ? maxObject.canvas_position.y : -100;
    updatedNodes = searchData.map((value: any) => {
      function isObjectEmpty(obj: any) {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
      }
      return {
        id: `${value.ensemble_model_cbc_id}`,
        position: !isObjectEmpty(value.canvas_position)
          ? value.canvas_position
          : { x: 100, y: (counter += 150) },
        data: {
          label: value.ensemble_model_cbc_name,
          type: value.ensemble_model_cbc_type,
        },
        type: "customNode",
      };
    });

    setNodes(updatedNodes);
    !clickedNode && setClickedNode(updatedNodes[0]);
  }, [searchData]);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onNodeDragStart = (
    event: React.MouseEvent,
    node: Node,
    nodes: Node[]
  ) => {
    onNodeClick(null, node);
    setCbcDependencyChange(true);
    setCbcChanges(true);
  };

  const onNodeClick = (event: React.MouseEvent, element: any) => {
    setAddedNodes((pre: any) => [...pre, element.id]);
    const clickedNode = nodes.find(
      (el) => el.data.label === element.data.label
    );
    const clickedData = searchData.filter(
      (value) => value.ensemble_model_cbc_id == element.id
    );
    setCbcDetails(clickedData[0]);
    setClickedNode(clickedNode);
    setAddNewCbc(false);
    getEnsembleModelCbcRelationById(element.id)
      .then((res: any) => {
        const connectedNodes = res.map((value: any) => ({
          source: `${value.src_ensemble_model_cbc_id}`,
          target: `${value.tgt_ensemble_model_cbc_id}`,
          id: value.ensemble_model_cbc_relation_id,
        }));
        setConnectedNodes(connectedNodes);
      })
      .catch((res: any) => setConnectedNodes([]));

      if (element?.id && element.type === "customNode") {
        const updatedElements = edges.map((el) => {
          if (el.source === element.id || el.target === element.id) {
            return {
              ...el,
              style: { stroke: "blue", strokeWidth: 2.5 },
            };
          }
          return {
            ...el,
            style: { stroke: "grey", strokeWidth: 2.5 },
          };
        });
        setEdges(updatedElements);
      }
  };

  const onConnect = (params: any) => {
    if (params.source === params.target) {
      return;
    }
    setCbcDependencyChange(true);
    setCbcChanges(true);
    const sourceNode = nodes.filter((value) => value.id == params.source);
    const targetNode = nodes.filter((value) => value.id == params.target);
    setClickedNode(sourceNode[0]);
    setAddedNodes((pre) => [...pre, targetNode[0].id]);
    const id1 = `${sourceNode[0].id}-${targetNode[0].id}`;
    const newEdge = {
      id: id1,
      source: sourceNode[0].id,
      target: targetNode[0].id,
      type: "smoothstep",
      style: { stroke: "black", strokeWidth: 2 },
    };
    const existingIndex = edges.findIndex((item) => item.id === newEdge.id);
    if (existingIndex !== -1) {
      const updatedEdges = [...edges];
      updatedEdges[existingIndex] = newEdge;
      setEdges(updatedEdges);
    } else {
      setEdges((pre) => [...pre, newEdge]);
    }
  };

  const inputChangeHandler = (e: any) => {
    setCbcChanges(true);

    if (e.target.name === "is_active") {
      setCbcDetails({
        ...cbcDetails,
        [e.target.name]: e.target.checked,
      });
    } else if (e.target.name === "additional_properties") {
      setCbcDetails({
        ...cbcDetails,
        [e.target.name]: e.target.checked,
      });
    } else {
      setCbcDetails({
        ...cbcDetails,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleCreateNewCBCButtonClick = () => {
    setAddNewCbc(true);
    setCbcDependencyChange(false)
    setCbcChanges(false);
    setCbcDetails({
      ensemble_model_cbc_name: "",
      ensemble_model_cbc_type: "person",
      ensemble_name: "",
      business_keys: "",
      description: "",
      canvas_position: "",
    });
  };

  const handleDeleteCbc = () => {
    deleteCbcDetails(ensembleModelSelectedNode.id, clickedNode.id).then(() => {
      getCbcDetails(ensembleModelSelectedNode.id).then((res) => {
        setSearchData(res);
        res?.sort((a: any, b: any) => {
          if (a !== null && b !== null) {
            let fa = a?.ensemble_model_cbc_name?.toLowerCase(),
              fb = b?.ensemble_model_cbc_name.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
          }
          return 0;
        });
        if (res.length > 0) {
          setCbcDetails(res[0]);
          getEnsembleModelCbcRelationById(res[0].ensemble_model_cbc_id)
            .then((res: any) => {
              const connectedNodes = res.map((value: any) => ({
                source: `${value.src_ensemble_model_cbc_id}`,
                target: `${value.tgt_ensemble_model_cbc_id}`,
                id: value.ensemble_model_cbc_relation_id,
              }));
              setConnectedNodes(connectedNodes);
            })
            .catch((res: any) => setConnectedNodes([]));
        }
      });
    });
  };

  const getJobName = (edgeDetails: any) => {
    let targetNode;
    if (edgeDetails.source == clickedNode?.id) {
      targetNode = nodes.filter((node: any) => node.id === edgeDetails.target);
    } else {
      targetNode = nodes.filter((node: any) => node.id === edgeDetails.source);
    }
    if (targetNode[0]?.data?.label.length > 30) {
      return `${targetNode[0]?.data?.label.slice(0, 30)}...`;
    } else {
      return targetNode[0]?.data?.label;
    }
  };

  const deleteEdgeHandler = (deleteValue: any, deleteType: any) => {
    setDeleteEdge({ deleteValue: deleteValue, deleteType: deleteType });
    setDeleteModal(true);
  };

  const handleDeleteJobDependency = () => {
    deleteEnsembleModelCbcRelation(deleteEdge.deleteValue.id)
      .then(() => {
        let updatedEdges: any[] = [];

        getEnsembleModelCbcRelation(ensembleModelSelectedNode.id)
          .then((res: any) => {
            updatedEdges = res.map((value: any) => ({
              id: value.ensemble_model_relation_id,
              source: `${value.src_ensemble_model_cbc_id}`,
              target: `${value.tgt_ensemble_model_cbc_id}`,
              type: "smoothstep",
              style: { stroke: "black", strokeWidth: 2 },
            }));
            setEdges(updatedEdges);
          })
          .catch(() => {
            setEdges([]);
          });
      })
      .catch(() => {});
  };

  return (
    <>
      <div className={styles.modalDependencyContainer}>
        <div className={styles.modalDependencyGraphContainer}>
          <div className={styles.modalDependencyGraphContainerHeader}>
            <div
              className={styles.backButton}
              onClick={() => {
                setShowJobTaskPage(false);
                setCbcDependencyChange(false);
                setCbcChanges(false);
              }}
            >
              <BackArrowIcon className={styles.backArrow} />
              Back
            </div>
            <div className={styles.addButton}>
              <div
                onClick={handleCreateNewCBCButtonClick}
                className={styles.createJobIcon}
              >
                <DarkPlusIconCircle />
              </div>
              Create new CBC
            </div>
            <div className={styles.headerName}>
              {ensembleModelSelectedNode.data.subject_area} -{" "}
              {ensembleModelSelectedNode.data.label}
            </div>
          </div>
          <div className={styles.modalDependencyGraphContainerBody}>
            <ReactFlow
              fitView
              nodes={nodes.map((element) => ({
                ...element,
                data: {
                  ...element.data,
                  isSelected: element.id === clickedNode?.id,
                },
              }))}
              edges={edges}
              onNodesChange={onNodesChange}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              onNodeClick={onNodeClick}
              connectionLineStyle={{ stroke: "#555", strokeWidth: 2 }}
              snapToGrid={true}
              snapGrid={[20, 20]}
              onNodeDragStart={onNodeDragStart}
              zoomOnDoubleClick={false}
            >
              <Background color="#fff" />
              <Controls />
            </ReactFlow>
          </div>
        </div>

        <div className={styles.modalDependencyDetailsContainer}>
          <div className={styles.header}>
            {addNewCbc ? "Add CBC Details" : "Edit CBC Details"}
            <img
              className={styles.deleteIcon}
              src={deleteFile}
              alt="Delete File Icon"
              onClick={() => {
                setDeleteCbc(true);
              }}
            />
          </div>
          {clickedNode || addNewCbc ? (
            <div className={styles.contentContainer}>
              <div className={styles.description}>
                <Form>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label
                      htmlFor="exampleForm.ControlInput1"
                      className={styles.label}
                    >
                      Model Name
                      <CustomTooltip infoMessage="Model name of selected CBC" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="jobDetailsDescription"
                      name="job_description"
                      placeholder="Enter subject area"
                      disabled={true}
                      value={ensembleModelSelectedNode.data.label}
                      className={styles.disabledInput}
                      maxLength={500}
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className={styles.description}>
                <Form>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label
                      htmlFor="exampleForm.ControlInput1"
                      className={styles.label}
                    >
                      Subject Area
                      <CustomTooltip infoMessage="Subject Area name of selected CBC" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="jobDetailsDescription"
                      name="job_description"
                      placeholder="Enter subject area"
                      disabled={true}
                      value={ensembleModelSelectedNode.data.subject_area}
                      className={styles.disabledInput}
                      maxLength={500}
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className={styles.description}>
                <Form>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label
                      htmlFor="exampleForm.ControlInput1"
                      className={styles.label}
                    >
                      Core Business Concept (CBC)
                      <CustomTooltip infoMessage="Specify CBC name" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="jobDetailsDescription"
                      name="ensemble_model_cbc_name"
                      placeholder="Enter CBC"
                      disabled={false}
                      onChange={(e) => {
                        inputChangeHandler(e);
                      }}
                      value={cbcDetails.ensemble_model_cbc_name}
                      className={styles.input}
                      maxLength={500}
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className={styles.description}>
                <Form>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label
                      htmlFor="exampleForm.ControlInput1"
                      className={styles.label}
                    >
                      Ensemble Name
                      <CustomTooltip infoMessage="Specify ensemble model name" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="jobDetailsDescription"
                      name="ensemble_name"
                      placeholder="Enter emsemble name"
                      disabled={false}
                      onChange={(e) => {
                        inputChangeHandler(e);
                      }}
                      value={cbcDetails.ensemble_name}
                      className={styles.input}
                      maxLength={500}
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className={styles.description}>
                <Form>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label
                      htmlFor="exampleForm.ControlInput1"
                      className={styles.label}
                    >
                      Business Keys
                      <CustomTooltip infoMessage="Specify business keys" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="jobDetailsDescription"
                      name="business_keys"
                      placeholder="Enter emsemble name"
                      disabled={false}
                      onChange={(e) => {
                        inputChangeHandler(e);
                      }}
                      value={cbcDetails.business_keys}
                      className={styles.input}
                      maxLength={500}
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className={styles.description}>
                <Form>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label
                      htmlFor="exampleForm.ControlInput1"
                      className={styles.label}
                    >
                      Description
                      <CustomTooltip infoMessage="Description for the CBC" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="jobDetailsDescription"
                      name="description"
                      placeholder="Detailed description"
                      disabled={false}
                      onChange={(e) => {
                        inputChangeHandler(e);
                      }}
                      value={cbcDetails.description}
                      className={styles.input}
                      maxLength={500}
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className={styles.description}>
                <Form>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label className={styles.label}>
                      Type
                      <CustomTooltip infoMessage="Specify type of the CBC" />
                    </Form.Label>
                    <Form.Select
                      className={styles.input}
                      name="ensemble_model_cbc_type"
                      value={cbcDetails.ensemble_model_cbc_type}
                      onChange={(e) => {
                        inputChangeHandler(e);
                      }}
                    >
                      <option value="person">Person</option>
                      <option value="place">Place</option>
                      <option value="thing">Thing</option>
                      <option value="event">Event</option>
                      <option value="unknown">Unknown</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </div>
              {!addNewCbc && (
                <div className={styles.dependencyDetails}>
                  {clickedNode?.data && (
                    <div className={styles.dependencyDetailsHeader}>
                      Dependency Details
                    </div>
                  )}
                  <div className={styles.dependencyDetailsContent}>
                    <div className={styles.jobJobContainer}>
                      {connectedNodes.length < 1 && clickedNode?.data ? (
                        <div className={styles.noJobText}>
                          No Dependency Created
                        </div>
                      ) : (
                        connectedNodes.length < 1 && (
                          <div className={styles.noJobText}>
                            Select a Ensemble Model to view dependency details
                          </div>
                        )
                      )}
                    </div>
                    {connectedNodes.length >= 1 &&
                      connectedNodes.map((value) => {
                        return (
                          <div className={styles.selectedJobDetails}>
                            <div className={styles.linkedJobDetails}>
                              <div className={styles.linkedJobHeader}>
                                <div className={styles.linkedJobHeaderName}>
                                  {getJobName(value)}
                                </div>

                                <div
                                  className={
                                    styles.linkedJobHeaderEditAndDelete
                                  }
                                >
                                  <img
                                    className={styles.deleteFile}
                                    src={deleteFile}
                                    alt="Delete"
                                    onClick={() => {
                                      deleteEdgeHandler(value, "dependency");
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.noCbcSelected}>
              Select a CBC to view details
            </div>
          )}
        </div>
        <DeleteConfirmationPopup
          popUpMessage="Do you want to delete the selected Core Business Concept ?"
          show={deleteCbc}
          onHide={() => setDeleteCbc(false)}
          onSave={handleDeleteCbc}
        />
        <DeleteConfirmationPopup
          popUpMessage={
            deleteEdge?.deleteType === "dependency"
              ? "Do you want to delete the selected Core Business Concept Dependency?"
              : ""
          }
          show={deleteModal}
          onHide={() => {
            setDeleteModal(false);
          }}
          onSave={handleDeleteJobDependency}
        />
      </div>
    </>
  );
};

export default ModalDependency;
