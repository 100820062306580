import { createReducer } from "@reduxjs/toolkit";
import { IuserState } from "./interface";
import { UserConstants } from "./constants";
import * as UserActions from "./actions";

const initialUserState: IuserState = {
    isAuthorized: Boolean(localStorage.getItem("isAuthenticated")),
};

export const userReducer = createReducer(initialUserState, {
    // [UserConstants.USER_LOGIN]: (draftState, action: ReturnType<typeof UserActions.loginUser>) => {
    //     draftState.id = action.payload.userId;
    //     draftState.isAuthorized = true;
    // },

    // [UserConstants.USER_CHANGE_LANGUAGE]: (draftState, action: ReturnType<typeof UserActions.changeLanguage>) => {
    //     draftState.languageId = action.payload.languageId;
    // },

    [UserConstants.USER_SET_IS_AUTORIZED]: (draftState, action: ReturnType<typeof UserActions.setIsAuthorized>) => {
        draftState.isAuthorized = action.payload.isAuthorized;
    },
});
