import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data, type, page }: any) => {
  const chartdata = data ? { ...data } : null;
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        stepSize: type === "dataQuality" ? 1 : null,

        ticks: {
          stepSize: type === "dataQuality" ? 1 : null,
        },
        title: {
          display: true,
          stepSize: 1,
          text:
            type === "dataQuality"
              ? "Number of failed DQ Tests"
              : "Time (minutes)",
        },
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        intersect: false,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleFontColor: "#fff",
        bodyFontColor: "#fff",
        callbacks: {
          label: function (context: any) {
            function convertToMinutesAndSeconds(decimalMinutes: any) {
              const totalSeconds = decimalMinutes * 60;
              const minutes = Math.floor(totalSeconds / 60);
              const seconds = Math.floor(totalSeconds % 60);

              const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
              const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

              return `${formattedMinutes}:${formattedSeconds}`;
            }
            const label = context.dataset.label || "";
            const value = convertToMinutesAndSeconds(context.parsed.y);
            if (type === "dataMonitor") return `${label}: ${value}`;
            else return undefined;
          },
        },
      },
      legend: {
        position: "top" as const,
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text:
          type === "dataQuality"
            ? page === true
              ? "Number of failed DQ-Tests Over time (per subject area)"
              : "Number of failed DQ-Tests Over time (per data mart)"
            : "Job Duration (minutes)",
        font: {
          size: 18,
        },
      },
    },
  };

  const calculateOverallTrendLine = (datasets: any) => {
    const trendLineData = Array.from(
      { length: datasets[0]?.data.length },
      (_, i) => {
        const sum = datasets.reduce(
          (acc: any, dataset: any) => acc + dataset.data[i],
          0
        );
        return sum / datasets.length;
      }
    );
    return trendLineData;
  };

  const trendLineData = chartdata
    ? calculateOverallTrendLine(chartdata?.datasets)
    : null;

  const trendLineChartData = chartdata?.datasets?.length > 1 && {
    label: "Trend Line",
    data: trendLineData,
    borderColor: "rgb(255,0,0)",
    backgroundColor: "rgb(255,0,0)",
    tension: 0.4,
    borderDash: [5],
  };

  return (
    <Line
      options={options}
      data={
        chartdata?.datasets?.length > 1
          ? {
              labels: data.labels,
              datasets: [...data.datasets, trendLineChartData],
            }
          : chartdata
      }
    />
  );
};

export default LineChart;
