import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import Input from "../../components/Input/Input";
import "./EndPoints.scss";
export interface PropsData {
  endpointData: {
    endPoints: number;
    endPointName: string;
    type: string;
    dataSource: string;
    addedOn: number;
  }[];
}
const EndPointsTable = (props: PropsData) => {
  const { endpointData } = props;
  const [keyValue, setKeyValue] = useState("");

  const onKeyValue = (e: any) => {
    setKeyValue(e.target.value);
  };
  return (
    <div className="UserListTable_Main">
      <br />
      <div className="UserListTable_Header">
        <div>
          <span>Users ({endpointData.length})</span>
          <div className="UserListTable_search">
            <Input
              label=""
              placeholder="search"
              ariaDescribedBy="Enter Tenant ID"
              value={keyValue}
              maxlength={100}
              isDisabled={false}
              onChange={(e) => onKeyValue(e)}
            />
          </div>
        </div>
        <div className="UserListTable_BTN">
          <Button variant="light">Upload List</Button>
        </div>
      </div>
      <Table>
        <thead>
          <tr className="UserListTable_Table_Head">
            <th>End Points</th>
            <th>End Point Name</th>
            <th>Type</th>
            <th>Role Assigned</th>
            <th>Added On</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {endpointData?.length &&
            endpointData?.map((item) => {
              return (
                <tr>
                  <td>{item.endPoints}</td>
                  <td>{item.endPointName}</td>
                  <td>{item.type}</td>
                  <td>{item.dataSource}</td>
                  <td>{item.addedOn}</td>
                  <td>
                    <svg
                      width="24"
                      height="24"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                    </svg>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};
export default EndPointsTable;
