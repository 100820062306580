import React from "react";
interface backArrowIcon extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
const BackArrowIcon = (props: backArrowIcon) => {
  return (
    <span className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23.996"
        height="23.996"
        viewBox="0 0 23.996 23.996"
      >
        <path
          id="arrow-left-circle-fill"
          d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm5.249,11.248a.75.75,0,0,1,0,1.5H8.559l3.22,3.218a.751.751,0,1,1-1.062,1.062l-4.5-4.5a.75.75,0,0,1,0-1.062l4.5-4.5A.751.751,0,0,1,11.779,8.03l-3.22,3.218Z"
          fill="#03234a"
          opacity="0.497"
        />
      </svg>
    </span>
  );
};
export default BackArrowIcon;
