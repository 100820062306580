import { useState, useEffect, useRef } from 'react';
import { getCatalogueFilteredData, updateCatalogueData, createView, updateViewConfiguration, getViewConfigurationsData, deleteView } from '../../services/catalogue.service';
import useDebounce from './useDebounceHook';

export const useCatalogueData = () => {
    const [data, setData] = useState({ data: [], total: 0, total_pages: 0 });
    const [columnsToShow, setColumnsToShow] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [warningModalShow, setWarningModalShow] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [formattedString, setFormattedString] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemsForFilters, setSelectedItemsForFilters] = useState([]);
    const [modalFormData, setModalFormData] = useState<any>({
        source_entity_column_detail_id: '',
        source_entity_column_id: '',
        logical_name: '',
        description: '',
        constraint: '',
        data_format: '',
        example_values: '',
        enable_genai: undefined,
        pii: undefined,
        classification: "",
        owner: "",
        notes: '',
        created_at: '',
        updated_at: ''
    });
    const [formData, setFormData] = useState<any>({
        source_entity_column_detail_id: '',
        source_entity_column_id: '',
        logical_name: '',
        description: '',
        constraint: '',
        data_format: '',
        example_values: '',
        enable_genai: undefined,
        pii: undefined,
        classification: "",
        owner: "",
        notes: '',
        created_at: '',
        updated_at: ''
    });
    const [loading, setLoading] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [viewName, setViewName] = useState("");
    const [viewData, setViewData] = useState(null);
    const [selectedcatalog, setSelectedcatalog] = useState<{
        id: number,
        name: string
    }>({
        id: null,
        name: null
    });
    const [activeViewUpdate, setActiveViewUpdate] = useState<boolean>(false)
    const [showStatusModal, setStatusShowModal] = useState(false);

    const handleHide = () => {
        if (deleteViewActionTriggered && showStatusModal) {
            setStatusShowModal(false)
            setDeleteViewActionTriggered(false)
        } else {
            setStatusShowModal(false)
        }
    };

    const [showDtaSetModal, setShowDtaSetModal] = useState(false);

    const [multipleSelection, setMultipleSelection] = useState<string[]>([]);
    const [selectedEndpoints, setSelectedEndpoints] = useState<string[]>([]);

    const [showErrorModal, setShowErrorModal] = useState(false);

    const handleOpenDtaSetModal = () => setShowDtaSetModal(true);
    const handleCloseDtaSetModal = () => setShowDtaSetModal(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [apiFailedStatus, setApiFailedStatus] = useState(false)

    const [deleteViewActionTriggered, setDeleteViewActionTriggered] = useState(false)

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [viewNameToDelete, setViewNameToDelete] = useState({
        viewName: "",
        viewId: 0
    })

    const handleDeleteClick = (view: any) => {
        setViewNameToDelete(view)
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const hasMounted = useRef(false);

    const itemsPerPage = 5;

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        if (selectedItemsForFilters[0] !== "") {
            const updatedFormFields = selectedItemsForFilters.map(field => ({
                controlId: `form${field.charAt(0).toUpperCase() + field.slice(1)}`,
                label: field.split('_').map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
                type: 'text',
                name: field
            }));

            setFormFields(updatedFormFields);
        }
    }, [selectedItemsForFilters])

    useEffect(() => {
        setLoading(true)
        getViewConfigurationsData()
            .then(data => setViewData(data))
            .catch(error => console.error('Error:', error));
    }, []);

    useEffect(() => {
        if (selectedcatalog.id) {
            setFormData({
                source_entity_column_detail_id: '',
                source_entity_column_id: '',
                logical_name: '',
                description: '',
                constraint: '',
                data_format: '',
                example_values: '',
                enable_genai: undefined,
                pii: undefined,
                classification: "",
                owner: "",
                notes: '',
                created_at: '',
                updated_at: ''
            });

            getViewConfigurationsData(true, selectedcatalog.id)
                .then(data => {
                    setSelectedItems(data.selected_field_names);

                    const formattedStringForViewFilters = data.filters.map((obj: any) => {
                        return Object.entries(obj).map(([key, value]) => `${key}:${value}`).join(',');
                    }).join(',');

                    setFormattedString(formattedStringForViewFilters);

                    if (data.filters.length === 0) {
                        setSelectedItemsForFilters([])
                    }

                    setMultipleSelection(data.selected_data_sources)
                    setSelectedEndpoints(data.selected_endpoints)
                })
                .catch(error => console.error('Error:', error));
        }
    }, [selectedcatalog]);

    useEffect(() => {
        if (selectedcatalog.id !== 0 && selectedcatalog.id !== null && selectedcatalog.id !== undefined) {
            const updatedFormattedString = formattedString.split(",").map((item: string) => item.split(":")[0]);

            if (updatedFormattedString[0] !== "") {
                setSelectedItemsForFilters(updatedFormattedString);

                const updatedFormFields = updatedFormattedString.map(field => ({
                    controlId: `form${field.charAt(0).toUpperCase() + field.slice(1)}`,
                    label: field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
                    type: 'text',
                    name: field
                }));

                setFormFields(updatedFormFields);
            }

            const keyValuePairs = formattedString.split(',').map(pair => pair.split(':'));

            const newData = keyValuePairs.reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {} as Record<string, string>);

            setFormData((prevFormData: any) => ({
                ...prevFormData,
                ...newData
            }));
        }
    }, [formattedString]);

    const debouncedValue = useDebounce(selectedItems, 3000);

    const fetchResults = async (page: number) => {
        try {
            setLoading(true)
            await getCatalogueFilteredData(selectedItems, formattedString, multipleSelection, selectedEndpoints, page)
                .then((res: any) => {
                    setData(res);
                });
        } catch (error) {
            console.error('Error fetching results:', error);
            setApiFailedStatus(true)
        } finally {
            setLoading(false)
        }
    };


    const handleApplyFilter = async (page: number) => {
        setCurrentPage(1)
        await fetchResults(currentPage)
    }

    useEffect(() => {
        if (selectedItems.length !== 0 || formattedString !== "") {
            fetchResults(currentPage);
        }
    }, [debouncedValue, currentPage,]);

    useEffect(() => {
        if (hasMounted.current) {
            if (selectedItemsForFilters.length === 0) {
                fetchResults(1);
            }
        } else {
            hasMounted.current = true;
        }
    }, [selectedItemsForFilters]);


    const columns = [
        { "header": "Source Entity Column", "field": "source_entity_column_name" },
        { "header": "Source Entity", "field": "source_entity_name" },
        { "header": "Data Source", "field": "data_source_name" },
        { "header": "Data Type", "field": "data_type" },
        { "header": "Logical Name", "field": "logical_name" },
        { "header": "Description", "field": "description" },
        { "header": "Constraint", "field": "constraint" },
        { "header": "Data Format", "field": "data_format" },
        { "header": "Example Values", "field": "example_values" },
        { "header": "Enable Genai", "field": "enable_genai" },
        { "header": "Pii", "field": "pii" },
        { "header": "Classification", "field": "classification" },
        { "header": "Owner", "field": "owner" },
        { "header": "Notes", "field": "notes" }
    ];

    const columnsForFilter = [
        { "header": "Source Entity Column", "field": "source_entity_column_name" },
        { "header": "Source Entity", "field": "source_entity_name" },
        { "header": "Data Source", "field": "data_source_name" },
        { "header": "Data Type", "field": "data_type" },
        { "header": "Logical Name", "field": "logical_name" },
        { "header": "Classification", "field": "classification" },
        { "header": "Owner", "field": "owner" },
    ];

    useEffect(() => {
        if (data.data.length !== 0) {
            setColumnsToShow(convertObjectToColumns(data.data[0]));
        }
    }, [data]);

    const handleSelect = (item: any) => {
        setLoading(true);

        if (item.field === 'source_entity_column_name') {
            return;
        }

        if (selectedItems.includes(item.field)) {

            setSelectedItems(selectedItems.filter(i => i !== item.field));
        } else {

            setSelectedItems([...selectedItems, item.field]);
        }
    };

    const convertStringToFormField = (fieldName: string): { controlId: string, label: string, type: string, name: string } => {
        const label = fieldName.replace(/_/g, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        const controlId = `form${label.replace(/\s/g, '')}`;
        const name = fieldName.toLowerCase();

        return { controlId, label, type: 'text', name };
    };

    const handleSelectForFilter = (item: any) => {
        if (selectedItemsForFilters.includes(item)) {
            setSelectedItemsForFilters(selectedItemsForFilters.filter(i => i !== item));
            setFormFields(formFields.filter(field => field.name !== item));

            const keyValuePairs = formattedString.split(',');
            const filteredPairs = keyValuePairs.filter(pair => pair.split(":")[0] !== item);

            setFormattedString(filteredPairs.length === 0 ? "" : filteredPairs.join(','));
            setFormData({
                ...formData,
                [item]: ""
            });
        } else {
            setSelectedItemsForFilters([...selectedItemsForFilters, item]);
            const exists = formFields.some(field => field.name === item);
            if (!exists) {
                setFormFields(prev => [...prev, convertStringToFormField(item)]);
            }
        }
    };

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setPreviewModal(false);
        setShowViewModal(true);
    };

    useEffect(() => {
        const formattedStringInThisContext = selectedItemsForFilters
            .filter((key) => formData[key] !== '' && formData[key] !== null && formData[key] !== undefined)
            .map((key) => `${key}:${formData[key]}`)
            .join(',');
        setFormattedString(formattedStringInThisContext);
    }, [formData]);

    const handleEditClick = (row: any) => {
        setModalFormData(row);
        setShowModal(true);
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        if (name === "enable_genai" || name === "pii") {
            const { checked } = e.target;
            setModalFormData({
                ...modalFormData,
                [name]: checked
            });
        } else {
            setModalFormData({
                ...modalFormData,
                [name]: value
            });
        }
    };

    const handleViewInputChange = (e: any) => {
        setViewName(e.target.value);
    };

    const handleSave = () => {
        updateCatalogueData(modalFormData)
            .then((res: any) => {
                getCatalogueFilteredData(selectedItems, formattedString, multipleSelection, selectedEndpoints, currentPage)
                    .then((res: any) => {
                        setData(res);
                    });
            });
        setShowModal(false);
    };

    const warningMessage = () => {
        if (selectedItems.length === 0 && formattedString.length === 0) {
            return "You have not Selected any of the Mentioned Field and also You have not Selected and Given any Input to Filter, DO you want to Continue?";
        } else if (selectedItems.length === 0 && formattedString.length !== 0) {
            return "You have not Selected any of the Mentioned Field, Do you want to Continue?";
        } else if (selectedItems.length !== 0 && formattedString.length === 0) {
            return "You have not Selected any of the Mentioned Filter and not given any Input to Default Filters, Do you want to Continue?";
        } else if (multipleSelection) {
            return "no data exists for selected Data Source and Endpoint"
        }
        else {
            return null;
        }
    };

    const handleViewSave = () => {
        if (multipleSelection?.length > 0 || selectedEndpoints?.length > 0) {
            createView(viewName, selectedItems, formattedString, multipleSelection, selectedEndpoints)
                .then(data => console.log('View Configuration:', data))
                .catch(error => console.error('Error:', error));
        } else {
            createView(viewName, selectedItems, formattedString)
                .then(data => console.log('View Configuration:', data))
                .catch(error => console.error('Error:', error));
        }
        setShowViewModal(false);
        setViewName("");
        getViewConfigurationsData()
            .then((data) => {
                setViewData(data)
                setMultipleSelection(data.selected_data_sources)
                setSelectedEndpoints(data.selected_endpoints)
            })
            .catch(error => console.error('Error:', error));
        setStatusShowModal(true)
    };

    const handleCreateNewView = () => {
        if (selectedItems.length === 0 || formattedString.length === 0) {
            setWarningModalShow(true);
        } else {
            setPreviewModal(true);
        }
    };

    const handleUpdateSelectedView = () => {
        if (multipleSelection?.length > 0 || selectedEndpoints?.length > 0) {
            updateViewConfiguration(selectedcatalog.id, selectedItems, formattedString, selectedcatalog.name, multipleSelection, selectedEndpoints)
                .then(() => {
                    setStatusShowModal(true)
                })
                .catch(error => console.error("Error:", error));
        } else {
            updateViewConfiguration(selectedcatalog.id, selectedItems, formattedString, selectedcatalog.name)
                .then(() => {
                    setStatusShowModal(true)
                })
                .catch(error => console.error("Error:", error));
        }
    }

    const handleDeleteView = async () => {
        deleteView(viewNameToDelete.viewId)
            .then(() => {
                setShowDeleteModal(false);
                setStatusShowModal(true);
                setTimeout(() => {
                    getViewConfigurationsData()
                        .then((data) => {
                            setViewData(data)
                            setMultipleSelection(data.selected_data_sources)
                            setSelectedEndpoints(data.selected_endpoints)
                            setSelectedcatalog({
                                id: null,
                                name: null
                            })
                            setActiveViewUpdate(false)
                        })
                        .catch(error => console.error('Error:', error));
                    setDeleteViewActionTriggered(true);
                }, 100);
            })
            .catch(error => console.error("Error:", error));
    }

    return {
        data,
        columnsToShow,
        showModal,
        showViewModal,
        warningModalShow,
        previewModal,
        formattedString,
        selectedItems,
        selectedItemsForFilters,
        modalFormData,
        loading,
        formFields,
        viewName,
        viewData,
        selectedcatalog,
        columns,
        columnsForFilter,
        formData,
        activeViewUpdate,
        showDtaSetModal,
        multipleSelection,
        selectedEndpoints,
        showErrorModal,
        itemsPerPage,
        currentPage,
        apiFailedStatus,
        deleteViewActionTriggered,
        showDeleteModal,
        viewNameToDelete,
        setActiveViewUpdate,
        setShowModal,
        setShowViewModal,
        setWarningModalShow,
        setPreviewModal,
        setFormattedString,
        setSelectedItems,
        setSelectedItemsForFilters,
        setModalFormData,
        setLoading,
        setFormFields,
        setViewName,
        setViewData,
        setSelectedcatalog,
        handleSelect,
        handleSelectForFilter,
        handleChange,
        handleSubmit,
        handleEditClick,
        handleInputChange,
        handleViewInputChange,
        handleSave,
        handleViewSave,
        handleCreateNewView,
        warningMessage,
        getViewConfigurationsData,
        updateViewConfiguration,
        handleApplyFilter,
        setData,
        getCatalogueFilteredData,
        showStatusModal,
        handleHide,
        handleUpdateSelectedView,
        handleOpenDtaSetModal,
        handleCloseDtaSetModal,
        setMultipleSelection,
        setSelectedEndpoints,
        setShowErrorModal,
        handlePageChange,
        handleDeleteView,
        handleDeleteClick,
        handleCloseDeleteModal
    };
};

const convertObjectToColumns = (obj: any) => {
    const keysToModify = [
        "source_entity_column_name",
        "source_entity_name",
        "data_source_name"
    ];

    const modified_object = Object.keys(obj).map(key => {
        let modifiedKey = key;
        if (keysToModify.includes(key)) {
            modifiedKey = key.replace('_name', '');
        }

        const header = modifiedKey.replace(/_/g, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        return { header: header, field: key };
    });

    return modified_object;
};