import * as api from "./middleware/api.middleware";

const serviceUrl = "environments";

const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

const getEnvironmentId = () => {
  return localStorage.getItem("environment_id");
};

export function getDataModelByName(DataModelId: string, name: string) {
  const getResponse = api.get<any>(
    `${serviceUrl}/${getEnvironmentId()}/data_model/${DataModelId}/list?subject_area=${name}`,
    getAccessToken()
  );
  return getResponse;
}

export function getDataModel(layerType: string) {
  const getResponse = api.get<any>(
    `${serviceUrl}/${getEnvironmentId()}/data_model/${layerType}`,
    getAccessToken()
  );
  return getResponse;
}

export function postDataModel(DataModelId: string, name: string, data: any) {
  const formData = new FormData();
  data.forEach((file: any, index: any) => {
    formData.append(`files`, file);
  });

  const getResponse = api.postFormData<any>(
    `${serviceUrl}/${getEnvironmentId()}/data_model/${DataModelId}/upload?subject_area=${name}`,
    formData,
    getAccessToken()
  );
  return getResponse;
}

export function putDataModel(DataModelId: string, name: string, data: any) {
  const formData = new FormData();
  data.forEach((file: any, index: any) => {
    formData.append(`files`, file);
  });

  const getResponse = api.putFormData<any>(
    `${serviceUrl}/${getEnvironmentId()}/data_model/${DataModelId}/update?subject_area=${name}`,
    formData,
    getAccessToken()
  );
  return getResponse;
}

export function deleteDataModel(subjectArea: number, layerType: any) {
  const getResponse = api.del<any>(
    `${serviceUrl}/${getEnvironmentId()}/data_model/${layerType}/${subjectArea}`,
    null,
    getAccessToken()
  );
  return getResponse;
}

export function downloadFiles(subjectArea: any, layerType: string, file: any) {
  if (file) {
    const queryParams: any = {
      file: file.filename,
    };

    const queryString = Object.keys(queryParams)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");
    const getResponse = api.get<any>(
      `${serviceUrl}/${getEnvironmentId()}/data_model/${layerType}/${subjectArea}/download?${queryString}`,
      getAccessToken()
    );
    return getResponse;
  } else {
    const getResponse = api.get<any>(
      `${serviceUrl}/${getEnvironmentId()}/data_model/${layerType}/${subjectArea}/download`,
      getAccessToken()
    );
    return getResponse;
  }
}

export function deleteFiles(
  subjectArea: number,
  layerType: any,
  dataModelId: any
) {
  const getResponse = api.del<any>(
    `${serviceUrl}/${getEnvironmentId()}/data_model/${layerType}/${subjectArea}/${dataModelId}`,
    null,
    getAccessToken()
  );
  return getResponse;
}
