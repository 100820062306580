import React from "react";
import InputInfoIcon from "../Icons/InputInfoIcon.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./CustomTooltip.scss";
interface CustomTooltip extends React.HTMLProps<SVGElement> {
  className?: string;
  onClick?: any;
  infoMessage?: any;
}

const CustomTooltip = (props: CustomTooltip) => {
  return (
    <>
      <span className={props.className} >
        <OverlayTrigger
          key="top"
          placement="right"
          overlay={
            <Tooltip className="toolTipMessage">
              {props.infoMessage}
            </Tooltip>
          }
        >
          <img
            className="inputInfoIcon"
            width={15}
            src={InputInfoIcon}
            onClick={props.onClick}
          />
        </OverlayTrigger>
      </span>
    </>
  );
};
export default CustomTooltip;
