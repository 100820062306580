import React, { useEffect, useState } from "react";
import style from "./ensembleModelling.module.scss";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { Button, Form } from "react-bootstrap";
import searchIcon from "../../components/Icons/search.svg";
import closeIcon from "../../components/Icons/closeIcon.svg";
import ThreePointOptionDropdown from "../../assets/images/svg/threePointOptionDropdown";
import checkCircleFillIcon from "../../components/Icons/checkCircleFillIcon.svg";
import PlusIconCircle from "../../assets/images/svg/PlusIconCircle";
import DarkPlusIconCircle from "../../assets/images/svg/DarkPlusIconCircle";
import Input from "../../components/Input/Input";

import {
  deleteEnsembleModellingDetails,
  getEnsembleModellingDetails,
  postCbcDetails,
  postEnsembleModelCbcRelation,
  postEnsembleModelRelation,
  postEnsembleModellingDetails,
  putCbcDetails,
  putEnsembleModellingDetails,
} from "../../services/ensembleModelling.service";
import { cn } from "../../helpers";
import FileIcon from "../../assets/images/svg/FileIcon";
import EnsembleModelsDependency from "./EnsembleModelsDependency";
import ModalDependency from "./ModelDependency";
import DeleteConfirmationPopup from "../../components/DeletePopup/DeleteConfirmationPopup";

const EnsembleModelling = () => {
  const [deleteUser, setDeleteUser] = useState(false);
  const [selectedCustomPlugInId, setSelectedCusdtomPlugInId] =
    useState<any>(null);
  const [customPluginSearchValue, setCustomPlugInSearchValue] =
    useState<string>("");
  const [showAssignUserPopup, setShowAssignUserPopup] = useState(true);
  const [showDeleteOption, setShowDeleteOption] = useState(false);
  const [showDataSourcePopover, setShowDataSourcePopover] = useState(false);
  const [userSearchData, setUserSearchData] = useState([]);
  const [userSuccessMessage, setUserSuccessMessage] = useState(false);
  const [customPluginDeletedMessage, setCustomPluginDeletedMessage] =
    useState(false);
  const wrapperRef = React.useRef(null);
  const wrapperRefForDeleteMenuOption = React.useRef(null);
  const [isValueSaved, setIsValueSaved] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [plugInNameEmpty, setPlugInNameEmpty] = useState(false);
  const [showJobTaskPage, setShowJobTaskPage] = useState<boolean>(false);
  const [modalDependencyChange, setModalDependecyChange] =
    useState<boolean>(false);
  const [cbcChanges, setCbcChanges] = useState<boolean>(false);
  const [cbcData, setCbcData] = useState<any>({ updated: false });
  const [cbcUpdated, setCbcUpdated] = useState<boolean>(false);
  const [cbcDependencyChange, setCbcDependencyChange] = useState(false);

  const [updatedNodes, setUpdatedNodes] = useState([]);
  const [ensembleModelSelectedNode, setEnsembleModelSelectedNode] =
    useState<any>(null);

  const [plugInNameAlreadyRegistered, setPlugInNameAlreadyRegistered] =
    useState(false);
  const [updatedNodesData, setUpdatedNodesData] = useState<any>();
  const [endPointSavedMessageDetails, setEndPointSavedMessageDetails] =
    useState<String>(null);
  const ERROR_MESSAGE = "This field is mandatory";

  useEffect(() => {
    getEnsembleModellingDetails().then((res) => {
      if (res.length === 0) {
        handleAddCustomPlugIn();
        setSearchData(res);
        setUserSearchData(res);
      } else {
        setSearchData(res);
        res?.sort((a: any, b: any) => {
          if (a !== null && b !== null) {
            let fa = a?.ensemble_model_name?.toLowerCase(),
              fb = b?.ensemble_model_name.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
          }
          return 0;
        });
        setUserSearchData(res);
        setShowAssignUserPopup(false);
        setSelectedCusdtomPlugInId(res[0].ensemble_model_id);
      }
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setEndPointSavedMessageDetails(null);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [endPointSavedMessageDetails]);

  const [editCustomPlugIn, setEditCustomPlugIn] = useState({
    ensemble_model_name: "",
    subject_area: "",
    description: "",
    canvas_position: {},
  });

  const inputChangeHandler = (e: any) => {
    setPlugInNameEmpty(false);
    setPlugInNameAlreadyRegistered(false);
    setIsValueSaved(false);
    if (e.target.name === "is_active") {
      setEditCustomPlugIn({
        ...editCustomPlugIn,
        [e.target.name]: e.target.checked,
      });
    } else if (e.target.name === "additional_properties") {
      setEditCustomPlugIn({
        ...editCustomPlugIn,
        [e.target.name]: e.target.checked,
      });
    } else {
      setEditCustomPlugIn({
        ...editCustomPlugIn,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddCustomPlugIn = () => {
    setPlugInNameEmpty(false);
    setShowDataSourcePopover(false);
    setPlugInNameAlreadyRegistered(false);
    setSelectedCusdtomPlugInId(null);
    setShowAssignUserPopup(true);
  };

  useEffect(() => {
    const selectedData = searchData?.filter(
      (item: any) => item.ensemble_model_id === selectedCustomPlugInId
    );

    if (selectedData.length === 0) {
      setEditCustomPlugIn({
        ...editCustomPlugIn,
        ensemble_model_name: "",
        subject_area: "",
        description: "",
        canvas_position: "",
      });
    } else {
      setEditCustomPlugIn({
        ...editCustomPlugIn,
        ensemble_model_name: selectedData?.[0]?.ensemble_model_name,
        description: selectedData?.[0]?.description,
        subject_area: selectedData?.[0]?.subject_area,
        canvas_position: selectedData?.[0]?.canvas_position,
      });
    }
  }, [selectedCustomPlugInId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setUserSuccessMessage(false);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [userSuccessMessage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCustomPluginDeletedMessage(false);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [customPluginDeletedMessage]);

  const customPluginSidebarHandler = (customPlugInId: number) => {
    setPlugInNameEmpty(false);

    setShowAssignUserPopup(false);
    setPlugInNameAlreadyRegistered(false);
    setSelectedCusdtomPlugInId(customPlugInId);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (showDataSourcePopover === true) {
        if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
          setShowDataSourcePopover(true);
        } else {
          setShowDataSourcePopover(false);
        }
      }
      if (showDeleteOption === true) {
        if (
          wrapperRefForDeleteMenuOption.current &&
          wrapperRefForDeleteMenuOption.current.contains(event.target)
        ) {
          setShowDeleteOption(true);
        } else {
          setShowDeleteOption(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDataSourcePopover, showDeleteOption]);

  const handleUserAdminPageSave = () => {
    if (!cbcChanges) {
      if (!modalDependencyChange) {
        if (editCustomPlugIn.ensemble_model_name === "") {
          editCustomPlugIn.ensemble_model_name === "" &&
            setPlugInNameEmpty(true);
        } else {
          if (showAssignUserPopup === true) {
            const updateCustomPlugInDetails = {
              ensemble_model_name: editCustomPlugIn.ensemble_model_name,
              description: editCustomPlugIn.description,
              subject_area: editCustomPlugIn.subject_area,
              canvas_position: editCustomPlugIn.canvas_position,
            };
            postEnsembleModellingDetails(updateCustomPlugInDetails)
              .then(() => {
                setIsValueSaved(true);
                setUserSuccessMessage(true);
                setShowAssignUserPopup(false);
                getEnsembleModellingDetails().then((res) => {
                  if (res.length === 0) {
                    handleAddCustomPlugIn();
                    setSearchData(res);
                    setUserSearchData(res);
                  } else {
                    setSearchData(res);
                    res?.sort((a: any, b: any) => {
                      if (a !== null && b !== null) {
                        let fa = a?.ensemble_model_name?.toLowerCase(),
                          fb = b?.ensemble_model_name.toLowerCase();

                        if (fa < fb) {
                          return -1;
                        }
                        if (fa > fb) {
                          return 1;
                        }
                      }
                      return 0;
                    });
                    setUserSearchData(res);
                    setSelectedCusdtomPlugInId(res[0].ensemble_model_id);
                  }
                });
                setEndPointSavedMessageDetails(
                  "Ensemble Model Created Successfully!"
                );
              })
              .catch((err) => {
                if (err.status === 409) {
                  setPlugInNameAlreadyRegistered(true);
                }
              });
          } else {
            const putCustomPlugIn = {
              ensemble_model_name: editCustomPlugIn.ensemble_model_name,
              description: editCustomPlugIn.description,
              subject_area: editCustomPlugIn.subject_area,
              canvas_position: editCustomPlugIn.canvas_position,
            };
            putEnsembleModellingDetails(putCustomPlugIn, selectedCustomPlugInId)
              .then(() => {
                setUserSuccessMessage(true);
                setUserSuccessMessage(true);
                getEnsembleModellingDetails().then((res) => {
                  if (res.length === 0) {
                    handleAddCustomPlugIn();
                    setSearchData(res);
                    setUserSearchData(res);
                  } else {
                    setSearchData(res);
                    res?.sort((a: any, b: any) => {
                      if (a !== null && b !== null) {
                        let fa = a?.ensemble_model_name?.toLowerCase(),
                          fb = b?.ensemble_model_name.toLowerCase();

                        if (fa < fb) {
                          return -1;
                        }
                        if (fa > fb) {
                          return 1;
                        }
                      }
                      return 0;
                    });
                    setUserSearchData(res);
                    setIsValueSaved(true);
                  }
                });
                setEndPointSavedMessageDetails(
                  "Ensemble Model Details Updated Successfully!"
                );
              })
              .catch((err) => {
                setPlugInNameAlreadyRegistered(true);
              });
          }
        }
      } else {
        updateNodePositionAfterSave();
      }
    } else {
      if (cbcDependencyChange) {
        updateCbcNodePositionAfterSave();
      } else if (cbcChanges) {
        const {
          ensemble_model_cbc_name,
          ensemble_model_cbc_type,
          ensemble_name,
          business_keys,
          description,
          canvas_position,
        } = cbcData.cbcDetails;

        const updatedData = {
          ensemble_model_cbc_name,
          ensemble_model_cbc_type,
          ensemble_name,
          business_keys,
          description,
          canvas_position,
        };

        !cbcData.addNewCbc
          ? putCbcDetails(
              updatedData,
              selectedCustomPlugInId,
              cbcData.cbcDetails.ensemble_model_cbc_id
            ).then((res) => {
              setEndPointSavedMessageDetails(
                "Core Business Concept Updated Successfully!"
              );
              setCbcUpdated((pre) => !pre);
              setCbcData((pre: any) => ({ ...pre, updated: false }));
            })
          : postCbcDetails(cbcData.cbcDetails, selectedCustomPlugInId).then(
              (res) => {
                setEndPointSavedMessageDetails(
                  "Core Business Concept Created Successfully!"
                );
                setCbcUpdated((pre) => !pre);
              }
            );
      }
    }
  };

  const onEmsembleModelDataChange = (data: any) => {
    setUpdatedNodesData(data);
  };

  const onCbcDataChange = (data: any) => {
    setCbcData(data);
  };

  const handleAddUserAdminPageCancel = () => {
    setPlugInNameEmpty(false);
    setPlugInNameAlreadyRegistered(false);
    if (selectedCustomPlugInId !== null) {
      const selectedData = searchData?.filter(
        (item: any) => item.ensemble_model_id === selectedCustomPlugInId
      );
      setEditCustomPlugIn({
        ...editCustomPlugIn,
        ensemble_model_name: selectedData?.[0]?.ensemble_model_name,
        description: selectedData?.[0]?.description,
        subject_area: selectedData?.[0]?.subject_area,
        canvas_position: selectedData?.[0]?.canvas_position,
      });
      setIsValueSaved(true);
    } else {
      getEnsembleModellingDetails().then((res) => {
        if (res.length === 0) {
          handleAddCustomPlugIn();
          setSearchData(res);
          setUserSearchData(res);
        } else {
          setSearchData(res);
          res?.sort((a: any, b: any) => {
            if (a !== null && b !== null) {
              let fa = a?.ensemble_model_name?.toLowerCase(),
                fb = b?.ensemble_model_name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
            }
            return 0;
          });
          setUserSearchData(res);
          setSelectedCusdtomPlugInId(res[0].ensemble_model_id);
        }
      });
    }
  };
  const handleDeleteUser = () => {
    deleteEnsembleModellingDetails(selectedCustomPlugInId).then(() => {
      setIsValueSaved(true);
      setCustomPluginDeletedMessage(true);
      getEnsembleModellingDetails().then((res) => {
        if (res.length === 0) {
          handleAddCustomPlugIn();
          setSearchData(res);
          setUserSearchData(res);
        } else {
          setSearchData(res);
          res?.sort((a: any, b: any) => {
            if (a !== null && b !== null) {
              let fa = a?.ensemble_model_name?.toLowerCase(),
                fb = b?.ensemble_model_name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
            }
            return 0;
          });
          setUserSearchData(res);
          setSelectedCusdtomPlugInId(res[0].ensemble_model_id);
        }
      });
    });
  };

  const customPlugInSearchHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomPlugInSearchValue(event.target.value);
    const filteredData = searchData?.filter((item) =>
      item?.ensemble_model_name
        ?.toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    setUserSearchData(filteredData);
  };

  const handleCloseUserSuccessMessage = () => {
    setUserSuccessMessage(false);
  };

  const handleDeletingUser = (user_id: number) => {
    setShowDeleteOption(false);
    setDeleteUser(true);
  };

  const importDataModelBreadCrumbData: { name: string; url: string }[] = [
    {
      name: "Import Data Model",
      url: "/importDataModel",
    },
    { name: "Ensemble Modelling", url: "/ensembleModelling" },
  ];

  const updateNodePositionAfterSave = () => {
    const uniqueAddedNodes: number[] = [];
    const seen: Set<number> = new Set();
    for (const item of updatedNodesData.addedNodes) {
      if (!seen.has(item)) {
        seen.add(item);
        uniqueAddedNodes.push(item);
      }
    }
    for (let i = 0; i < uniqueAddedNodes.length; i++) {
      const fNode = searchData.filter(
        (value: any) => value.ensemble_model_id == uniqueAddedNodes[i]
      );
      let nNode;
      if (updatedNodes.length > 0) {
        nNode = updatedNodes.filter(
          (value: any) => value.id == uniqueAddedNodes[i]
        );
      } else {
        nNode = updatedNodesData.nodes.filter(
          (value: any) => value.id == uniqueAddedNodes[i]
        );
      }

      const pNode = { ...fNode[0], canvas_position: nNode[0].position };
      const {
        ensemble_model_name,
        subject_area,
        description,
        canvas_position,
      } = pNode;

      const updatedData = {
        ensemble_model_name,
        subject_area,
        description,
        canvas_position,
      };
      putEnsembleModellingDetails(updatedData, pNode.ensemble_model_id)
        .then(() => {
          setUpdatedNodes([]);
          setUserSuccessMessage(true);
          setUserSuccessMessage(true);
          setEndPointSavedMessageDetails(
            "Ensemble Model Details Updated Successfully!"
          );
        })
        .catch((err) => {
          setPlugInNameAlreadyRegistered(true);
        });
      setModalDependecyChange(false);
    }
    const stringIdObjects = updatedNodesData.edges.filter(
      (obj: any) => typeof obj.id === "string"
    );

    const uData = stringIdObjects.map((value: any) => ({
      src_ensemble_model_id: value?.source,
      tgt_ensemble_model_id: value?.target,
    }));
    uData.forEach((value: any) => {
      postEnsembleModelRelation(value).then((res) =>
        setEndPointSavedMessageDetails(
          "Ensemble Model Dependency Created Successfully!"
        )
      );
    });
  };

  const updateCbcNodePositionAfterSave = () => {
    const uniqueAddedNodes: number[] = [];
    const seen: Set<number> = new Set();
    for (const item of cbcData.addedNodes) {
      if (!seen.has(item)) {
        seen.add(item);
        uniqueAddedNodes.push(item);
      }
    }

    for (let i = 0; i < uniqueAddedNodes.length; i++) {
      let fNode = cbcData.searchData.filter(
        (value: any) => value?.ensemble_model_cbc_id == uniqueAddedNodes[i]
      );

      if (
        fNode[0]?.ensemble_model_cbc_id ===
        cbcData.cbcDetails?.ensemble_model_cbc_id
      ) {
        fNode = cbcData.cbcDetails;
      } else {
        fNode = { ...fNode[0] };
      }

      let nNode;
      if (updatedNodes.length > 0) {
        nNode = updatedNodes.filter(
          (value: any) => value.id == uniqueAddedNodes[i]
        );
      } else {
        nNode = cbcData.nodes.filter(
          (value: any) => value.id == uniqueAddedNodes[i]
        );
      }
      const pNode = { ...fNode, canvas_position: nNode[0]?.position };
      const {
        ensemble_model_cbc_name,
        ensemble_model_cbc_type,
        ensemble_name,
        business_keys,
        description,
        canvas_position,
      } = pNode;

      const updatedData = {
        ensemble_model_cbc_name,
        ensemble_model_cbc_type,
        ensemble_name,
        business_keys,
        description,
        canvas_position,
      };

      putCbcDetails(
        updatedData,
        selectedCustomPlugInId,
        pNode.ensemble_model_cbc_id
      )
        .then(() => {
          setUserSuccessMessage(true);
          setUserSuccessMessage(true);
          setEndPointSavedMessageDetails(
            "Core Business Concept Updated Successfully!"
          );
          setCbcUpdated((pre) => !pre);
          setCbcData((pre: any) => ({ ...pre, updated: false }));
        })
        .catch((err) => {
          setPlugInNameAlreadyRegistered(true);
        });
      setModalDependecyChange(false);
    }

    const stringIdObjects = cbcData.edges.filter(
      (obj: any) => typeof obj.id === "string"
    );

    const uData = stringIdObjects.map((value: any) => ({
      src_ensemble_model_cbc_id: value?.source,
      tgt_ensemble_model_cbc_id: value?.target,
    }));

    uData.forEach((value: any) => {
      postEnsembleModelCbcRelation(value, selectedCustomPlugInId).then((res) =>
        setEndPointSavedMessageDetails(
          "Ensemble Model Dependency Created Successfully!"
        )
      );
    });
  };

  const handleCloseendpoints = () => {
    setEndPointSavedMessageDetails(null);
  };

  return (
    <div className={style.userAdminPageOuterContainer}>
      <div className={style.datasourcesmaincontainer}>
        <div className={style.jobDependencyNotification}>
          {endPointSavedMessageDetails && (
            <div className={style.endPointSavedMessage}>
              <span>
                <img src={checkCircleFillIcon} alt="checkMark" />
                {endPointSavedMessageDetails}
              </span>
              <img
                onClick={handleCloseendpoints}
                className={style.endPointSavedMessageCrossIcon}
                width={15}
                src={closeIcon}
                alt="close"
              />
            </div>
          )}
        </div>

        {customPluginDeletedMessage && (
          <div className={style.userSaveMessage}>
            <span>
              <img
                className={style.UserSavedMessageIcon}
                width={16}
                src={checkCircleFillIcon}
              />
              <span className={style.UserSavedMessageBold}>
                Ensemble Modelling
              </span>
              <span className={style.UserSavedMessageSemiBold}>
                Deleted Successfully!
              </span>
            </span>
            <img
              onClick={handleCloseUserSuccessMessage}
              className={style.UserSavedMessageCrossIcon}
              width={15}
              src={closeIcon}
            />
          </div>
        )}
        <BreadCrumbs
          breadCrumbsData={importDataModelBreadCrumbData}
        />

        <div className={style.datasourceheader}>
          <div className={style.environmentDetailsHeaderContainer}>
            <div className={style.environmentDetailsHeader}>
              Ensemble Modelling
            </div>
            <div className={style.dataSourceCancleAndSaveButton}>
              <Button
                onClick={handleAddUserAdminPageCancel}
                disabled={isValueSaved}
                id={style.saveCancelButtonCANCEL}
              >
                CANCEL
              </Button>
              <Button
                onClick={handleUserAdminPageSave}
                disabled={
                  cbcChanges
                    ? false
                    : modalDependencyChange
                    ? false
                    : cbcDependencyChange
                    ? false
                    : isValueSaved
                }
                id={style.saveCancelButtonSave}
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      </div>
      {!showJobTaskPage ? (
        <div className={style.datasourcesmaincontainer}>
          <div className={style.userAdminPageMainContainer}>
            <div className={style.datasourcefirstsidebarcontainer}>
              <div className={style.sidebarheader}>
                <span className={style.sidebarheading}>Ensemble Models</span>
                <div className={style.sidebarheaderRightIcon}>
                  {showDataSourcePopover ? (
                    <DarkPlusIconCircle />
                  ) : (
                    <PlusIconCircle
                      onClick={() =>
                        setShowDataSourcePopover(!showDataSourcePopover)
                      }
                    />
                  )}
                </div>
              </div>
              {showDataSourcePopover && (
                <div className={style.TooltipsContainer} ref={wrapperRef}>
                  <div className={style.TooltipsContainerBody}>
                    <span
                      onClick={handleAddCustomPlugIn}
                      className={style.TooltipsContainertext}
                    >
                      Add Ensemble Model
                    </span>
                  </div>
                </div>
              )}
              <div className={style.sidebarsearch}>
                <img src={searchIcon} alt=""></img>
                <Form.Group>
                  <Form.Control
                    type="search"
                    id="dataModelSearchInput"
                    disabled={false}
                    placeholder="Search"
                    name="firstSearch"
                    onChange={customPlugInSearchHandler}
                    className={style.searchBar}
                    value={customPluginSearchValue}
                  />
                </Form.Group>
              </div>
              <div className={style.userListContainer}>
                {userSearchData?.map((sidebardata, index) => {
                  return (
                    <div
                      id={
                        selectedCustomPlugInId ===
                          sidebardata.ensemble_model_id &&
                        style.selectedDataSource
                      }
                      className={style.firstseachsidebardatalist}
                      onClick={() =>
                        customPluginSidebarHandler(
                          sidebardata?.ensemble_model_id
                        )
                      }
                    >
                      <div className={style.sidebarfilledData}>
                        <div className={style.sidebarfilledFirstTitle}>
                          <div className={style.sidebarProfileIcon}>
                            <FileIcon />
                          </div>
                          <div className={style.sidebarDataListTitle}>
                            <h6 className={style.sidebarlistdata}>
                              {sidebardata.ensemble_model_name}
                            </h6>
                          </div>
                        </div>
                        <div className={style.sidebarfilledIcons}>
                          <div className={style.sidebarheaderSelectIcon}>
                            <ThreePointOptionDropdown
                              className="SideBarTablesvg"
                              onClick={() => setShowDeleteOption(true)}
                            />

                            {showDeleteOption &&
                              selectedCustomPlugInId ===
                                sidebardata.ensemble_model_id && (
                                <div
                                  className={
                                    style.sidebarheadingSelectTooltipsContainer
                                  }
                                  ref={wrapperRefForDeleteMenuOption}
                                >
                                  <div
                                    className={
                                      style.sidebarheadingTooltipsContainerBody
                                    }
                                  >
                                    <span
                                      onClick={() =>
                                        handleDeletingUser(
                                          sidebardata.ensemble_model_id
                                        )
                                      }
                                      className={
                                        style.sidebarheadingTooltipsContainertext_Delete
                                      }
                                    >
                                      Delete
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <DeleteConfirmationPopup
              popUpMessage="Do you want to delete the selected Ensemble Model?"
              show={deleteUser}
              onHide={() => setDeleteUser(false)}
              onSave={handleDeleteUser}
            />
            <div className={style.userAdminPageInputContainer}>
              <div className={style.userAdminPageHeaderContainer}>
                <div className={style.userAdminPageHeader}>
                  {showAssignUserPopup
                    ? "Create New Ensemble Model"
                    : "Edit Ensemble Model Details"}
                </div>
              </div>
              <div className={style.ensembleModellingDetailsContainer}>
                <div className={cn(style.customPluginInputContainer, "row")}>
                  <div className="col-6">
                    <Input
                      type="text"
                      id="custumPlugInInput"
                      labelId="dataSourceDetailsInputLabel"
                      isRequired={true}
                      label="Model Name"
                      placeholder="Enter emsemble modal name"
                      infoIcon={true}
                      infoMessage="Specify name of the emsemble model."
                      name="ensemble_model_name"
                      maxlength={100}
                      value={editCustomPlugIn.ensemble_model_name}
                      isDisabled={false}
                      onChange={(e) => {
                        inputChangeHandler(e);
                      }}
                    />
                    {plugInNameEmpty && (
                      <div className={style.userEmptyErrorMessage}>
                        {ERROR_MESSAGE}
                      </div>
                    )}
                    {plugInNameAlreadyRegistered === true && (
                      <div className={style.userEmptyErrorMessage}>
                        Name already exists. Please enter a unique name.
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <Input
                      type="text"
                      id="custumPlugInInput"
                      labelId="dataSourceDetailsInputLabel"
                      isRequired={true}
                      label="Subject Area Name"
                      placeholder="Enter subject area name"
                      infoIcon={true}
                      infoMessage="Specify name of the subject area."
                      name="subject_area"
                      maxlength={100}
                      value={editCustomPlugIn.subject_area}
                      isDisabled={false}
                      onChange={(e) => {
                        inputChangeHandler(e);
                      }}
                    />
                    {plugInNameEmpty && (
                      <div className={style.userEmptyErrorMessage}>
                        {ERROR_MESSAGE}
                      </div>
                    )}
                    {plugInNameAlreadyRegistered === true && (
                      <div className={style.userEmptyErrorMessage}>
                        Name already exists. Please enter a unique name.
                      </div>
                    )}
                  </div>
                </div>
                <div className={cn(style.customPluginInputContainer, "row")}>
                  <div className={style.descriptionContainer}>
                    <Input
                      type="text"
                      id="ensembleModellingDescription"
                      labelId="dataSourceDetailsInputLabel"
                      label="Description"
                      name="description"
                      placeholder="Detailed description of custom plugin"
                      isDisabled={false}
                      value={editCustomPlugIn.description}
                      maxlength={500}
                      onChange={(e) => {
                        inputChangeHandler(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={style.graphContainer}>
                <div className={style.graphContainerHeader}>
                  Ensemble Models Dependency
                </div>
                <div className={style.graphContainerBody}>
                  <EnsembleModelsDependency
                    showJobTaskPage={showJobTaskPage}
                    setShowJobTaskPage={setShowJobTaskPage}
                    data={searchData}
                    setModalDependecyChange={setModalDependecyChange}
                    modalDependencyChange={modalDependencyChange}
                    onEmsembleModelDataChange={onEmsembleModelDataChange}
                    setEnsembleModelSelectedNode={setEnsembleModelSelectedNode}
                    setSelectedCusdtomPlugInId={setSelectedCusdtomPlugInId}
                    selectedCustomPlugInId={selectedCustomPlugInId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ModalDependency
          setShowJobTaskPage={setShowJobTaskPage}
          ensembleModelSelectedNode={ensembleModelSelectedNode}
          setCbcChanges={setCbcChanges}
          onCbcDataChange={onCbcDataChange}
          cbcUpdated={cbcUpdated}
          setCbcDependencyChange={setCbcDependencyChange}
        />
      )}
    </div>
  );
};

export default EnsembleModelling;
