import React from "react";
interface FileIcon extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
const FileIcon = (props: FileIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g id="Group_188" data-name="Group 188" transform="translate(-110 -386)">
        <circle
          id="Ellipse_18"
          data-name="Ellipse 18"
          cx="16"
          cy="16"
          r="16"
          transform="translate(110 386)"
          fill="#03234a"
        />
        <g id="description_black_24dp" transform="translate(118 394)">
          <path
            id="Path_216"
            data-name="Path 216"
            d="M0,0H16V16H0Z"
            fill="none"
          />
          <path
            id="Path_217"
            data-name="Path 217"
            d="M10.671,2H5.334A1.332,1.332,0,0,0,4.007,3.334L4,14a1.332,1.332,0,0,0,1.327,1.334h8.011A1.338,1.338,0,0,0,14.673,14V6ZM12,12.669H6.668V11.335H12ZM12,10H6.668V8.668H12ZM10,6.668V3l3.669,3.668Z"
            transform="translate(-1.336 -0.668)"
            fill="#d6dee6"
          />
        </g>
      </g>
    </svg>
  );
};
export default FileIcon;
