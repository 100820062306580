import React from "react";
interface Group2357 extends React.HTMLProps<SVGElement> {
  className?: string;
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  [key:string]:any;
}
const Group2357 = (props: Group2357) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox={props.viewBox || "0 0 72 72"}
    >
      <g id="Group_2357" data-name="Group 2357" transform="translate(-89 -30)">
        <circle
          id="Ellipse_82"
          data-name="Ellipse 82"
          cx="36"
          cy="36"
          r="36"
          transform="translate(89 30)"
          fill="#03234a"
        />
        <g
          id="_28-data_setting"
          data-name="28-data setting"
          transform="translate(105 46)"
        >
          <path
            id="Path_1876"
            data-name="Path 1876"
            d="M37.5,36.3H2.5A2.5,2.5,0,0,1,0,33.8V10.5A2.5,2.5,0,0,1,2.5,8H9.167V9.665H2.5a.833.833,0,0,0-.833.832V33.8a.833.833,0,0,0,.833.832h35a.833.833,0,0,0,.833-.832V10.5a.833.833,0,0,0-.833-.832H30.833V8H37.5A2.5,2.5,0,0,1,40,10.5V33.8A2.5,2.5,0,0,1,37.5,36.3Z"
            transform="translate(0 -1.295)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <path
            id="Path_1877"
            data-name="Path 1877"
            d="M31.145,47.665H12.832A.832.832,0,0,1,12,46.832V46H31.977v.832A.832.832,0,0,1,31.145,47.665Z"
            transform="translate(-1.989 -7.665)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <rect
            id="Rectangle_619"
            data-name="Rectangle 619"
            width="2"
            height="5"
            transform="translate(12.015 34)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <rect
            id="Rectangle_620"
            data-name="Rectangle 620"
            width="1"
            height="5"
            transform="translate(26.031 34)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <path
            id="Path_1878"
            data-name="Path 1878"
            d="M24.676,26.636h-6.67a.833.833,0,0,1-.834-.832V23.366L15.445,25.09a.835.835,0,0,1-1.179,0L9.548,20.381a.831.831,0,0,1,0-1.177l1.728-1.724H8.834A.833.833,0,0,1,8,16.648V9.989a.833.833,0,0,1,.834-.832h2.442L9.548,7.432a.831.831,0,0,1,0-1.177l4.718-4.71a.835.835,0,0,1,1.179,0L17.172,3.27V.832A.833.833,0,0,1,18.006,0h6.67a.833.833,0,0,1,.834.832V3.27l1.727-1.725a.835.835,0,0,1,1.179,0l4.718,4.71a.831.831,0,0,1,0,1.177L31.406,9.156h2.442a.833.833,0,0,1,.834.832v6.659a.833.833,0,0,1-.834.832H31.406L33.134,19.2a.831.831,0,0,1,0,1.177L28.416,25.09a.835.835,0,0,1-1.179,0L25.51,23.366V25.8A.833.833,0,0,1,24.676,26.636ZM18.84,24.971h5V22.724a.832.832,0,0,1,.554-.785,9.306,9.306,0,0,0,.891-.369.835.835,0,0,1,.948.166l1.591,1.588,3.538-3.532L29.773,18.2a.831.831,0,0,1-.167-.946,9.272,9.272,0,0,0,.369-.889.834.834,0,0,1,.787-.554h2.251V10.821H30.763a.834.834,0,0,1-.786-.554,9.271,9.271,0,0,0-.369-.889.831.831,0,0,1,.167-.946l1.591-1.588L27.826,3.312,26.235,4.9a.835.835,0,0,1-.948.166A9.306,9.306,0,0,0,24.4,4.7a.832.832,0,0,1-.554-.786V1.665h-5V3.912a.832.832,0,0,1-.554.785,9.306,9.306,0,0,0-.891.369.835.835,0,0,1-.948-.166L14.856,3.312,11.318,6.844l1.591,1.588a.831.831,0,0,1,.167.946,9.272,9.272,0,0,0-.369.889.834.834,0,0,1-.787.554H9.668v4.994h2.251a.834.834,0,0,1,.786.554,9.273,9.273,0,0,0,.369.889.831.831,0,0,1-.167.946l-1.591,1.588,3.538,3.532,1.591-1.588a.835.835,0,0,1,.948-.166,9.305,9.305,0,0,0,.891.369.832.832,0,0,1,.555.786Z"
            transform="translate(-1.341 0)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <path
            id="Path_1879"
            data-name="Path 1879"
            d="M22.659,21.318a6.659,6.659,0,1,1,6.659-6.659,6.659,6.659,0,0,1-6.659,6.659Zm0-11.653a4.994,4.994,0,1,0,4.994,4.994,4.994,4.994,0,0,0-4.994-4.994Z"
            transform="translate(-2.659 -1.33)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <path
            id="Path_1880"
            data-name="Path 1880"
            d="M40.5,36.994a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,40.5,36.994Zm0-3.33a.832.832,0,1,0,.832.832A.832.832,0,0,0,40.5,33.665Z"
            transform="translate(-6.324 -5.329)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <path
            id="Path_1881"
            data-name="Path 1881"
            d="M6.5,36.994a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,6.5,36.994Zm0-3.33a.832.832,0,1,0,.832.832A.832.832,0,0,0,6.5,33.665Z"
            transform="translate(-0.67 -5.329)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <path
            id="Path_1882"
            data-name="Path 1882"
            d="M40.708,31.876H39.043V30.211a.832.832,0,0,0-.832-.832H36.546a.832.832,0,0,1-.588-.244L34.293,27.47l1.177-1.177,1.421,1.421h1.32a2.5,2.5,0,0,1,2.5,2.5Z"
            transform="translate(-5.702 -4.378)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <path
            id="Path_1883"
            data-name="Path 1883"
            d="M19.821,35.162H9V33.5H19.821a.832.832,0,0,0,.832-.832V31h1.665v1.665A2.5,2.5,0,0,1,19.821,35.162Z"
            transform="translate(-1.496 -5.163)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <rect
            id="Rectangle_621"
            data-name="Rectangle 621"
            width="2"
            height="2"
            transform="translate(35.046 9.966)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <rect
            id="Rectangle_622"
            data-name="Rectangle 622"
            width="2"
            height="2"
            transform="translate(35.046 12.97)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <rect
            id="Rectangle_623"
            data-name="Rectangle 623"
            width="2"
            height="2"
            transform="translate(3 12.97)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
          <rect
            id="Rectangle_624"
            data-name="Rectangle 624"
            width="2"
            height="2"
            transform="translate(3 9.966)"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
        </g>
      </g>
    </svg>
  );
};
export default Group2357;
