import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const login = useSelector((state) => state.login);
  let location = useLocation();
  if (login.isAuthorized === false && localStorage.getItem('isLoggedIn') === null) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
  