import React from "react";
import style from "./ModalPopup.module.scss";
import { Button, Modal } from "react-bootstrap";
import YesTick from "../Icons/YesTick.svg";
import closeIcon from "../Icons/closeIconGreen.svg";
import { INVALID_DELETE_MESSAGE } from "../../utils/dataSourceAndEndpointUtils";

export interface PopUpProps {
  show: boolean;
  onHide: any;
  footer?: boolean;
  bodyHead?: string;
  bodyTitle?: string;
  doneButton?: boolean;
  loginMessageSuccess?: any;
}

const ModalPopup = (props: PopUpProps) => {
  const {
    show,
    onHide,
    footer,
    bodyHead,
    bodyTitle,
    doneButton,
    loginMessageSuccess,
  } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className={style.GuideEnvironmentCloseIconModal}
    >
      <div className={style.modalBodayMain}>
        <Modal.Body>
          <div className={style.GuideEnvironmentCloseIcon}>
            <img
              onClick={onHide}
              src={closeIcon}
              alt="closeIcon"
              width={19.94}
              height={19.9}
            />
          </div>
          <div className={style.modalBoday}>
            { bodyTitle !==INVALID_DELETE_MESSAGE ?
            <img
              id="yes-tick-icon-id"
              src={YesTick}
              alt="YesTick"
              width={189.64}
              height={113.36}
            />
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#d0fb56" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <circle cx="12" cy="12" r="10" fill="#03234a" />
              <line x1="15" y1="9" x2="9" y2="15" stroke="#d0fb56" />
              <line x1="9" y1="9" x2="15" y2="15" stroke="#d0fb56" />
            </svg>
            }
            <span className={style.modalBodayHeader}>{bodyHead}</span>
            <span className={style.modalBodayTitle}>{bodyTitle}</span>
            {doneButton && (
              <Modal.Footer className={style.modalDoneButton}>
                <Button onClick={loginMessageSuccess}>Done</Button>
              </Modal.Footer>
            )}
          </div>
        </Modal.Body>
        {footer && (
          <Modal.Footer>
            <Button onClick={onHide}>Close</Button>
          </Modal.Footer>
        )}
      </div>
    </Modal>
  );
};
export default ModalPopup;
