import { ILoginResetPasswordInterface } from "../interfaces/login.interfaces";
import * as api from "./middleware/api.middleware";

const serviceUrl='reset_password';
export function resetPasswordService(
  email: string,
  newpassword: string,
  passwordresettoken: string
) {
  const getResponse = api.post<ILoginResetPasswordInterface>(`${serviceUrl}`, {
    email: email,
    is_active: true,
    new_password: newpassword,
    password_reset_token: passwordresettoken
  });
  return getResponse;
}
