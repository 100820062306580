import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ErrorModalProps {
  showModal: boolean;
  setShowErrorModal: any
}



const ErrorModal: React.FC<ErrorModalProps> = ({ showModal,setShowErrorModal}) => {

    const handleCloseModal = () => {
        setShowErrorModal(false);
    };
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Data Not Found</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>The queried catalog data does not exist with the given selected data sources and endpoints.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;