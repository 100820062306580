import { configureStore } from "@reduxjs/toolkit";
import { getHostName } from "../helpers";
import { reducer } from "./reducer";

const enableReduxDevTool = getHostName()?.includes("localhost");

export const store = configureStore({ reducer, devTools: enableReduxDevTool });

// Infer 'Rootstate' and 'AppDispatch' types form store itself
export type IRootState = ReturnType<typeof store.getState>;
// Inferred type: {users: UsersState, employees: EmployeesState}
export type IAppDispatch = typeof store.dispatch;
